import UserStore from "./userStore";
import ProfessionalStore from "./professionalStore";
import PatientStore from "./patientStore";
import MeasurementProfilesStore from "./MeasurementProfilesStore";
import AppointmentStore from "./appointmentStore";
import AnamnesisStore from "./anamesisStore";


export function resetStores(){
    for (const store in stores){
        stores[store].reset();
    }
}

export const stores = {
    userStore: new UserStore(),
    professionalStore: new ProfessionalStore(),
    patientStore: new PatientStore(),
    measurementProfilesStore: new MeasurementProfilesStore(),
    appointmentStore: new AppointmentStore(),
    anamnesisStore: new AnamnesisStore(),
};
