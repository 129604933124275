import Step from '../Step';

class UpAndGoStep extends Step {
    connectToGripwise = null;

    constructor(name) {
        super(name);
    }

    /* 
        We're using ?? because this attribute should be true 
        or false and the logic gets messed up with an || operator 
    */
    initFromData(data){
        this.connectToGripwise = data.connect_to_gripwise ?? data.connectToGripwise;
    }
}

export default UpAndGoStep;
