import { Avatar } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";

const DefaultAvatar = observer((props) => {
    let names = props.name?.split(' ').map(name => name[0]?.toUpperCase()) || '';
    let style = {
        bgcolor: "primary.main",        
    };

    if (props.fullWidth) style.transform = 'scale(3)';

    return (
        <Avatar alt={props.name} sx={style}>
            <span style={{ color: 'white', fontWeight: 'bold' }}>
                {names.length > 1 ? names[0] + names[names.length - 1] : names[0]}
            </span>
        </Avatar>
    );
});

export default DefaultAvatar;
