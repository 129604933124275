import { makeObservable, observable, computed, action } from 'mobx';
import SkinFoldsStep from './steps/SkinFoldsStep';
import WeightAndHeightStep from './steps/WeightAndHeightStep';
import WeightPastStep from './steps/WeightPastStep';
import AnthropometryStep from './steps/AnthropometryStep';
import SurveyStep from './steps/SurveyStep';
import WalkTimeStep from './steps/WalkTimeStep';
import StrengthStep from './steps/StrengthStep';
import SitToStandStep from './steps/SitToStandStep';
import BioImpedanceStep from './steps/BioImpedanceStep';
import OtitestStep from './steps/OtitestStep';
import TempStep from './steps/TempStep';
import NoteStep from './steps/NoteStep';
import UpAndGoStep from './steps/UpAndGoStep';
import PresetStep from './steps/PresetStep';
import CheckpointStep from './steps/CheckpointStep';
import LegStrengthStep from './steps/LegStrengthStep';
import HandConfigurationStep from './steps/HandConfigurationStep';
import BasicMeasurementsStep from './steps/BasicMeasurementsStep';



// Inserir classe de passo baseado no nome
export const STEP_CLASS_BY_NAME = {
    'S-SKIN': SkinFoldsStep,
    'S-WEIGHT-PAST': WeightPastStep,
    'S-WEIGHT': WeightAndHeightStep,
    'S-ANTHRO': AnthropometryStep,
    'S-ANTHRO-GIRTHS': AnthropometryStep,
    'S-ANTHRO-LENGTHS-HEIGHTS': AnthropometryStep,
    'S-ANTHRO-BREADTHS-DEPTHS': AnthropometryStep,
    'S-SURVEY': SurveyStep,
    'S-WALK': WalkTimeStep,
    'S-STRENGTH': StrengthStep,
    'S-STRENGTH-ABDUCTION': LegStrengthStep,
    'S-MANUAL-SKIN': SkinFoldsStep,
    'S-MANUAL-HAND-STRENGTH': StrengthStep,
    'S-SIT-TO-STAND': SitToStandStep,
    'S-UP-AND-GO': UpAndGoStep,
    'S-BIO-IMPEDANCE': BioImpedanceStep,
    'S-OTITEST': OtitestStep,
    'S-MANUAL-OTITEST': OtitestStep,
    'S-TEMP-OTITEST': TempStep,
    'S-MANUAL-TEMP': TempStep,
    'S-NOTES': NoteStep,
    'S-SARCOPENIA-ASSESSMENT': PresetStep,
    'S-SKINFOLD-ORDER': PresetStep,
    'S-SURVEY-PRESET-CHECKPOINT': CheckpointStep,
    'S-INITIAL-PRESET-CHECKPOINT': CheckpointStep,
    'S-STRENGTH-PRESET-CHECKPOINT': CheckpointStep,
    'S-QUALITY-PRESET-CHECKPOINT': CheckpointStep,
    'S-SEVERITY-PRESET-CHECKPOINT': CheckpointStep,
    'S-HAND-CONFIGURATION': HandConfigurationStep,
    'S-BASIC-MEASUREMENTS': BasicMeasurementsStep,
};

class Profile {
    id;
    steps = [];
    name = '';
    parent_evaluation_profile = null;
    isRemovable = true;
    isEditable = true;
    store = null;
    sequence;

    constructor(store, id, name, steps, isRemovable, isEditable, sequence) {
        this.store = store;
        this.id = id || null;
        this.name = name || '';
        this.isRemovable = isRemovable || true;
        this.isEditable = isEditable || true;
        this.sequence = sequence || 0;

        makeObservable(this, {
            steps: observable,
            name: observable,
            stepsInReordableFormat: computed,
            stepsOrder: computed,
            removeStep: action,
            addOrUpdateStep: action,
            addDuplicateStep: action,
        });

        if (steps) {
            this.steps = steps.map(step => {
                const StepClass = STEP_CLASS_BY_NAME[step.name];
                const newStep = new StepClass(step.name, step.preset);
                newStep.initFromData(step);
                return newStep;
            });
        }
    }

    moveStepUp(stepName) {
        const index = this.steps.findIndex(s => s.name === stepName);
        if (index > 0) { 
            [this.steps[index - 1], this.steps[index]] = [this.steps[index], this.steps[index - 1]];
        }
    }

    moveStepDown(stepName) {
        const index = this.steps.findIndex(s => s.name === stepName);
        if (index < this.steps.length - 1) {  
            [this.steps[index + 1], this.steps[index]] = [this.steps[index], this.steps[index + 1]];
        }
    }


    get stepsInReordableFormat() {
        let newItemsArray = [];
        for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].preset !== null) {
                newItemsArray.push(this.steps[i].inReordableListFormat);
            }
        }
        return {
            data: newItemsArray,
            order: this.stepsOrder,
        };
    }

    get stepsOrder() {
        return this.steps.map(step => step.name);
    }

    removeStep(stepName) {
        this.steps = this.steps.filter(s => s.name !== stepName);
    }

    addOrUpdateStep(step) {
        const index = this.steps.findIndex(s => s.name === step.name);
        if (index !== -1) {
            this.steps[index] = step;
        } else {
            this.steps.push(step);
        }
    }

    addDuplicateStep(step) {
        const newStep = { ...step };
        this.steps.push(newStep);
    }

    getSteps() {
        return this.steps;
    }

    isValid() {
        return this.steps.length > 0;
    }
}

export default Profile;
