import Step from '../Step';

class SkinFoldsStep extends Step {
    equationId = null;

    secondaryEquations = [];

    skinFolds = [];

    alternateModeEnabled = true;

    constructor(name, preset) {
        super(name, preset);
    }

    initFromData(data) {
        this.skinFolds = data.skinFolds || data.skin_folds;
        this.equationId = data.equationId || data.equation_id;
        this.secondaryEquations = data.secondaryEquations || data.secondary_equations;
        this.alternateModeEnabled = data.alternateModeEnabled || data.alternate_mode_enabled;
    }
}

export default SkinFoldsStep;
