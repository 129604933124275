import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';

import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import { stores } from '../stores';

import { translate } from '../translations/translate';
import { observer } from 'mobx-react-lite';

const ListItemStyle = styled(props => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2.5),
    wordBreak: 'break-word',
    color: theme.palette.text.secondary,
    '&:before': {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: "''",
        display: 'none',
        position: 'absolute',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: theme.palette.primary.main,
    },
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const NavItem = observer(({ item, active, contentVisible }) => {
    const theme = useTheme();
    const isActiveRoot = active(item.path);
    const { title, path, icon, info, children } = item;
    const [open, setOpen] = useState(isActiveRoot);

    const handleOpen = () => {
        setOpen(prev => !prev);
    };

    const activeRootStyle = {
        color: 'primary.main',
        fontWeight: 'fontWeightMedium',
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        '&:before': { display: 'block' },
    };

    const activeSubStyle = {
        color: 'text.primary',
        fontWeight: 'fontWeightMedium',
    };

    if (children) {
        return (
            <>
                <ListItemStyle
                    onClick={handleOpen}
                    sx={{
                        ...(isActiveRoot && activeRootStyle),
                    }}
                >
                    <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                    <ListItemText disableTypography primary={translate(title)} />
                    {info && info}
                    <Box
                        component={Icon}
                        icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {children.map(item => {
                            const { title, path } = item;
                            const isActiveSub = active(path);

                            // don't display the children items if they require manager permissions and the user is not a manager
                            if (item.requiresManagerPermission && stores.userStore.getRedirectPath === '/patients') return null;

                            // render the children items when manager validation passes
                            else return (
                                <ListItemStyle
                                    key={title}
                                    component={RouterLink}
                                    to={path}
                                    sx={{
                                        ...(isActiveSub && activeSubStyle),
                                    }}
                                >
                                    <ListItemIconStyle>
                                        <Box
                                            component='span'
                                            sx={{
                                                width: 4,
                                                height: 4,
                                                display: 'flex',
                                                borderRadius: '50%',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                bgcolor: 'text.disabled',
                                                transition: theme => theme.transitions.create('transform'),
                                                ...(isActiveSub && {
                                                    transform: 'scale(2)',
                                                    bgcolor: 'primary.main',
                                                }),
                                            }}
                                        />
                                    </ListItemIconStyle>
                                    <ListItemText disableTypography primary={translate(title)} />
                                </ListItemStyle>
                            );
                        })}
                    </List>
                </Collapse>
            </>
        );
    }

    return (
        <ListItemStyle
            component={RouterLink}
            to={path}
            sx={{
                ...(isActiveRoot && activeRootStyle),
            }}
        >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={translate(title)}  sx={{ opacity: contentVisible ? 1 : 0}}/>
            {info && info}
        </ListItemStyle>
    );
});

NavItem.propTypes = {
    item: PropTypes.object,
    active: PropTypes.func,
};

const NavSection = observer(({ navConfig, contentVisible, ...other }) => {
    const { pathname } = useLocation();
    const match = path => (path ? !!matchPath({ path }, pathname) : false);
    return (
        <Box {...other}>
            <List disablePadding>
                {navConfig.map(
                    item =>
                    (item.requiresManagerPermission && !stores.userStore.isManager ?
                        null // don't display the sidebar item if it requires manager permissions and the user is not a manager
                        :
                        <NavItem key={item.title} item={item} active={match} />
                    )
                )}
            </List>
        </Box>
    );
});

NavSection.propTypes = {
    navConfig: PropTypes.array,
};

export default NavSection;