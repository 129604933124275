export default {
    'fold_triceps': 'Triceps',
    'fold_subscapular': 'Subscapular',
    'fold_biceps': 'Biceps',
    'fold_iliacCrest': 'Iliac crest',
    'fold_supraspinale': 'Supraspinale',
    'fold_abdominal': 'Abdominal',
    'fold_anteriorThigh': 'Thigh',
    'fold_medialcalf': 'Calf',
    'fold_cheek': 'Cheek',
    'fold_chin': 'Chin',
    'fold_chest': 'Chest',
    'fold_umbilical': 'Umbilical',
    'fold_knee': 'Knee',
    'fold_midAxillary': 'Axillary',
    'fold_lowerBack': 'Lower back',
    'fold_calf': 'Calf',
    'fold_axillar': 'Axillar',
    'fold_total': 'Total',
    'eq1': 'Durnin&Womesley Triceps',
    'eq2': 'Durnin&Womesley Subscapular',
    'eq3': 'Durnin&Womesley T+S',
    'eq4': 'Durnin&Womesley 4 Skinfolds',
    'eq5': 'Jackson&Pollock 3 Skinfolds Male',
    'eq6': 'Jackson&Pollock 3 Skinfolds(log)Male',
    'eq7': 'Jackson&Pollock 3 Skinfolds Female',
    'eq8': 'Jackson&Pollock 3 Skinfolds(log)Female',
    'eq9': 'Jackson&Pollock 7 Skinfolds',
    'eq10': 'Jackson&Pollock 7 Skinfolds(log)',
    'eq11': 'Jackson&Pollock 4 Skinfolds Female',
    'eq12': 'Jackson&Pollock 4 Skinfolds(log)Female',
    'eq13': 'Jackson&Pollock 1985 3 Skinfolds Male',
    'eq14': 'Jackson&Pollock 1985 3 Skinfolds Female',
    'eq15': 'Peterson',
    'eq16': 'Visser 4 Skinfolds',
    'eq17': 'Visser T+B',
    'eq18': 'Slaughter T+C',
    'eq19': 'Slaughter T+S Male',
    'eq20': 'Slaughter T+S Female',
    'eq21': 'Carter (ISAK)',
    'eq22': 'Yuhasz',
    'eq23': 'Withers Male',
    'eq24': 'Withers Female',
    'eq25': 'Novel',
    'eq26': 'Guedes (1994) Male',
    'eq27': 'Guedes (1994) Female',
    'eq28': 'Kagawa T+I Female',
    'eq29': 'Kagawa T+I+B Female',
    'eq30': 'Kagawa A+C Male',
    'eq31': 'Kagawa 8 Skinfolds Male',
    'eq32': 'Kagawa T+S Male',
    'eq33': 'Faulkner',
    'eq34': 'Weltman',
    'eq35': 'Dezenberg',
    'eq36': 'Kannieappan (Pregnant)',
    'eq37': 'Huston Presley (Pregnant)',
    'eq38': 'Ball Male',
    'eq39': 'Ball Female',
    'eq40': 'Petroski Male',
    'eq41': 'Petroski Female',
    'eq42': 'Sloan Male',
    'eq43': 'Sloan Female',
    'eq44': 'Reilly Male',
    'eq45': 'Evans',
    'eq46': 'Eston',
    'eq47': 'Haisman Male',
    'eq48': 'Wilmore Behnke Male',
    'eq49': 'Wilmore Behnke Female',
    // bone mass - ISAK consense
    'eq50': 'Rocha (Bone Mass)',
    'eq51': 'Martin (Bone Mass)',
    // muscle weight - ISAK consense
    'eq52': 'Matiegka (Muscle Weight)',
    // skeletal muscle mass - ISAK consense
    'eq53': 'Lee (Muscle Mass)',
    'eq54': 'Poortmans (Muscle Mass)',
    // residual weight - ISAK consense
    'eq55': 'Wurch (Residual Weight)',
    // skinfold sums - ISAK consense
    'eq56': '6 Skinfolds Sum',
    'eq57': '8 Skinfolds Sum',
    'eq58': 'Skinfolds Sum',
    // Somatotype Heath-Carter (2002) - ISAK consense
    'eq59': 'Endomorphy',
    'eq60': 'Mesomorphy',
    'eq61': 'Ectomorphy',
    'eq62': 'Conversion from 15 feet to 4 m',
    'anthropometry_section_girths': 'Girths',
    'anthropometry_section_lengths_and_heights': 'Lengths & Heights',
    'anthropometry_section_breadths_and_depths': 'Breadths & Depths',
    'anthropometry_head': 'Head',
    'anthropometry_neck': 'Neck',
    'anthropometry_relaxedArm_wrist': 'Relaxed Arm',
    'anthropometry_flexedArm': 'Flexed Arm',
    'anthropometry_flexedAndTensedArm': 'Arm flexed and tensed',
    'anthropometry_forearm': 'Forearm',
    'anthropometry_wrist': 'Wrist',
    'anthropometry_chest': 'Chest',
    'anthropometry_waist_minimal': 'Waist (minimal)',
    'anthropometry_waist_iliac': 'Waist (iliac)',
    'anthropometry_gluteal': 'Gluteal',
    'anthropometry_thigh_1cm_below_gluteal': 'Thigh 1 cm below the gluteal',
    'anthropometry_tight': 'Thigh',
    'anthropometry_calf': 'Calf',
    'anthropometry_ankle': 'Ankle',
    'anthropometry_acromialeRadiale': 'Arm (acromiale-radiale )',
    'anthropometry_radialeStylion': 'Forearm (radiale-stylion)',
    'anthropometry_midstylion_dactylion': 'Midstylion-dactylion',
    'anthropometry_iliospinaleHeight': 'Iliospinale height',
    'anthropometry_trochanterionHeight': 'Trochanterion Height',
    'anthropometry_trochanterionTibialeLaterale': 'Trochanterion-tibiale laterale',
    'anthropometry_tibialeLateraleHeight': 'Tibiale laterale height',
    'anthropometry_tibialeMedialeSphyrionTibiale': 'Tibiale mediale-sphyrion tibiale',
    'anthropometry_biacromial': 'Biacromial',
    'anthropometry_anterior_posterior_abdominal_depth': 'Anterior-posterior abdominal depth',
    'anthropometry_biiliocristal': 'Biiliocristal',
    'anthropometry_bimalleolar': 'Bimalleolar',
    'anthropometry_foot_length': 'Foot length',
    'anthropometry_transverse_chest': 'Transverse chest',
    'anthropometry_anterior_posterior_chest_depth': 'Anterior-posterior chest depth',
    'anthropometry_bi_epicondylar_humerus': 'Bi-epicondylar humerus',
    'anthropometry_bi_epicondylar_femur': 'Bi-epicondylar femur',
    'anthropometry_bi_estiloideo': 'Bi-styloid',
    'alcohol_consumption_none': 'None',
    'alcohol_consumption_1_portion': '1 portion',
    'alcohol_consumption_2_portions': '2 portions',
    'alcohol_consumption_more_than_2_portions': '> 2 portions',
    'expectations_weightLoss': 'Weight Loss',
    'expectations_weightGain': 'Weight Gain',
    'expectations_muscleMassGain': 'Muscle Mass Gain',
    'expectations_other': 'Other',
    'level_low': 'Low',
    'level_medium': 'Medium',
    'level_elevate': 'Elevate',
    'physical_activity_inactive': 'Inactive',
    'physical_activity_light_intensity': 'Light-intensity (1-3 days/week)',
    'physical_activity_moderate_intensity': 'Moderate-intensity (3-5 days/week)',
    'physical_activity_vigorous_intensity': 'Vigorous-intensity (6-7 days/week)',
    'never_used': 'Never used',
    'have_used': 'Have used',
    'smoke_none': 'None',
    'smoke_less_10': '<10',
    'smoke_10_to_20': '10-20',
    'smoke_20_to_40': '20-40',
    'smoke_more_than_40': '>40',
    'appetite_inappetent': 'No Appetite',
    'appetite_regular': 'Regular',
    'appetite_moderate': 'Moderate',
    'appetite_strong': 'Strong',
    'nutrition_food_beans': 'Beans',
    'nutrition_food_dairy': 'Dairy',
    'nutrition_food_drinks': 'Drinks',
    'nutrition_food_eggs': 'Eggs',
    'nutrition_food_fish': 'Fish',
    'nutrition_food_fruits': 'Fruits',
    'nutrition_food_grains/bread/pasta': 'Grains/Bread/Pasta',
    'nutrition_food_meat': 'Meat',
    'nutrition_food_nuts': 'Nuts',
    'nutrition_food_prepared meals': 'Prepared meals',
    'nutrition_food_poultry': 'Poultry',
    'nutrition_food_seafood': 'Seafood',
    'nutrition_food_sweets': 'Sweets',
    'nutrition_food_vegetables': 'Vegetables',
    'nutrition_food_other': 'Other',
    'nutrition_allergies_and_intolerances_bee pollen/propolis': 'Bee pollen/propolis',
    'nutrition_allergies_and_intolerances_beef chicken': 'Beef Chicken',
    'nutrition_allergies_and_intolerances_celery': 'Celery',
    'nutrition_allergies_and_intolerances_crustaceans': 'Crustaceans',
    'nutrition_allergies_and_intolerances_eggs': 'Eggs',
    'nutrition_allergies_and_intolerances_fish': 'Fish',
    'nutrition_allergies_and_intolerances_gluten-containing cereals':
        'Gluten-containing cereals, namely: wheat, spelled, Khorasan wheat, rye, barley, oats',
    'nutrition_allergies_and_intolerances_latex (natural rubber)': 'Latex (natural rubber)',
    'nutrition_allergies_and_intolerances_lupine': 'Lupine',
    'nutrition_allergies_and_intolerances_mango': 'Mango',
    'nutrition_allergies_and_intolerances_milk lactose': 'Milk Lactose',
    'nutrition_allergies_and_intolerances_milk': 'Milk',
    'nutrition_allergies_and_intolerances_molluscs': 'Mollusks',
    'nutrition_allergies_and_intolerances_mustard': 'Mustard',
    'nutrition_allergies_and_intolerances_nuts':
        'Nuts, namely, almonds, hazelnuts, walnuts, cashews, pecans, Brazil nuts, pistachios, macadamia or Queensland nuts',
    'nutrition_allergies_and_intolerances_peach': 'Peach',
    'nutrition_allergies_and_intolerances_peanuts': 'Peanuts',
    'nutrition_allergies_and_intolerances_pork': 'Pork',
    'nutrition_allergies_and_intolerances_royal jelly': 'Royal Jelly',
    'nutrition_allergies_and_intolerances_sesame seeds': 'Sesame seeds',
    'nutrition_allergies_and_intolerances_soy': 'Soy',
    'nutrition_allergies_and_intolerances_sulphur dioxide and sulphites': 'Sulphur dioxide and sulphites',
    'nutrition_allergies_and_intolerances_tomato': 'Tomato',
    'diet_atkins': 'Atkins',
    'diet_blood_type': 'Blood Type',
    'diet_clear_liquid': 'Clear Liquid',
    'diet_dash': 'DASH',
    'diet_diabetic': 'Diabetic',
    'diet_diary_free': 'Diary Free',
    'diet_energy_restricted': 'Energy Restricted',
    'diet_full_liquid': 'Full Liquid',
    'diet_general': 'General',
    'diet_gluten_free': 'Gluten Free',
    'diet_glycemic_index': 'Glycemic-index',
    'diet_high_calorie': 'High Calorie',
    'diet_high_protein': 'High Protein',
    'diet_high_fiber': 'High Fiber',
    'diet_ketogenic': 'Ketogenic',
    'diet_lactose_free': 'Lactose Free',
    'diet_low_carb': 'Low Carb',
    'diet_low_cholesterol': 'Low Cholesterol',
    'diet_low_fat': 'Low Fat',
    'diet_low_potassium': 'Low Potassium',
    'diet_low_residue': 'Low Residue',
    'diet_low_fiber': 'Low Fiber',
    'diet_low_sodium': 'Low Sodium',
    'diet_low_fodmap': 'Low-fodmap',
    'diet_macrobiotic': 'Macrobiotic',
    'diet_mediterranean': 'Mediterranean',
    'diet_ncs': 'No Concentrated Sweets (NCS)',
    'diet_paleo': 'Paleo',
    'diet_pureed': 'Pureed',
    'diet_raw_food': 'Raw Food',
    'diet_renal': 'Renal',
    'diet_soft': 'Soft',
    'diet_vegan': 'Vegan',
    'diet_vegetarian_flexitarian': 'Vegetarian (Flexitarian)',
    'diet_vegetarian_lacto': 'Vegetarian (Lacto)',
    'diet_vegetarian_lacto_ovo': 'Vegetarian (Lacto-Ovo)',
    'diet_vegetarian_ovo': 'Vegetarian (Ovo)',
    'diet_vegetarian_pescatarian': 'Vegetarian (Pescatarian)',
    'diet_weight_watchers': 'Weight Watchers',
    'diet_whole_30': 'Whole 30',
    'bowel_normal': 'Normal',
    'bowel_constipation': 'Constipation',
    'bowel_diarrhea': 'Diarrhea',
    'bowel_irregular': 'Irregular',
    'bristol_type1': 'Type 1: Separate hard lumps, like nuts (hard to pass)',
    'bristol_type2': 'Type 2: Sausage-shaped, but lumpy',
    'bristol_type3': 'Type 3: Like a sausage but with cracks on its surface',
    'bristol_type4': 'Type 4: Like a sausage or snake, smooth and soft',
    'bristol_type5': 'Type 5: Soft blobs with clear cut edges (easy to pass)',
    'bristol_type6': 'Type 6: Fluffy pieces with ragged edges, a mushy stool',
    'bristol_type7': 'Type 7: Watery, no solid pieces, entirely liquid',
    'reasons_weight_loss': 'Weight loss',
    'reasons_weight_gain': 'Weight gain',
    'reasons_muscle_mass_gain': 'Muscle mass gain',
    'reasons_food_allergies': 'Food allergies',
    'reasons_overall': 'Overall nutrition improvement',
    'reasons_performance': 'Sport performance improvement',
    'reasons_food': 'Food routine',
    'reasons_disease': 'Associated disease',
    'reasons_well_being': 'Well being',
    'reasons_other': 'Other',
    'family_itself': 'Itself',
    'family_partner': 'Partner',
    'family_father': 'Father',
    'family_mother': 'Mother',
    'family_son': 'Son',
    'family_daughter': 'Daughter',
    'family_other': 'Other',
    'gender_male': 'Male',
    'gender_female': 'Female',
    'gender_other': 'Other',
    '<1 day/week': '<1 day/week',
    '1-2 days/week': '1-2 days/week',
    '3-4 days/week': '3-4 days/week',
    '>4 days/week': '>4 days/week',
    'walking_pleasure': 'Walking - for pleasure',
    'walking_work': 'Walking – to and from work',
    'walking_during_work': 'Walking – during work break',
    'mowing_the_lawn_ridding': 'Mowing the lawn - with riding mower',
    'mowing_the_lawn_walking': 'Mowing the lawn – walking behind power mower',
    'mowing_the_lawn_pushing': 'Mowing the lawn – pushing hand mower',
    'raking': 'Raking',
    'gardening': 'Gardening',
    'hiking': 'Hiking',
    'jogging': 'Jogging',
    'biking': 'Biking',
    'cycling': 'Exercise cycling',
    'dancing': 'Dancing',
    'aerobic': 'Aerobics',
    'bowling': 'Bowling',
    'golf_power_cart': 'Golf: riding a power cart',
    'golf_walking_cart': 'Golf: walking, pulling clubs on cart',
    'gold_clubs': 'Golf: walking and carrying clubs',
    'single_tennis': 'Single tennis',
    'doubles_tennis': 'Doubles tennis',
    'racket_ball': 'Racket ball',
    'calisthenics': 'Calisthenics',
    'swimming': 'Swimming',
    'assistance_walking': 'Assistance in Walking',
    'rise_chair': 'Rise from a Chair',
    'climb_stairs': 'Climb Stairs',
    'falls': 'Falls',
    'sarc_f_none': 'None',
    'sarc_f_some': 'Some',
    'sarc_f_a_lot_or_unable': 'A lot or unable',
    'sarc_f_a_lot_use_aids_or_unable': 'A lot, use aids or unable',
    'sarc_f_a_lot_or_unable_without_help': 'A lot or unable without help',
    'sarc_f_1_3_falls': '1-3 falls',
    'sarc_f_4_or_more_falls': '4 or more falls',
    'goals': 'Goals',
    'reasons_appointment': 'Reasons for Appointment',
    'expectations': 'Expectations',
    'other_information': 'Other Information',
    'motivation_title': 'Motivation',
    'personal_social': 'Personal and Social',
    'stress_level': 'Stress Level',
    'physical_activity': 'Physical Activity',
    'mobility_issues': 'Mobility Issues',
    'smoker': 'Smoker',
    'alcohol_consumption': 'Alcohol Consumption',
    'drugs': 'Drugs',
    'sleep_quantity': 'Sleep Quantity',
    'sleep_quality': 'Sleep Quality',
    'nutrition': 'Nutrition',
    'daily_meals': 'Daily Meals',
    'appetite': 'Appetite',
    'diet_type': 'Diet Type',
    'water_intake': 'Water Intake',
    'bowel_movements': 'Bowel Movements',
    'bristol_stool_scale': 'Bristol Stool Scale',
    'intestinal_gases': 'Intestinal Gases',
    'indigestion': 'Indigestion',
    'nausea': 'Nausea',
    'responsible_for_groceries': 'Responsible for Groceries',
    'favorite_food': 'Favorite Food',
    'disliked_food': 'Disliked Food',
    'allergies_and_intolerances': 'Allergies and Intolerances',
    'body_fat': 'Body Fat',
    'muscle_mass': 'Muscle Mass',
    'bone_mass': 'Bone Mass',
    'residual_mass': 'Residual Mass',
    'lean_mass': 'Lean Mass',
    'visceral_fat': 'Visceral Fat',
    'no_intention': 'No intention',
    'medium_intention': 'Medium intention',
    'certain': 'certain',
    'hours_night': 'hours/night',
    'entity': 'Entity',
    'manager': 'Manager',
    'professional': 'Professional',
    'client': 'Client',
    'signin_as': 'Sign in as',
    'username': 'Username',
    'password': 'Password',
    'login': 'Login',
    'logout': 'Logout',
    'profile': 'Profile',
    'settings': 'Settings',
    'no_account': "Don't have a personal account? ",
    'register': 'Register',
    'new_entity': 'Looking to create a entity?',
    'select_entity_continue': 'Select an entity to continue',
    'no_entities_found': 'It seems you don\'t have any entities.',
    'ask_admin_for_entity': 'Please ask your administrator to invite you to an entity or',
    'softdiet_offline': 'You are offline. For this page to properly work, you need to be online.',
    'softdiet_error': 'Error loading the page. Please try again, or contact your administrator.',
    'register_entity': 'Create a new entity',
    'info_register_entity': 'Must be legal representative',
    'forgot_password': 'Forgot password?',
    'register_steps1': 'Entity information',
    'register_steps2': "Select the legal representative and entity manager",
    'register_steps3': 'Select the first professional',
    'register_steps4': 'Finish',
    'next': 'Next',
    'back': 'Back',
    'yes': 'Yes',
    'no': 'No',
    'of': 'of',
    'super_manager': 'Super Manager',
    'my_entity': 'My Entity',
    'external_entities': 'External Entities',
    'have_access': 'Have access',
    'gave_access': 'Gave access',
    'managers': 'Managers',
    'manager_profile': 'Manager Profile',
    'professional_profile': 'Professional Profile',
    'my_professionals': 'My Professionals',
    'associations': 'Associations',
    'external_professionals': 'Associations to external entities',
    'my_clients': 'My Clients',
    'client_profile': 'Client Profile',
    'external_clients': 'External Clients',
    'add': 'add',
    'edit': 'Edit',
    'edit_role': 'Edit role',
    'save': 'Save',
    'cancel': 'Cancel',
    'delete': 'Delete',
    'reset': 'Reset',
    'disassociate': 'Disassociate',
    'search': 'Search',
    'label_name': 'Name',
    'label_username': 'Username',
    'label_email': 'Email address',
    'label_business_username': 'Business username',
    'label_business_email': 'Business email address',
    'label_choose_country': 'Choose a country',
    'label_fiscal_id': 'Fiscal ID',
    'label_birthday': 'Birthday',
    'label_gender': 'Sex',
    'label_ethnicity': 'Ethnicity',
    'label_street': 'Street',
    'label_postal_code': 'Postal Code',
    'label_city': 'City',
    'label_locality': 'Locality',
    'label_state': 'State / Province / Region',
    'label_country': 'Country',
    'label_mobile': 'Mobile',
    'label_phone': 'Phone',
    'label_language': 'Language',
    'label_status': 'Status',
    'label_permissions': 'Permission',
    'label_role': 'Role',
    'yup_short': 'Too Short!',
    'yup_long': 'Too Long!',
    'yup_email_valid': 'Email must be a valid email address',
    'yup_country_required': 'Country is required',
    'yup_fiscal_id_required': 'Fiscal ID is required',
    'yup_entity_required': 'Entity is required',
    'yup_name_required': 'Name is required',
    'yup_email_required': 'Email is required',
    'yup_username_required': 'Username is required',
    'yup_username_invalid': 'Username can only contain letters, numbers, underscores and dots',
    'yup_password_required': 'Password is required',
    'yup_street_required': 'Street is required',
    'yup_postal_code_required': 'Postal Code is required',
    'yup_locality_required': 'Locality is required',
    'yup_city_required': 'City is required',
    'yup_state_required': 'State / Province / Region is required',
    'yup_gender_required': 'Sex is required',
    'yup_birthdate_required': 'Birthday is required',
    'start_new_entity': 'Get started with a new Entity.',
    'same_person': 'Same person as the legal representative',
    'rows_per_page': 'Rows per page:',
    'search_manager': 'Search Manager',
    'search_professional': 'Search Professional',
    'search_entity': 'Search Entity',
    'search_client': 'Search Client',
    'view_profile': 'View profile',
    'my_profile': 'My Profile',
    'select_entity': 'Select Entity',
    'password_reset': 'Password reset',
    'need_username_entity_reset': 'Your username and associated entity are required.',
    'password_reset_email_sent_success':
        'If it is a valid account, an email must have been sent to your account associated with entity {{entityName}} with username {{username}}.',
    'info_no_entity_for_fiscal_id': 'No entity found with this fiscal id.',
    'info_no_user_for_fiscal_id':
        'No {{user_type}} found with this fiscal id. Please fill in the {{user_type}} user data.',
    'register_entity_details_info':
        'The entity {{entity_name}} is being created, located in {{entity_country}} with the tax identification number {{entity_fiscalID}}.',
    'register_legal_representative_details_info':
        'Your legal representative is {{legal_representative_name}} with the tax number {{legal_representative_fiscalID}} in {{legal_representative_fiscalCountry}} ',
    'register_operations_manager_details_info':
        'to which you have associated a manager with the name {{operations_manager_name}} and tax identification number {{operations_manager_fiscalID}} in {{operations_manager_fiscalCountry}}.',
    'register_same_operations_manager_details_info': 'to which he was also associated as a manager.',
    'error_regist': 'Registration error',
    'filter': 'Filter',
    'filter_list': 'Filter list',
    'search_not_found': 'Not Found',
    'search_no_results': 'No results found for',
    'search_try_again': '. Try checking for typos or using complete words.',
    'add_entity': 'Add external entity',
    'add_user': 'Add {{user_type}} to {{entity}}',
    'add_professional_success':
        '{{user_type}} added successfully.\nEmail for verification has been sent to {{user_email}}.',
    'disassociate_manager': 'Disassociate manager?',
    'disassociate_manager_info': 'Are you sure you want to disassociate this manager?',
    'disassociate_professional': 'Disassociate professional?',
    'disassociate_professional_info': 'Are you sure you want to disassociate this professional?',
    'user_is_not_confirmed_yet': 'User is not confirmed yet',
    'unique_id_already_exists': 'User already exists with the pair {{unique_id_country}} {{unique_id_number}}',
    'username_already_exists': 'Username already exists.',
    'person_already_a_professional_in_entity': 'This person is already a professional in the specified entity.',
    'person_already_a_manager_in_entity': 'This person is already a manager in the specified entity.',
    'entity_unique_id_already_exists': 'An entity already exists with this Fiscal ID.',
    'motivation_description':
        '0 to 2: Pre-contemplation - neither intention nor exercise\nDO NOT COME TO THE GYM Unless the doctors oblige them (GIVE UP SOON)\n\n2 to 4: Contemplation - intention, but no exercise\nUNLIKELY TO COME GYM BUT WHEN THEY COME THEY GIVE UP\n\n4 to 6: Preparation - occasional intention and exercise\nCome TO THE GYM BUT HAVE STRONG POSSIBILITY TO GIVE UP (GO AND COME)\n\n6 to 8: Regular exercise for a short period of time\nDO PHYSICAL EXERCISE BUT NOT REGULARLY\n\n8 to 10: Maintenance - regular exercise/n DO REGULAR EXERCISE',
    'download_report': 'Download Report',
    'template_type': 'Choose the template',
    'complete': 'Complete',
    'frailty': 'Frailty',
    'isak': 'ISAK',
    'appointments_list': 'Appointments list',
    'report': 'Report',
    'body_composition': 'Body Composition',
    'last_appointment': 'Last appointment',
    'age': 'Age',
    'handgrip': 'Handgrip',
    'bmi': 'BMI',
    'lipowise': 'Lipowise',
    'gripwise': 'Gripwise',
    'type': 'Type',
    'value': 'Value',
    'ref_value': 'Reference Value',
    'equation': 'Equation',
    'other_skinfold_calliper': 'Other Skinfold Calliper',
    'bioimpedance': 'Bioimpedance',
    'anthropometry': 'Anthropometry',
    'waist_hip_ratio': 'Waist-Hip Ratio',
    'weight': 'Weight',
    'height': 'Height',
    'sitting_height': 'Sitting Height',
    'arm_span': 'Arm Span',
    'girths': 'Girths',
    'lenghts_heights': 'Lengths and Heights',
    'breadths_depths': 'Breadths and Depths',
    'somatotype': 'Somatotype',
    'endomorphy': 'Endomorphy',
    'mesomorphy': 'Mesomorphy',
    'ectomorphy': 'Ectomorphy',
    'skinfold_measurements': 'Skinfold Measurements',
    'measure': 'Measure',
    'skinfold_compressibility': 'Skinfold Compressibility',
    'skinfold': 'Skinfold',
    'thickness': 'Thickness',
    'time': 'Time',
    'geriatric_assessment': 'Geriatric Assessment (physical)',
    'weigh_loss': 'Weight Loss',
    'energy_expenditure': 'Energy Expenditure',
    'exhaustion': 'Exhaustion',
    'grip_strenght': 'Grip Strength',
    'walk_time': 'Walk Time',
    'frailty_score': 'Frailty Score',
    'sarcopenia': 'Sarcopenia',
    'muscle_strength': 'Muscle Strength',
    'sit_to_stand': 'Sit to Stand (5x)',
    'muscle_quantity_quality': 'Muscle Quantity/Quality',
    'muscle_quantity_estimation': 'Muscle Quantity Estimation',
    'calf_girth': 'Calf Girth',
    'mid_arm_muscle_girth': 'Mid Muscle Arm Circumference',
    'physical_performance': 'Physical Performance',
    'gait_speed_4m': 'Gait Speed (4 m)',
    'strength': 'Strength',
    'left_hand': 'Left hand',
    'right_hand': 'Right hand',
    'dominant': 'dominant',
    'non_dominant': 'non-dominant',
    'size': 'Size',
    'measures': 'Measures',
    'max_strength': 'Max. Strength',
    'avg_strength': 'Average Strength',
    'strength_profile': 'Strength Profile',
    'other_dynamometer': 'Other Dynamometer',
    'surveys': 'Surveys',
    'total': 'Total',
    'sarc_f': 'SARC-F',
    'answer': 'Answer',
    'score': 'Score',
    'other_tests': 'Other Tests',
    'last_year': 'last year',
    'no_appointments': 'No appointments',
    'error_login': 'You did not sign in correctly or your account is disabled.',
    'enter_username': 'Unknown username, please verify.',
    'identified_risks': 'Identified Risks',
    'alcohol': 'Alcohol',
    'sedentarism': 'Sedentarism',
    'overweight': 'Overweight',
    'sleep': 'Sleep',
    'evolution': 'Evolution',
    'maximum': 'Maximum',
    'average': 'Average',
    'skinfold_values': 'Skinfold values',
    'email': 'Email',
    'identification': 'Identification',
    'appointment_date': 'Appointment Date',
    'right': 'Right',
    'left': 'Left',
    'body_comp': 'Body Composition Analysis',
    'frailty_sarcopenia': 'Physical Frailty and Sarcopenia',
    'reduced': 'Reduced',
    'associate': 'Associate',
    'associate_manager': 'Associate manager?',
    'associate_manager_info': 'Are you sure you want to associate this manager?',
    'associate_professional': 'Associate professional?',
    'associate_professional_info': 'Are you sure you want to associate this professional?',
    'note': 'Observations',
    'regist_ok': 'Register Success. Check your email!',
    'invalid_token': 'Your session has expired. Please Login!',
    'manager_login': 'Entity Management',
    'professional_login': 'Patients Management',
    'info_same_user': 'This is the same person as the legal representative!',
    'median': 'Median',
    'mst_abbott_title': 'Malnutrition Screening Tool (MST)',
    'full_mna_title': 'Full MNA®',
    'survey_not_complete': 'Not all questions are answered',
    'mst_abbott_S2Score1A': 'MST = 0 or 1 not at Risk',
    'mst_abbott_S2Score1B':
        'Eating well with little or no weight loss\nIf length of stay exceeds 7 days, then rescreen, repeating weekly as needed.',
    'mst_abbott_S2Score2A': 'MST = 2 or more at Risk',
    'mst_abbott_S2Score2B':
        'Eating poorly and/or recent weight loss.\nRapidly implement nutrition interventions. Perform nutrition consult within 24-72 hrs, depending on risk.',
    'full_mna_score1': 'Screening score (subtotal max. 14 points)',
    'full_mna_score1A': '12-14 points: Normal nutritional status',
    'full_mna_score1B': '8-11 points: At risk of malnutrition',
    'full_mna_score1C': '0-7 points: Malnourished',
    'full_mna_score2': 'Assessment (max. 16 points)',
    'full_mna_score3': 'Total Assessment (max. 30 points)',
    'full_mna_score4': 'Malnutrition Indicator Score',
    'full_mna_score4A': '24 to 30 points Normal nutritional status',
    'full_mna_score4B': '17 to 23.5 points At risk of malnutrition',
    'full_mna_score4C': 'Less than 17 points Malnourished',
    'same_username': 'Same username as the legal representative',
    'entity_name_already_exists': 'An entity already exists with this Name.',
    'body_mass': 'Fat Mass',
    'matiegka': 'Matiegka',
    'rocha': 'Rocha',
    'wurch': 'Wurch',
    'more_5%': 'more than 5%',
    'less_383kcals': 'less than 383 kcals',
    'less_270kcals': 'less than 270 kcals',
    'more_2day_week': 'more than 2 days/week',
    'less_29kgf': 'less than 29 kgf',
    'less_30kgf': 'less than 30 kgf',
    'less_32kgf': 'less than 32 kgf',
    'less_17kgf': 'less than 17 kgf',
    'less_17.3kgf': 'less than 17.3 kgf',
    'less_18kgf': 'less than 18 kgf',
    'less_21kgf': 'less than 21 kgf',
    'more_6s': 'more than 6 s',
    'more_7s': 'more than 7 s',
    'frailty_robust': 'Robust',
    'frailty_pre_frail': 'Pre-frail',
    'frailty_frail': 'Frail',
    'result_must_confirmed_professional': 'This result must be confirmed by a professional',
    'sarc_f_score': 'SARC-F Score',
    'find_cases': 'Find cases',
    'no_sarcopenia': 'No Sarcopenia',
    'predictive_sarcopenia': 'Predictive Sarcopenia',
    'more_equal_4': 'more or equal to 4',
    'assess': 'Assess',
    'less_27kg': 'less than 27 kgf',
    'less_16kg': 'less than 16 kgf',
    'more_15s': 'more than 15 seconds',
    'confirm': 'Confirm',
    'muscle_estimation': 'Muscle Estimation',
    'less_20kg': 'less than 20 kg',
    'less_15kg': 'less than 15 kg',
    'less_equal_31cm': 'less or equal to 31 cm',
    'less_equal_26_3cm': 'less or equal than 26.3 cm',
    'less_equal_20_2cm': 'less or equal than 20.2 cm',
    'severity': 'Severity',
    'more_5s': 'more than 5 seconds',
    'sarcopenia_score': 'Sarcopenia Score',
    'presence_sarcopenia': 'Presence Sarcopenia',
    'severe_sarcopenia': 'Severe Sarcopenia',
    'criteria': 'Criteria',
    'results': 'Results',
    'presence': 'Presence',
    'parameters': 'Parameters',
    'dashboard': 'Dashboard',
    'anamnese': 'Anamnese',
    'appointments': 'Appointments',
    'already_have_an_account': 'Already have an account? ',
    'all_steps_completed': 'All steps completed.',
    "measurement_profiles": "Measurement Profiles",
    'my_patients': 'My Patients',
    'search_patient': 'Search Patient',
    'patient': 'Patient',

    'create_measurement_profile': 'Create Measurement Profile',
    'profile_name': 'Profile Name',
    'step_list': 'Step List',
    'add_step': 'Add Step',
    "S-AEROBIC-ENDURANCE": "Aerobic Endurance",
    "S-ANTHRO": "Anthropometry",
    "S-ANTHRO-BREADTHS-DEPTHS": "Breadths and Depths",
    "S-ANTHRO-GIRTHS": "Girths",
    "S-ANTHRO-LENGTHS-HEIGHTS": "Lengths and Heights",
    "S-BASIC-MEASUREMENTS": "Basic Measurements",
    "S-BIO-IMPEDANCE": "Bioimpedance",
    "S-BLOOD-PRESSURE": "Blood Pressure",
    "S-BLOOD-TESTS": "Blood Tests",
    "S-CATEGORY-ANALYTICAL-DATA": "Analytical Data",
    "S-CATEGORY-ANTHROPOMETRY": "Anthropometry",
    "S-CATEGORY-ERGONOMIC": "Ergonomic",
    "S-CATEGORY-FOLLOW-UP-SURVEYS": "Follow-Up Surveys",
    "S-CATEGORY-FUNCTIONAL": "Functional",
    "S-CATEGORY-MORPHOLOGICAL": "Morphological",
    "S-CATEGORY-OTHERS": "Others",
    "S-CATEGORY-PRESETS": "Presets",
    "S-GOALS": "Goals",
    "S-HAND-CONFIGURATION": "Hand Configuration",
    "S-INITIAL-PRESET-CHECKPOINT": "Initial Checkpoint",
    "S-LIMBS-BALANCE": "Limb Balance",
    "S-MANUAL-HAND-STRENGTH": "Hand Strength (Manual)",
    "S-MANUAL-OTITEST": "Otitest (Manual)",
    "S-MANUAL-SKIN": "Skinfolds (Manual)",
    "S-MANUAL-STRENGTH-ABDUCTION": "Leg Abduction and Adduction (Manual)",
    "S-MANUAL-TEMP": "Temperature (Manual)",
    "S-MEDICAL-AND-CLINICAL": "Medical and Clinical",
    "S-MOBILITY-AND-STABILITY": "Mobility and Stability",
    "S-NOTES": "Make Notes",
    "S-NUTRITION": "Nutrition",
    "S-OTITEST": "Otitest (Automatic)",
    "S-OXIMETER": "Oximeter",
    "S-PERSONAL-AND-SOCIAL": "Personal and Social",
    "S-PHOTOGRAMETRY": "Photogrammetry",
    "S-PHYSICAL-ACTIVITY-AND-SPORTS": "Physical Activity and Sports",
    "S-POSTURE": "Posture",
    "S-QUALITY-PRESET-CHECKPOINT": "Muscle Quality Checkpoint",
    "S-SARCOPENIA-ASSESSMENT": "Sarcopenia",
    "S-SEVERITY-PRESET-CHECKPOINT": "Sarcopenia Severity Checkpoint",
    "S-SIT-TO-STAND": "Sit to Stand",
    "S-SIT-TO-STAND-INTERVAL": "Sit to Stand Interval",
    "S-SKIN": "Skinfolds (Lipowise)",
    "S-SKINFOLD-ORDER": "Skinfold Measurement Order",
    "S-STRENGTH": "Strength (Gripwise)",
    "S-STRENGTH-ABDUCTION": "Leg Abduction and Adduction (Gripwise)",
    "S-STRENGTH-PRESET-CHECKPOINT": "Muscle Strength Checkpoint",
    "S-SURVEY": "Surveys",
    "S-SURVEY-PRESET-CHECKPOINT": "SarcF Survey",
    "S-TEMP-OTITEST": "Temperature (Otitest)",
    "S-UP-AND-GO": "TUG (3+3m)",
    "S-WALK": "Walking Time (15 ft)",
    "S-WEIGHT": "Weight and Height",
    "S-WEIGHT-PAST": "Weight (Past)",
    "choose_at_least_1_step": "Choose at least 1 step",
    "save_profile": "Save Profile",
    "delete_profile": "Delete Profile",
    "confirm_delete_profile": "Are you sure you want to delete this profile:",
    'patient_update_error_title': 'Error',
    'patient_update_error_description': 'An error occurred while updating the patient. Please try again.',
    'patient_update_success_title': 'Success',
    'patient_update_success_description': 'Patient updated successfully.',
    'axial_measurements': 'Axial Measurements',
    'bilateral_measurements': 'Bilateral Measurements',
    "physical_activity": "Physical Activity",
    "physical_activity_description": "Physical activity is any bodily movement produced by skeletal muscles that requires energy expenditure. It includes activities such as working, walking, dancing, swimming, and playing sports.",
    "physical_activity_field_label": "Physical Activity",
    "physical_activity_header": "Physical Activity",
    "physical_activity_inactive": "Inactive",
    "physical_activity_label": "Physical Activity",
    "physical_activity_light": "Light Intensity (1-3 days/week)",
    "physical_activity_moderate": "Moderate Intensity (3-5 days/week)",
    "physical_activity_vigorous": "Vigorous Intensity (6-7 days/week)",
    "have_not_have_used": "Have used",
    "have_not_never_used": "Never used",
    "hours_per_night": "Hours per night",
    "level": "Level",
    "level_elevate": "Elevate",
    "level_low": "Low",
    "level_medium": "Medium",
    "gender_female": "Feminine",
    "gender_label": "Sex",
    "gender_male": "Masculine",
    "gender_other": "Other",
    "ethnicity_african": "African",
    "ethnicity_asiatic": "Asiatic",
    "ethnicity_caucasian": "Caucasian",
    "ethnicity_hispanic": "Hispanic",
    "ethnicity_other": "Other",
};
