import { stores } from "../stores";

// THE OPTION HAS TO MATCH THE PLACEHOLDER IN THE TRANSLATION FILE!
export function translate(key, options = {}) {
    let languageFile = require(`../translations/${stores.userStore.language}.js`);
    let result = languageFile.default[key] || key;

    Object.entries(options).forEach(([placeholder, value]) => {
        const placeholderRegex = new RegExp(`{{${placeholder}}}`, 'g');
        result = result.replace(placeholderRegex, value);
    });

    return result;
}