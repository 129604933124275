export default {
    'fold_triceps': 'Tríceps',
    'fold_subscapular': 'Subscapular',
    'fold_biceps': 'Bíceps',
    'fold_iliacCrest': 'Cresta ilíaca',
    'fold_supraspinale': 'Supraespinal',
    'fold_abdominal': 'Abdominal',
    'fold_anteriorThigh': 'Muslo',
    'fold_medialcalf': 'Pierna',
    'fold_cheek': 'Mejilla',
    'fold_chin': 'Mentón',
    'fold_chest': 'Cofre',
    'fold_umbilical': 'Umbilical',
    'fold_knee': 'Rodilla',
    'fold_midAxillary': 'Axilar',
    'fold_lowerBack': 'Espalda baja',
    'fold_calf': 'Pierna',
    'fold_axillar': 'Axilar',
    'fold_total': 'Total',
    'eq1': 'Durnin&Womesley Tríceps',
    'eq2': 'Durnin&Womesley subscapular',
    'eq3': 'Durnin&womesley T+S',
    'eq4': 'Durnin&Womesley 4 Pliegues',
    'eq5': 'Jackson&Pollock 3 Pliegues Hombre',
    'eq6': 'Jackson&Pollock 3 Pliegues (Log) Hombre',
    'eq7': 'Jackson&Pollock 3 Pliegues Mujer',
    'eq8': 'Jackson&Pollock 3 Pliegues (Log) Mujer',
    'eq9': 'Jackson&Pollock 7 Pliegues',
    'eq10': 'Jackson&Pollock 7 Pliegues (registro)',
    'eq11': 'Jackson&Pollock 4 Pliegues Mujer',
    'eq12': 'Jackson&Pollock 4 Pliegues (log) Mujer',
    'eq13': 'Jackson&Pollock 1985 3 Pliegues Hombre',
    'eq14': 'Jackson&Pollock 1985 3 Pliegues Mujer',
    'eq15': 'Peterson',
    'eq16': 'Visser 4 Pliegues',
    'eq17': 'Visser T+B',
    'eq18': 'Matanza T+C',
    'eq19': 'Masacre T+S Hombre',
    'eq20': 'Matanza T+S Mujer',
    'eq21': 'Carter (ISAK)',
    'eq22': 'Yuhasz',
    'eq23': 'Withers Hombre',
    'eq24': 'Withers Mujer',
    'eq25': 'Novela',
    'eq26': 'Guedes (1994) Hombre',
    'eq27': 'Guedes (1994) Mujer',
    'eq28': 'Kagawa T+I Mujer',
    'eq29': 'Kagawa T+I+B Mujer',
    'eq30': 'Kagawa A+C Hombre',
    'eq31': 'Kagawa 8 Pliegues Hombre',
    'eq32': 'Kagawa T+S Hombre',
    'eq33': 'Faulkner',
    'eq34': 'Weltman',
    'eq35': 'Dezenberg',
    'eq36': 'Kannieappan (embarazada)',
    'eq37': 'Huston Presley (embarazada)',
    'eq38': 'Ball Hombre',
    'eq39': 'Ball Mujer',
    'eq40': 'Petroski Hombre',
    'eq41': 'Petroski Mujer',
    'eq42': 'Sloan Hombre',
    'eq43': 'Sloan Mujer',
    'eq44': 'Reilly Hombre',
    'eq45': 'Evans',
    'eq46': 'Eston',
    'eq47': 'Haisman Hombre',
    'eq48': 'Wilmore Behnke Hombre',
    'eq49': 'Wilmore Behnke Mujer',
    // bone mass - ISAK consense
    'eq50': 'Rocha (masa ósea)',
    'eq51': 'Martin (masa ósea)',
    // muscle weight - ISAK consense
    'eq52': 'Matiegka (peso muscular)',
    // skeletal muscle mass - ISAK consense
    'eq53': 'Lee (masa muscular)',
    'eq54': 'Poortmans (masa muscular)',
    // residual weight - ISAK consense
    'eq55': 'Wurch (peso residual)',
    // skinfold sums - ISAK consense
    'eq56': '6 Pliegues suma',
    'eq57': '8 Pliegues suma',
    'eq58': 'Suma de pliegues',
    // Somatotype Heath-Carter (2002) - ISAK consense
    'eq59': 'Endomorfo',
    'eq60': 'Mesomorfo',
    'eq61': 'Ectomorfo',
    'eq62': 'Conversión de 15 pies a 4 m.',
    'anthropometry_section_girths': 'Perímetros',
    'anthropometry_section_lengths_and_heights': 'Longitudes y alturas',
    'anthropometry_section_breadths_and_depths': 'Anchuras y profundidades',
    'anthropometry_head': 'Cabeza',
    'anthropometry_neck': 'Cuello',
    'anthropometry_relaxedArm_wrist': 'Brazo relajado',
    'anthropometry_flexedArm': 'Brazo flexionado',
    'anthropometry_flexedAndTensedArm': 'Brazo flexionado y contraído.',
    'anthropometry_forearm': 'Antebrazo',
    'anthropometry_wrist': 'Muñeca',
    'anthropometry_chest': 'Cofre',
    'anthropometry_waist_minimal': 'Cintura (mínima)',
    'anthropometry_waist_iliac': 'Cintura (ilíaca)',
    'anthropometry_gluteal': 'Caderas',
    'anthropometry_thigh_1cm_below_gluteal': 'Muslo 1 cm por debajo del glúteo',
    'anthropometry_tight': 'Muslo',
    'anthropometry_calf': 'Pierna',
    'anthropometry_ankle': 'Tobillo',
    'anthropometry_acromialeRadiale': 'Brazo (acromiale-radiale)',
    'anthropometry_radialeStylion': 'Antebrazo (radial-stylion)',
    'anthropometry_midstylion_dactylion': 'Midstylion-Dactylion',
    'anthropometry_iliospinaleHeight': 'Altura iliosospinale',
    'anthropometry_trochanterionHeight': 'Altura de trocante',
    'anthropometry_trochanterionTibialeLaterale': 'Trochanterion-tibiale laterale',
    'anthropometry_tibialeLateraleHeight': 'Altura tibial lateral',
    'anthropometry_tibialeMedialeSphyrionTibiale': 'Tibiale mediele-sphyrion tibiale',
    'anthropometry_biacromial': 'Biacromial',
    'anthropometry_anterior_posterior_abdominal_depth': 'Profundidad abdominal anterior-posterior',
    'anthropometry_biiliocristal': 'Biiliocristal',
    'anthropometry_bimalleolar': 'Bimalleolar',
    'anthropometry_foot_length': 'Largo del pie',
    'anthropometry_transverse_chest': 'Cofre transversal',
    'anthropometry_anterior_posterior_chest_depth': 'Profundidad del pecho anterior-posterior',
    'anthropometry_bi_epicondylar_humerus': 'Húmero bi-epicondílar',
    'anthropometry_bi_epicondylar_femur': 'Fémur bi-epicondíleo',
    'anthropometry_bi_estiloideo': 'Bi-estiloideo',
    'alcohol_consumption_none': 'Ninguna',
    'alcohol_consumption_1_portion': '1 porción',
    'alcohol_consumption_2_portions': '2 porciones',
    'alcohol_consumption_more_than_2_portions': '> 2 porciones',
    'expectations_weightLoss': 'Pérdida de peso',
    'expectations_weightGain': 'Aumento de peso',
    'expectations_muscleMassGain': 'Aumento de masa muscular',
    'expectations_other': 'Otra',
    'level_low': 'Bajo',
    'level_medium': 'Medio',
    'level_elevate': 'Elevar',
    'physical_activity_inactive': 'Inactiva',
    'physical_activity_light_intensity': 'Intensidad ligera (1-3 días/semana)',
    'physical_activity_moderate_intensity': 'Intensidad moderada (3-5 días/semana)',
    'physical_activity_vigorous_intensity': 'Intensidad vigorosa (6-7 días/semana)',
    'never_used': 'Nunca usado',
    'have_used': 'Han utilizado',
    'smoke_none': 'Ninguna',
    'smoke_less_10': '<10',
    'smoke_10_to_20': '10-20',
    'smoke_20_to_40': '20-40',
    'smoke_more_than_40': '>40',
    'appetite_inappetent': 'Sin apetito',
    'appetite_regular': 'Regular',
    'appetite_moderate': 'Moderar',
    'appetite_strong': 'Fuerte',
    'nutrition_food_beans': 'Frijoles',
    'nutrition_food_dairy': 'Lácteos',
    'nutrition_food_drinks': 'Bebidas',
    'nutrition_food_eggs': 'Huevos',
    'nutrition_food_fish': 'Pez',
    'nutrition_food_fruits': 'Frutas',
    'nutrition_food_grains/bread/pasta': 'Granos/Pan/Pasta',
    'nutrition_food_meat': 'Carne',
    'nutrition_food_nuts': 'Nueces',
    'nutrition_food_prepared meals': 'Comidas preparadas',
    'nutrition_food_poultry': 'Aves de corral',
    'nutrition_food_seafood': 'Mariscos',
    'nutrition_food_sweets': 'Dulces',
    'nutrition_food_vegetables': 'Vegetales',
    'nutrition_food_other': 'Otra',
    'nutrition_allergies_and_intolerances_bee pollen/propolis': 'Polen de abeja/propólez',
    'nutrition_allergies_and_intolerances_beef chicken': 'Pollo de carne',
    'nutrition_allergies_and_intolerances_celery': 'Apio',
    'nutrition_allergies_and_intolerances_crustaceans': 'Crustáceos',
    'nutrition_allergies_and_intolerances_eggs': 'Huevos',
    'nutrition_allergies_and_intolerances_fish': 'Pez',
    'nutrition_allergies_and_intolerances_gluten-containing cereals':
        'Cereales que contienen gluten, a saber: trigo, deletreado, trigo khorasan, centeno, cebada, avena',
    'nutrition_allergies_and_intolerances_latex (natural rubber)': 'Látex (caucho natural)',
    'nutrition_allergies_and_intolerances_lupine': 'Lupina',
    'nutrition_allergies_and_intolerances_mango': 'Mango',
    'nutrition_allergies_and_intolerances_milk lactose': 'Lactosa de leche',
    'nutrition_allergies_and_intolerances_milk': 'Leche',
    'nutrition_allergies_and_intolerances_molluscs': 'Moluscos',
    'nutrition_allergies_and_intolerances_mustard': 'Mostaza',
    'nutrition_allergies_and_intolerances_nuts':
        'Nueces, a saber, almendras, avellanas, nueces, anacardos, nueces, nueces de Brasil, Pistachos, Macadamia o Queensland Nuts',
    'nutrition_allergies_and_intolerances_peach': 'Durazno',
    'nutrition_allergies_and_intolerances_peanuts': 'Miseria',
    'nutrition_allergies_and_intolerances_pork': 'Cerda',
    'nutrition_allergies_and_intolerances_royal jelly': 'Jalea real',
    'nutrition_allergies_and_intolerances_sesame seeds': 'semillas de sésamo',
    'nutrition_allergies_and_intolerances_soy': 'Soja',
    'nutrition_allergies_and_intolerances_sulphur dioxide and sulphites': 'Dióxido de azufre y sulfitos.',
    'nutrition_allergies_and_intolerances_tomato': 'Tomate',
    'diet_atkins': 'Atkins',
    'diet_blood_type': 'Tipo de sangre',
    'diet_clear_liquid': 'Líquido claro',
    'diet_dash': 'PIZCA',
    'diet_diabetic': 'Diabética',
    'diet_diary_free': 'Libre diario',
    'diet_energy_restricted': 'Energía restringida',
    'diet_full_liquid': 'Líquido completo',
    'diet_general': 'General',
    'diet_gluten_free': 'Sin gluten',
    'diet_glycemic_index': 'Índice glucémico',
    'diet_high_calorie': 'Alta en calorías',
    'diet_high_protein': 'Alto en proteína',
    'diet_high_fiber': 'Alto contenido de fibra',
    'diet_ketogenic': 'Cetogénico',
    'diet_lactose_free': 'Sin lactosa',
    'diet_low_carb': 'Baja en carbohidratos',
    'diet_low_cholesterol': 'Colesterol bajo',
    'diet_low_fat': 'Bajo en grasa',
    'diet_low_potassium': 'Bajo potasio',
    'diet_low_residue': 'Residuo bajo',
    'diet_low_fiber': 'Fibra baja',
    'diet_low_sodium': 'Bajo en sodio',
    'diet_low_fodmap': 'Bajo-Fodmap',
    'diet_macrobiotic': 'Macrobiótico',
    'diet_mediterranean': 'Mediterránea',
    'diet_ncs': 'No hay dulces concentrados (NCS)',
    'diet_paleo': 'Paleo',
    'diet_pureed': 'Puré',
    'diet_raw_food': 'Alimentos crudos',
    'diet_renal': 'Renal',
    'diet_soft': 'Suave',
    'diet_vegan': 'Vegana',
    'diet_vegetarian_flexitarian': 'Vegetariano (flexitariano)',
    'diet_vegetarian_lacto': 'Vegetariano (lacto)',
    'diet_vegetarian_lacto_ovo': 'Vegetariano (lacto-ovo)',
    'diet_vegetarian_ovo': 'Vegetariano (ovo)',
    'diet_vegetarian_pescatarian': 'Vegetariano (pescatariano)',
    'diet_weight_watchers': 'Controlador de peso',
    'diet_whole_30': 'Todo el 30',
    'bowel_normal': 'Normal',
    'bowel_constipation': 'Estreñimiento',
    'bowel_diarrhea': 'Diarrea',
    'bowel_irregular': 'Irregular',
    'bristol_type1': 'Tipo 1: Bultos duros separados, como nueces (difíciles de pasar)',
    'bristol_type2': 'Tipo 2: En forma de salchicha, pero grumosa',
    'bristol_type3': 'Tipo 3: Como una salchicha pero con grietas en su superficie.',
    'bristol_type4': 'Tipo 4: Como una salchicha o serpiente, suave y suave.',
    'bristol_type5': 'Tipo 5: Blobs suaves con bordes de corte transparente (fácil de pasar)',
    'bristol_type6': 'Tipo 6: piezas esponjosas con bordes irregulares, un taburete blindado',
    'bristol_type7': 'Tipo 7: Aguo, sin piezas sólidas, totalmente líquido.',
    'reasons_weight_loss': 'Pérdida de peso',
    'reasons_weight_gain': 'Aumento de peso',
    'reasons_muscle_mass_gain': 'Aumento de masa muscular',
    'reasons_food_allergies': 'Alergias a los alimentos',
    'reasons_overall': 'Mejora general de la nutrición',
    'reasons_performance': 'Mejora del rendimiento deportivo',
    'reasons_food': 'Rutina de alimentos',
    'reasons_disease': 'Enfermedad asociada',
    'reasons_well_being': 'Bienestar',
    'reasons_other': 'Otra',
    'family_itself': 'Sí mismo',
    'family_partner': 'Compañera',
    'family_father': 'Padre',
    'family_mother': 'Madre',
    'family_son': 'Hijo',
    'family_daughter': 'Hija',
    'family_other': 'Otra',
    'gender_male': 'Masculina',
    'gender_female': 'Mujer',
    'gender_other': 'Otra',
    '<1 day/week': '<1 día/semana',
    '1-2 days/week': '1-2 dias/semana',
    '3-4 days/week': '3-4 días/semana',
    '>4 days/week': '> 4 días/semana',
    'walking_pleasure': 'Caminando - por placer',
    'walking_work': 'Caminando - hacia y desde el trabajo.',
    'walking_during_work': 'Caminando - Durante el trabajo.',
    'mowing_the_lawn_ridding': 'Cortar el césped - con la cortadora de montar',
    'mowing_the_lawn_walking': 'Cortar el césped - caminar detrás de la cortadora de corriente',
    'mowing_the_lawn_pushing': 'Cortar el césped - empujando la cortadora de manos',
    'raking': 'Rastrillar',
    'gardening': 'Jardinería',
    'hiking': 'Senderismo',
    'jogging': 'Correr',
    'biking': 'Bicicleta',
    'cycling': 'Ciclismo de ejercicio',
    'dancing': 'Bailando',
    'aerobic': 'Aeróbicos',
    'bowling': 'Bolos',
    'golf_power_cart': 'Golf: montando un carrito de potencia',
    'golf_walking_cart': 'Golf: caminar, tirando de clubes en el carrito.',
    'gold_clubs': 'Golf: caminar y llevar clubes.',
    'single_tennis': 'Tenis único',
    'doubles_tennis': 'Dobles Tenis',
    'racket_ball': 'Bola de raqueta',
    'calisthenics': 'Calistenia',
    'swimming': 'Natación',
    'assistance_walking': 'Asistencia en caminar',
    'rise_chair': 'Subida de una silla',
    'climb_stairs': 'Subir escaleras',
    'falls': 'Caídas',
    'sarc_f_none': 'Ninguna',
    'sarc_f_some': 'Algunas',
    'sarc_f_a_lot_or_unable': 'Mucho o no puede',
    'sarc_f_a_lot_use_aids_or_unable': 'Mucho, use ayudas o incapaces',
    'sarc_f_a_lot_or_unable_without_help': 'Mucho o no puede sin ayuda',
    'sarc_f_1_3_falls': '1-3 caídas',
    'sarc_f_4_or_more_falls': '4 o más caídas',
    'goals': 'Objetivos',
    'reasons_appointment': 'Razones para la cita',
    'expectations': 'Expectativas',
    'other_information': 'Otra información',
    'motivation_title': 'Motivación',
    'personal_social': 'Personal y social',
    'stress_level': 'Nivel de estrés',
    'physical_activity': 'Actividad física',
    'mobility_issues': 'Problemas de movilidad',
    'smoker': 'Fumador',
    'alcohol_consumption': 'Consumo de alcohol',
    'drugs': 'Drogas',
    'sleep_quantity': 'Cantidad de sueño',
    'sleep_quality': 'Calidad de sueño',
    'nutrition': 'Nutrición',
    'daily_meals': 'Comidas diarias',
    'appetite': 'Apetito',
    'diet_type': 'Tipo de dieta',
    'water_intake': 'La ingesta de agua',
    'bowel_movements': 'Movimientos intestinales',
    'bristol_stool_scale': 'Escala de heces de Bristol',
    'intestinal_gases': 'Gases intestinales',
    'indigestion': 'Indigestión',
    'nausea': 'Náusea',
    'responsible_for_groceries': 'Responsable de comestibles',
    'favorite_food': 'Comida favorita',
    'disliked_food': 'Alimentos disgustados',
    'allergies_and_intolerances': 'Alergias e intolerancias.',
    'body_fat': 'Grasa corporal',
    'muscle_mass': 'Masa muscular',
    'bone_mass': 'Masa ósea',
    'residual_mass': 'Masa residual',
    'lean_mass': 'Masa magra',
    'visceral_fat': 'Grasa visceral',
    'no_intention': 'Sin intención',
    'medium_intention': 'Intención media',
    'certain': 'cierta',
    'hours_night': 'horas/noche',
    'entity': 'Entidad',
    'manager': 'Gerente',
    'professional': 'Profesional',
    'client': 'Cliente',
    'signin_as': 'Registrarse como',
    'username': 'Nombre de usuario',
    'password': 'Clave',
    'login': 'Acceso',
    'logout': 'Cerrar sesión',
    'profile': 'Perfil',
    'settings': 'Ajustes',
    'no_account': '¿No tienes una cuenta personal?',
    'register': 'Registrarse',
    'new_entity': '¿Buscando crear una entidad?',
    'select_entity_continue': 'Seleccione una entidad para continuar',
    'no_entities_found': 'Parece que no tienes ninguna entidad.',
    'ask_admin_for_entity': 'Por favor, pídale a su administrador que le invite a una entidad o',
    'softdiet_offline': 'Estás desconectado. Para que esta página funcione correctamente, debes estar en línea.',
    'softdiet_error': 'Error al cargar la página. Inténtelo de nuevo o comuníquese con su administrador.',
    'register_entity': 'Crear una nueva entidad',
    'info_register_entity': 'Debe ser un representante legal',
    'forgot_password': '¿Se te olvidó tu contraseña?',
    'register_steps1': 'Información de la entidad',
    'register_steps2': 'Seleccione el Representante Legal de la Entidad',
    'register_steps3': 'Selecciona el primer profesional',
    'register_steps4': 'Finalizar',
    'next': 'Próxima',
    'back': 'Atrás',
    'yes': 'sí',
    'no': 'No',
    'of': 'de',
    'super_manager': 'Super Manager',
    'my_entity': 'Mi entidad',
    'external_entities': 'Entidades externas',
    'have_access': 'Tener acceso',
    'gave_access': 'Dio acceso',
    'managers': 'Gerentes',
    'manager_profile': 'Perfil del gerente',
    'professional_profile': 'Perfil profesional',
    'my_professionals': 'Mis profesionales',
    'associations': 'Asociaciones',
    'external_professionals': 'Asociaciones a entidades externas.',
    'my_clients': 'Mis clientes',
    'client_profile': 'Perfil del cliente',
    'external_clients': 'Clientes externos',
    'add': 'Agregar',
    'edit': 'Editar',
    'edit_role': 'Función de edición',
    'save': 'Salvar',
    'cancel': 'Cancelar',
    'delete': 'Borrar',
    'reset': 'Reiniciar',
    'disassociate': 'Desasociar',
    'search': 'Búsqueda',
    'label_name': 'Nombre',
    'label_username': 'Nombre de usuario',
    'label_email': 'Dirección de correo electrónico',
    'label_business_username': 'Nombre de usuario empresarial',
    'label_business_email': 'Dirección de correo electrónico de negocios',
    'label_choose_country': 'Elige un país',
    'label_fiscal_id': 'ID fiscal',
    'label_birthday': 'Cumpleaños',
    'label_gender': 'Sexo',
    'label_ethnicity': 'Etnicidad',
    'label_street': 'Calle',
    'label_postal_code': 'Código postal',
    'label_city': 'Ciudad',
    'label_locality': 'Localidad',
    'label_state': 'Estado / Provincia / Región',
    'label_country': 'País',
    'label_mobile': 'Móvil',
    'label_phone': 'Teléfono',
    'label_language': 'Idioma',
    'label_status': 'Estado',
    'label_permissions': 'Permiso',
    'label_role': 'Papel',
    'yup_short': '¡Demasiado corto!',
    'yup_long': '¡Demasiado largo!',
    'yup_email_valid': 'El correo electrónico debe ser una dirección de correo electrónico válida',
    'yup_country_required': 'El país es obligatorio',
    'yup_fiscal_id_required': 'Se requiere identificación fiscal',
    'yup_entity_required': 'Se requiere entidad',
    'yup_name_required': 'Se requiere el nombre',
    'yup_email_required': 'correo electronico es requerido',
    'yup_username_required': 'Se requiere nombre de usuario',
    'yup_username_invalid': 'El nombre de usuario debe contener solo letras, números, guiones bajos y puntos',
    'yup_password_required': 'Se requiere contraseña',
    'yup_street_required': 'Se requiere la calle',
    'yup_postal_code_required': 'Se requiere código postal',
    'yup_locality_required': 'Se requiere localidad',
    'yup_city_required': 'Se requiere la ciudad',
    'yup_state_required': 'Estado / provincia / región es obligatorio',
    'yup_gender_required': 'Se requiere sexo',
    'yup_birthdate_required': 'Se requiere fecha de nacimiento',
    'start_new_entity': 'Comience con una nueva entidad.',
    'same_person': 'Misma persona como representante legal',
    'rows_per_page': 'Filas por página:',
    'search_manager': 'Gerente de búsqueda',
    'search_professional': 'Buscar profesional',
    'search_entity': 'Entidad de búsqueda',
    'search_client': 'Buscar cliente',
    'view_profile': 'Ver perfil',
    'my_profile': 'Mi perfil',
    'select_entity': 'Seleccionar entidad',
    'password_reset': 'Restablecimiento de contraseña',
    'need_username_entity_reset': 'Se requiere su nombre de usuario y entidad asociada.',
    'password_reset_email_sent_success':
        'Si es una cuenta válida, debe haber sido enviado un correo electrónico a su cuenta asociada con la entidad {{entityName}} con nombre de usuario {{username}}.',
    'info_no_entity_for_fiscal_id': 'Ninguna entidad encontrada con esta ID fiscal.',
    'info_no_user_for_fiscal_id':
        'No {{user_type}} Se encuentra con esta ID fiscal. Por favor, rellene los datos de usuario {{user_type}}.',
    'register_entity_details_info':
        'La entidad {{entity_name}} se está creando, ubicada en {{entity_country}} con el número de identificación fiscal {{entity_fiscalID}}.',
    'register_legal_representative_details_info':
        'Su representante legal es {{legal_representative_name}} con el número de impuestos {{legal_representative_fiscalID}} en {{legal_representative_fiscalCountry}}',
    'register_operations_manager_details_info':
        'A los que ha asociado a un gerente con el nombre {{operations_manager_name}} y número de identificación fiscal {{operations_manager_fiscalID}} en {{operations_manager_fiscalCountry}}.',
    'register_same_operations_manager_details_info': 'A lo que también se asoció como gerente.',
    'error_regist': 'Error de registro',
    'filter': 'Filtrar',
    'filter_list': 'Lista de filtros',
    'search_not_found': 'Extraviado',
    'search_no_results': 'No se encontraron resultados para',
    'search_try_again': '. Intente verificar los errores tipográficos o usar palabras completas.',
    'add_entity': 'Añadir entidad externa',
    'add_user': 'Añadir {{user_type}} a {{entity}}',
    'add_professional_success':
        '{{user_type}} agregado con éxito.\nEl correo electrónico para la verificación se ha enviado a {{user_email}}.',
    'disassociate_manager': 'Desasociar al gerente?',
    'disassociate_manager_info': '¿Está seguro de que desea desasociar este administrador?',
    'disassociate_professional': 'Disociar profesional?',
    'disassociate_professional_info': '¿Estás seguro de que quieres disociar a este profesional?',
    'user_is_not_confirmed_yet': 'El usuario aún no está confirmado',
    'unique_id_already_exists': 'El usuario ya existe con el par {{unique_id_country}} {{unique_id_number}}',
    'username_already_exists': 'Nombre de usuario ya existe.',
    'person_already_a_professional_in_entity': 'Esta persona ya es profesional en la entidad especificada.',
    'person_already_a_manager_in_entity': 'Esta persona ya es gerente en la entidad especificada.',
    'entity_unique_id_already_exists': 'Una entidad ya existe con esta identificación fiscal.',
    'motivation_description':
        '0 a 2: Pre-contemplación - ni intención ni ejercicio\nNo vengas al gimnasio a menos que los médicos los obliguen pronto.\n\n2 a 4: Contemplación - intención, pero sin ejercicio.\nEs poco probable que venga al gimnasio, pero cuando lleguen se dan por vencidos.\n\n4 a 6: Preparación - intención ocasional y ejercicio\nVen al gimnasio, pero tiene una gran posibilidad de renunciar (ir y venir)\n\n6 a 8: Ejercicio regular por un corto período de tiempo\nHacer ejercicio físico pero no regularmente\n\n8 a 10: Mantenimiento - Ejercicio regular\nHacer ejercicio regular',
    'download_report': 'Descargar informe',
    'template_type': 'Elige la plantilla',
    'complete': 'Completa',
    'frailty': 'Fragilidad',
    'isak': 'Isak',
    'appointments_list': 'Lista de citas',
    'report': 'Informe',
    'body_composition': 'Composición corporal',
    'last_appointment': 'Última cita',
    'age': 'Años',
    'handgrip': 'Empuñadura',
    'bmi': 'IMC',
    'lipowise': 'Lipowise',
    'gripwise': 'Gripwise',
    'type': 'Escribe',
    'value': 'Valor',
    'ref_value': 'Valor de referencia',
    'equation': 'Ecuación',
    'other_skinfold_calliper': 'Otro plicómetro',
    'bioimpedance': 'Bioimpedía',
    'anthropometry': 'Antropometría',
    'waist_hip_ratio': 'Proporción cintura cadera',
    'weight': 'Peso',
    'height': 'Talla',
    'sitting_height': 'Talla sentado',
    'arm_span': 'Lapso del brazo',
    'girths': 'Perimetros',
    'lenghts_heights': 'Longitudes y alturas',
    'breadths_depths': 'Anchs y profundidades',
    'somatotype': 'Somatotipo',
    'endomorphy': 'Endomorfo',
    'mesomorphy': 'Mesomorfo',
    'ectomorphy': 'Ectomorfo',
    'skinfold_measurements': 'Medición de los pliegues',
    'measure': 'La medida',
    'skinfold_compressibility': 'Compresibilidad de los pliegues',
    'skinfold': 'Pliegues',
    'thickness': 'Espesor',
    'time': 'Hora',
    'geriatric_assessment': 'Evaluación geriátrica (física)',
    'weigh_loss': 'Pérdida de peso',
    'energy_expenditure': 'Gasto de energía',
    'exhaustion': 'Agotamiento',
    'grip_strenght': 'La fuerza de prensión',
    'walk_time': 'Pasar el tiempo',
    'frailty_score': 'Puntuación de fragilidad',
    'sarcopenia': 'Sarcopenia',
    'muscle_strength': 'Fuerza muscular',
    'sit_to_stand': 'Sit to Stand (5x)',
    'muscle_quantity_quality': 'Cantidad muscular/calidad',
    'muscle_quantity_estimation': 'Estimación de la cantidad muscular',
    'calf_girth': 'Cuello de la pierna',
    'mid_arm_muscle_girth': 'Circunferencia Muscular del Brazo',
    'physical_performance': 'Desempeño físico',
    'gait_speed_4m': 'Velocidad de marcha (4 m)',
    'strength': 'Fuerza',
    'left_hand': 'Mano izquierda',
    'right_hand': 'Mano derecha',
    'dominant': 'dominante',
    'non_dominant': 'no dominante',
    'size': 'Tamaño',
    'measures': 'Medidas',
    'max_strength': 'Max. Fuerza',
    'avg_strength': 'Fuerza media',
    'strength_profile': 'Perfil de fuerza',
    'other_dynamometer': 'Otro dinamómetro',
    'surveys': 'Encuestas',
    'total': 'Total',
    'sarc_f': 'Sarc-f',
    'answer': 'Responder',
    'score': 'Puntaje',
    'other_tests': 'Otras pruebas',
    'last_year': 'el año pasado',
    'no_appointments': 'No hay citas',
    'error_login': 'No inició sesión correctamente o su cuenta está deshabilitada.',
    'enter_username': 'Nombre de usuario desconocido, por favor verifique.',
    'identified_risks': 'Riesgos identificados',
    'alcohol': 'Alcohol',
    'sedentarism': 'Sedentarismo',
    'overweight': 'Exceso de peso',
    'sleep': 'Dormir',
    'evolution': 'Evolución',
    'maximum': 'Máxima',
    'average': 'Promedio',
    'skinfold_values': 'Valores de Piel',
    'email': 'Correo electrónico',
    'identification': 'Identificación',
    'appointment_date': 'Día de la cita',
    'right': 'Derecha',
    'left': 'Izquierda',
    'body_comp': 'Análisis de la composición corporal',
    'frailty_sarcopenia': 'Freilty físico y sarcopenia',
    'reduced': 'Reducida',
    'associate': 'Asociar',
    'associate_manager': '¿Gerente asociado?',
    'associate_manager_info': '¿Está seguro de que desea asociar este administrador?',
    'associate_professional': 'Asociado profesional?',
    'associate_professional_info': '¿Estás seguro de que quieres asociar a este profesional?',
    'note': 'Observaciones',
    'regist_ok': 'Registro exitoso. ¡Consultar su correo electrónico!',
    'invalid_token': 'Su sesión ha caducado. ¡Por favor Iniciar sesión!',
    'manager_login': 'Gestión de la entidad',
    'professional_login': 'Gestión de pacientes',
    'info_same_user': '¡Esta es la misma persona que el representante legal!',
    'median': 'Mediana',
    'mst_abbott_title': 'Herramienta de detección de malnutrición (MST)',
    'full_mna_title': 'Full MNA®',
    'survey_not_complete': 'No todas las preguntas son respondidas',
    'mst_abbott_S2Score1A': 'MST = 0 o 1 no en riesgo',
    'mst_abbott_S2Score1B':
        'Comer bien con poca o ninguna pérdida de peso.\nSi la duración de la estadía excede los 7 días, luego se reemplace, repitiendo semanalmente según sea necesario.',
    'mst_abbott_S2Score2A': 'MST = 2 o más en riesgo',
    'mst_abbott_S2Score2B':
        'Comer mal y/o pérdida de peso reciente.\nImplementar rápidamente intervenciones de nutrición. Realice una consulta de nutrición dentro de las 24-72 horas, dependiendo del riesgo.',
    'full_mna_score1': 'Puntaje de detección (Subtotal Max. 14 puntos)',
    'full_mna_score1A': '12-14 puntos: estado nutricional normal',
    'full_mna_score1B': '8-11 puntos: en riesgo de desnutrición',
    'full_mna_score1C': '0-7 puntos: desnutridos',
    'full_mna_score2': 'Evaluación (máx. 16 puntos)',
    'full_mna_score3': 'Evaluación total (máx. 30 puntos)',
    'full_mna_score4': 'Puntuación indicadora de malnutrición',
    'full_mna_score4A': '24 a 30 puntos de estado nutricional normal',
    'full_mna_score4B': '17 a 23.5 puntos en riesgo de desnutrición.',
    'full_mna_score4C': 'Menos de 17 puntos desnutridos.',
    'same_username': 'El mismo nombre de usuario que el representante legal.',
    'entity_name_already_exists': 'Una entidad ya existe con este nombre.',

    'body_mass': 'Masa Grasa',
    'matiegka': 'Matiegka',
    'rocha': 'Rocha',
    'wurch': 'Wurch',
    'more_5%': 'más del 5%',
    'less_383kcals': 'menos de 383 kcal',
    'less_270kcals': 'menos de 270 kcal',
    'more_2day_week': 'más de 2 días/semana',
    'less_29kgf': 'menos de 29 kgf',
    'less_30kgf': 'menos de 30 kgf',
    'less_32kgf': 'menos de 32 kgf',
    'less_17kgf': 'menos de 17 kgf',
    'less_17.3kgf': 'menos de 17.3 kgf',
    'less_18kgf': 'menos de 18 kgf',
    'less_21kgf': 'menos de 21 kgf',
    'more_6s': 'más de 6 s',
    'more_7s': 'más de 7 s',
    'frailty_robust': 'Robusto',
    'frailty_pre_frail': 'Pre-frágil',
    'frailty_frail': 'Frágil',
    'result_must_confirmed_professional': 'Este resultado debe ser confirmado por un profesional',
    'sarc_f_score': 'Puntuación SARC-F',
    'find_cases': 'Encontrar casos',
    'no_sarcopenia': 'Sin sarcopenia',
    'predictive_sarcopenia': 'Sarcopenia predictiva',
    'more_equal_4': 'mayor o igual a 4',
    'assess': 'Evaluar',
    'less_27kg': 'menos de 27 kgf',
    'less_16kg': 'menos de 16 kgf',
    'more_15s': 'más de 15 segundos',
    'confirm': 'Confirmar',
    'muscle_estimation': 'Estimación muscular',
    'less_20kg': 'menos de 20 kg',
    'less_15kg': 'menos de 15 kg',
    'less_equal_31cm': 'menor o igual a 31 cm',
    'less_equal_26_3cm': 'menor o igual a 26,3 cm',
    'less_equal_20_2cm': 'menor o igual a 20,2 cm',
    'severity': 'Gravedad',
    'more_5s': 'más de 5 segundos',
    'sarcopenia_score': 'Puntuación de sarcopenia',
    'presence_sarcopenia': 'Presencia Sarcopenia',
    'severe_sarcopenia': 'Sarcopenia severa',
    'criteria': 'Criterios',
    'results': 'Resultados',
    'presence': 'Presencia',
    'parameters': 'Parámetros',
    'dashboard': 'Tablero',
    'anamnese': 'Anamnés',
    'appointments': 'Consulta',
    'already_have_an_account': '¿Ya tienes una cuenta? ',
    'all_steps_completed': 'Todos los pasos completados.',
    'measurement_profiles': "Perfiles de medición",
    'my_patients': 'Mis pacientes',
    'search_patient': 'Buscar paciente',
    'patient': 'Paciente',
    'create_measurement_profile': 'Crear perfil de medición',
    'profile_name': 'Nombre del perfil',
    'step_list': 'Lista de pasos',
    'add_step': 'Agregar paso',
    "S-AEROBIC-ENDURANCE": "Resistencia Aeróbica",
    "S-ANTHRO": "Antropometría",
    "S-ANTHRO-BREADTHS-DEPTHS": "Anchuras y Profundidades",
    "S-ANTHRO-GIRTHS": "Circunferencias",
    "S-ANTHRO-LENGTHS-HEIGHTS": "Longitudes y Alturas",
    "S-BASIC-MEASUREMENTS": "Mediciones Básicas",
    "S-BIO-IMPEDANCE": "Bioimpedancia",
    "S-BLOOD-PRESSURE": "Presión Arterial",
    "S-BLOOD-TESTS": "Análisis de Sangre",
    "S-CATEGORY-ANALYTICAL-DATA": "Datos Analíticos",
    "S-CATEGORY-ANTHROPOMETRY": "Antropometría",
    "S-CATEGORY-ERGONOMIC": "Ergonómico",
    "S-CATEGORY-FOLLOW-UP-SURVEYS": "Encuestas de Seguimiento",
    "S-CATEGORY-FUNCTIONAL": "Funcional",
    "S-CATEGORY-MORPHOLOGICAL": "Morfológico",
    "S-CATEGORY-OTHERS": "Otros",
    "S-CATEGORY-PRESETS": "Preajustes",
    "S-GOALS": "Objetivos",
    "S-HAND-CONFIGURATION": "Configuración de Manos",
    "S-INITIAL-PRESET-CHECKPOINT": "Punto de Control Inicial",
    "S-LIMBS-BALANCE": "Equilibrio de las Extremidades",
    "S-MANUAL-HAND-STRENGTH": "Fuerza (Manual)",
    "S-MANUAL-OTITEST": "Otitest (Manual)",
    "S-MANUAL-SKIN": "Pliegues Cutáneos (Manual)",
    "S-MANUAL-STRENGTH-ABDUCTION": "Abducción y Aducción de Piernas (Manual)",
    "S-MANUAL-TEMP": "Temperatura (Manual)",
    "S-MEDICAL-AND-CLINICAL": "Médico y Clínico",
    "S-MOBILITY-AND-STABILITY": "Movilidad y Estabilidad",
    "S-NOTES": "Tomar Notas",
    "S-NUTRITION": "Nutrición",
    "S-OTITEST": "Otitest (Automático)",
    "S-OXIMETER": "Oxímetro",
    "S-PERSONAL-AND-SOCIAL": "Personal y Social",
    "S-PHOTOGRAMETRY": "Fotogrametría",
    "S-PHYSICAL-ACTIVITY-AND-SPORTS": "Actividad Física y Deportes",
    "S-POSTURE": "Postura",
    "S-QUALITY-PRESET-CHECKPOINT": "Punto de Control de Calidad Muscular",
    "S-SARCOPENIA-ASSESSMENT": "Evaluación de Sarcopenia",
    "S-SEVERITY-PRESET-CHECKPOINT": "Punto de Control de Gravedad de Sarcopenia",
    "S-SIT-TO-STAND": "Sentarse y Levantarse",
    "S-SIT-TO-STAND-INTERVAL": "Intervalo de Sentarse y Levantarse",
    "S-SKIN": "Pliegues Cutáneos (Lipowise)",
    "S-SKINFOLD-ORDER": "Orden de Medición de Pliegues Cutáneos",
    "S-STRENGTH": "Fuerza (Gripwise)",
    "S-STRENGTH-ABDUCTION": "Abducción y Aducción de Piernas (Gripwise)",
    "S-STRENGTH-PRESET-CHECKPOINT": "Punto de Control de Fuerza Muscular",
    "S-SURVEY": "Encuestas",
    "S-SURVEY-PRESET-CHECKPOINT": "Encuesta SarcF",
    "S-TEMP-OTITEST": "Temperatura (Otitest)",
    "S-UP-AND-GO": "TUG (3+3m)",
    "S-WALK": "Tiempo de Marcha (15 pies)",
    "S-WEIGHT": "Peso y Altura",
    "S-WEIGHT-PAST": "Peso (Anterior)",
    "choose_at_least_1_step": 'Elija al menos 1 paso',
    "save_profile": 'Guardar perfil',
    "delete_profile": 'Eliminar perfil',
    "confirm_delete_profile": '¿Estás seguro de que quieres eliminar este perfil:',
    'patient_update_error_title': 'Error',
    'patient_update_error_description': 'No se pudo actualizar el paciente. Por favor, inténtelo de nuevo.',
    'patient_update_success_title': 'Éxito',
    'patient_update_success_description': 'Paciente actualizado con éxito.',
    'axial_measurements': 'Medidas axiales',
    'bilateral_measurements': 'Medidas bilaterales',
    "physical_activity": "Actividad física",
    "physical_activity_description": "Descripción de la actividad física",
    "physical_activity_field_label": "Actividad física",
    "physical_activity_header": "Actividad física",
    "physical_activity_inactive": "Inactivo",
    "physical_activity_label": "Actividad física",
    "physical_activity_light": "Intensidad Ligera (1-3 días/semana)",
    "physical_activity_moderate": "Intensidad Moderada (3-5 días/semana)",
    "physical_activity_vigorous": "Intensidad Vigorosa (>5 días/semana)",
    "have_not_have_used": "Ha utilizado",
    "have_not_never_used": "Nunca ha utilizado",
    "hours_per_night": "Horas por noche",
    "level": "Nivel",
    "level_elevate": "Elevado",
    "level_low": "Bajo",
    "level_medium": "Medio",
    "gender_female": "Femenino",
    "gender_label": "Sexo",
    "gender_male": "Masculino",
    "gender_other": "Otro",
    "ethnicity_african": "Africana",
    "ethnicity_asiatic": "Asiática",
    "ethnicity_caucasian": "Caucásica",
    "ethnicity_hispanic": "Hispana",
    "ethnicity_other": "Otra",

};
