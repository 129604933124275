
export function fAppointValue(value, measure = '') {
  return value ? `${value} ${measure}` : `-`;
}

export function fAppointData(content, value) {
  return value != null ? content[value].label : `-`;
}

export function mapValuesToLabels(list, labelName = 'label', valueName = 'value') {
  return Object.assign({}, ...list.map(x => ({[x[valueName]]: x[labelName]})));
}
