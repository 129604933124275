import React, { useRef, useState } from 'react';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { LANGUAGES } from '../constants.js';
import MenuPopover from './MenuPopover.jsx';
import { stores } from '../stores/index.js';

const LanguagePopover = observer((props) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSwitchLang = lang => {
        let updateDB = props.updateDB ?? true;
        stores.userStore.setLanguage(lang, updateDB);
        handleClose();
    };

    return <>
        <IconButton
            ref={anchorRef}
            onClick={handleOpen}
            sx={{
                padding: 0,
                width: '50px',
            }}
            size="large">
            <img src={LANGUAGES.find(l => l.value === stores.userStore.language).icon} alt={LANGUAGES.find(l => l.value === stores.userStore.language).label}
                style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: 'auto', objectFit: 'contain' }}
            />
        </IconButton>


        <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
            <Box sx={{ py: 1 }}>
                {LANGUAGES.map(option => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === LANGUAGES.find(l => l.value === stores.userStore.language).value}
                        onClick={() => handleSwitchLang(option.value)}
                        sx={{ py: 1, px: 2.5 }}
                    >
                        <ListItemIcon>
                            <Box component='img' alt={option.label} src={option.icon} sx={{ width: '35px', marginRight: '10px' }} />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{option.label}</ListItemText>
                    </MenuItem>
                ))}
            </Box>
        </MenuPopover>
    </>;
});

export default LanguagePopover;