import Step from '../Step';

class SitToStandStep extends Step {
    test_type = null;
    interval = null;

    constructor(name, preset) {
        super(name, preset);
    }
    
    initFromData(data) {
        this.test_type = data.test_type;
        this.interval = data.interval;
    }
}

export default SitToStandStep;
