import { styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MHidden } from './@material-extend';
import { Drawer, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import Scrollbar from './Scrollbar.jsx';
import { sidebarConfig } from '../constants.js';
import NavSection from './NavSection.jsx';
import { observer } from 'mobx-react-lite';
import Logo from "./Logo.jsx";

const DRAWER_WIDTH = 280;
const DRAWER_COLLAPSED_WIDTH = 75;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
    }
}));

const Sidebar = observer(() => {
    const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 1200);
    const [contentVisible, setContentVisible] = useState(window.innerWidth >= 1200);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const { pathname } = useLocation();

    useEffect(() => {
        if (sidebarOpen) setSidebarOpen(false);
    }, [pathname]);

    // when the sidebar is open, set contentVisible to true after 150ms, to make the transition smooth
    useEffect(() => {
        // only applicable for small viewports
        if (screenWidth >= 1200) return;

        if (sidebarOpen) {
            const timer = setTimeout(() => setContentVisible(true), 150);
            return () => clearTimeout(timer);
        } else {
            setContentVisible(false);
        }
    }, [sidebarOpen]);

    // update screenWidth state when the window is resized
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // set contentVisible and sidebarOpen to true when the window is resized to a large screen
    // set contentVisible and sidebarOpen to false when the window is resized to a small screen
    useEffect(() => {
        if (screenWidth >= 1200 && (!contentVisible || !sidebarOpen)) {
            setContentVisible(true);
            setSidebarOpen(true);
        }
        if (screenWidth < 1200 && (contentVisible || sidebarOpen)) {
            setContentVisible(false);
            setSidebarOpen(false);
        }
    }, [screenWidth]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: '100%',
                overflow: 'hidden',
                '& .simplebar-content': {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Box sx={{ px: 2.5, py: 3, opacity: contentVisible ? 1 : 0, transition: 'opacity 0.2s ease', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Logo />
            </Box>
            <NavSection navConfig={sidebarConfig} contentVisible={contentVisible} />
        </Scrollbar>
    );

    return (
        <RootStyle>
            <MHidden width='lgUp'>
                <Drawer
                    sx={{
                        width: sidebarOpen ? DRAWER_WIDTH : DRAWER_COLLAPSED_WIDTH,
                    }}
                    open
                    onClose={() => setSidebarOpen(false)}
                    onMouseEnter={() => setSidebarOpen(true)}
                    onMouseLeave={() => setSidebarOpen(false)}
                    hideBackdrop
                    PaperProps={{
                        sx: {
                            width: sidebarOpen ? DRAWER_WIDTH : DRAWER_COLLAPSED_WIDTH,
                            transition: 'width 0.2s ease',
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
            <MHidden width='lgDown'>
                <Drawer
                    open
                    variant='persistent'
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
});

Sidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default Sidebar;
