import React from "react";
import hospitalIcon from '@iconify/icons-fa-solid/hospital-alt';
import { Icon } from '@iconify/react';
import { stores } from "../stores";
import { Navigate, Link } from "react-router-dom";

export const EntityPopover = () => {
    const handleIconClick = () => {
        return <Navigate to={`/entity/${stores.userStore.entityId}`} />;
    };

    return (
        <Link
            to={`/entity/${stores.userStore.entityId}`}
            style={{
                color: 'white',
                fontSize: '40px',
                cursor: 'pointer',
                backgroundColor: 'black',
                borderRadius: '50%',
                width: '25px',
                height: '25px',
                padding: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onClick={handleIconClick}
        >
            <Icon
                icon={hospitalIcon}
            />
        </Link>
    );
};
