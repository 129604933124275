import Step from '../Step';

class AnthropometryStep extends Step {
    anthropometryFields = [];

    constructor(name, preset) {
        super(name, preset);
    }

    initFromData(data) {
        this.anthropometryFields = data.anthropometryFields || data.anthropometry_fields;
    }
}

export default AnthropometryStep;
