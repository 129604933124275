import { makeAutoObservable, action, observable } from "mobx";
import axios from "axios";
import agent from "../agent";
import { API_ROOT } from "../agent";
import { stores } from "./index";

class UserStore {
    // FIXME: add the rest of the fields
    loggedIn = false;
    username = null;
    language = 'en';
    entityId = null;
    entityName = null;
    entityCountry = null;
    entities = []
    token = null;
    inProgress = {
        getEntities: false,
        resetPassword: false,
        login: false,
        getUserByUniqueId: false,
        register: false,
        selectEntity: false
    }
    error = null;
    userByUniqueId = {};
    currentUser = {};
    isManager = false;

    constructor() {
        makeAutoObservable(this, {
            pullEntitiesByUsername: action,
            login: action,
            backendLogin: action,
            setLoggedIn: action,
            setUsername: action,
            setLanguage: action,
            setEntityId: action,
            setEntityName: action,
            setEntityCountry: action,
            setEntities: action,
            setToken: action,
            setError: action,
            reset: action,
            setCurrentUser: action,
            setIsManager: action,
            getRedirectPath: action,
        });
    }

    setLoggedIn(loggedIn) {
        this.loggedIn = loggedIn;
    }

    setUsername(username) {
        this.username = username;
    }

    setLanguage(language, updateDB) {
        this.language = language;
    }

    setEntityId(entityId) {
        this.entityId = entityId;
    }

    setEntityName(entityName) {
        this.entityName = entityName;
    }

    setEntityCountry(entityCountry) {
        this.entityCountry = entityCountry;
    }

    setEntities(entities) {
        this.entities = entities;
    }

    setToken(token) {
        this.token = token;
    }

    setError(error) {
        this.error = error;
    }

    setCurrentUser(currentUser) {
        this.currentUser = currentUser;
    }

    setIsManager(isManager) {
        this.isManager = isManager;
    }

    getRedirectPath() {
        if (this.isManager) return '/professionals';
        else return '/patients';
    }

    reset() {
        this.loggedIn = false;
        this.username = null;
        this.language = 'en';
        this.entityId = null;
        this.entityName = null;
        this.entityCountry = null;
        this.entities = [];
        this.token = null;
        this.error = null;
        this.userByUniqueId = null;
        this.currentUser = {}
        this.isManager = false;
        this.inProgress = {
            getEntities: false,
            resetPassword: false,
            login: false,
            getUserByUniqueId: false,
            register: false,
            selectEntity: false
        };
    }

    /**
     * Called when the user presses the login button.
     * After each login, we replace auth.realm with the user's credentials, so that they can be used to automatically login.
     * 1. Check if the user's credentials exist in auth.realm.
     * 2. If they do, automatically login.
     * 3. If they don't, check if we have connection to the backend.
     * 4. If we do, check if the credentials are valid, and if they are, login.
     * 5. If they aren't, display an error message.
     * @param {Array} values - An array containing the username, password and entity ID.
     */
    async login(values) {
        this.inProgress.login = true;

        const username = values.username;
        //const password = values.password;
        //const entityId = values.entityId;
        //const entityName = this.entities.find(entity => entity.id === entityId)?.name;

        return this.backendLogin(values)
            .then((backendResult) => {
                if (backendResult) {
                    if (backendResult?.token) {
                        let token = backendResult.token;
                        stores.userStore.setToken(token);
                        stores.userStore.setLoggedIn(true);

                        agent.Entity.byUsername(username).then(entities => {
                            stores.userStore.setEntities(entities);
                        })
                    }
                } else {
                    console.error('Invalid credentials');
                }
                return true;
            });
    }

    // offline fetch only returns the last entity that was used to login
    async pullEntitiesByUsername(username) {
        this.inProgress.getEntities = true;

        try {
            let entities = await agent.Entity.byUsername(username);
            if (entities) {
                this.setEntities(entities);
                return entities;
            }
        } catch (error) {
            console.error('Error fetching entities:', error.message);
        } finally {
            this.inProgress.getEntities = false;
        }

        return [];
    }

    async backendLogin(values) {
        // FIXME: login type is hardcoded for now, until the backend is updated
        try {
            let response = await agent.Auth.login(values);
            if (response?.token) {
                return response;
            }
            else return false;
        }
        catch (error) {
            this.setError(error.response.body);
            return null;
        }
        finally {
            this.inProgress.login = false;
        }
    }

    async selectEntity(entity) {
        this.inProgress.selectEntity = true;

        try {
            let response = await agent.Auth.selectEntity({ entity_id: entity.id })
            if (response?.message === 'Entity connected') {
                stores.userStore.setEntityId(entity.id);
                stores.userStore.setEntityName(entity.name);
                stores.userStore.setLanguage(entity.language)

                agent.User.current(2).then(
                    response => {
                        if (response) {
                            this.setUsername(response.username);
                            this.setEntityCountry(response.current_entity.country)
                            this.setCurrentUser(response)
                            this.setIsManager(response?.is_manager)
                            this.inProgress.selectEntity = false;
                        }
                    }
                );

                return true;
            } else return false;
        } catch (error) {
            console.error('Error selecting entity:', error.message);
            return false;
        } finally {
            this.inProgress.selectEntity = false;
        }
    }

    async logout() {
        this.reset();
        return Promise.resolve();
    }

    requestPasswordReset = (username) => {
        this.inProgress.resetPassword = true;
        this.errors = undefined;

        const body = {
            username,
            type: 1, // FIXME: login type is hardcoded for now, until the backend is updated
        };

        return agent.Auth.passwordReset(body)
            .catch(
                action(err => {
                    console.error(err);
                    this.errors = err;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.resetPassword = false;
                })
            );
    };

    pullUserByUniqueId = (uniqueIdCountry, uniqueIdNumber) => {
        this.inProgress.getUserByUniqueId = true;
        return agent.User.byUniqueId(uniqueIdCountry, uniqueIdNumber)
            .then(
                action(user => {
                    this.userByUniqueId = user;
                })
            )
            .catch(
                action(err => {
                    console.error(err);
                })
            )
            .finally(
                action(() => {
                    this.inProgress.getUserByUniqueId = false;
                })
            );
    };

    register = values => {
        this.inProgress.register = true;
        this.errors = undefined;

        const body = JSON.parse(JSON.stringify(values));

        removeEmpty(body.entity);
        body.entity.country = body.entity.country.code;
        body.entity.phone_number = body.entity?.phone_number || undefined;
        body.legal_representative.unique_id_country = body.legal_representative.unique_id_country.code;
        body.legal_representative_username = body.legal_representative.username;
        removeEmpty(body.legal_representative);
        removeEmpty(body.legal_representative?.address);
        body.legal_representative.address = objEmpty(body.legal_representative.address)
            ? undefined
            : body.legal_representative.address;

        if (body.legal_representative_id) {
            if (!body.legal_representative.has_email)
                body.legal_representative_email = body.legal_representative.email;
            body.legal_representative = undefined;
        }
        else delete body['legal_representative_id']

        if (body.operations_manager_id) {
            if (!body.operations_manager.has_email) body.operations_manager_email = body.operations_manager.email;
            body.operations_manager_username = body.operations_manager.username;
            body.operations_manager = undefined;
        } else if (body.operations_manager.operations_manager_is_legal_representative) {
            body.operations_manager_is_legal_representative = true;
            if (!body.operations_manager.has_email) body.operations_manager_email = body.operations_manager.email;
            body.operations_manager = undefined;
        } else {
            delete body['operations_manager_id']
            body.operations_manager.unique_id_country = body.operations_manager.unique_id_country.code;
            body.operations_manager_username = body.operations_manager.username;
            removeEmpty(body.operations_manager);
            removeEmpty(body.operations_manager?.address);
            body.operations_manager.address = objEmpty(body.operations_manager.address)
                ? undefined
                : body.operations_manager.address;
        }

        return agent.Auth.register(body)
            .catch(
                action(err => {
                    this.errors = err.response.body;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.register = false;
                })
            );
    };
}

function removeEmpty(obj) {
    for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj;
}

function objEmpty(obj) {
    return !Object.values(obj).filter(e => typeof e !== 'undefined').length
}

export default UserStore;