import Step from '../Step';


class SurveyStep extends Step {
    surveyOptions = [];

    constructor(name, preset) {
        super(name, preset);
    }

    initFromData(data) {
        this.surveyOptions = data.surveyOptions || data.survey_options;
    }
}

export default SurveyStep;
