import React, { useEffect, useState } from 'react';
import LanguagePopover from './LanguagePopover.jsx';
import { Routes, Route, Navigate } from 'react-router-dom';
import { stores } from '../stores/index.js';
import { observer } from 'mobx-react-lite';
import Sidebar from './Sidebar.jsx';
import { Container } from '@mui/material';
import { EntityPopover } from './EntityPopover.jsx';
import { UserPopover } from './UserPopover.jsx';

/*
    THIS WILL INCLUDE THE LOGO, PROFILE AND SIDEBAR 
    Include the components that'll be rendered in every page.
*/
const Layout = observer(({ routes }) => {
    return (
        <Container sx={{ width: '100vw', height: '100%', display: 'flex', flexDirection: 'row', maxWidth: '100vw !important' }}>
            {!stores.userStore.loggedIn && <Navigate to='/login' />}

            <Sidebar />

            <Container
                sx={{
                    padding: '70px 0px 40px 0px',
                    '@media (max-width: 1199px)': {
                        paddingLeft: '100px'
                    },
                }}
            >
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={<route.component routes={route.routes} />}
                        />
                    ))}
                </Routes>
            </Container>
            <Container
                sx={{
                    position: 'fixed',
                    width: 'fit-content',
                    right: 0,
                    top: 0,
                    padding: '10px 0px',
                    zIndex: 1000,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 'calc(10px + 1vw)',
                }}
            >
                <EntityPopover />
                <LanguagePopover />
                <UserPopover />
            </Container>
        </Container>
    )
});

export default Layout;