import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../agent";
import { formatDateWithMicroseconds } from "../utils";

class PatientStore {
    all = [];
    selectedPatient = null;
    bodyWeightEvolution = [];  
    inProgress = {
        register: false,
        update: false,
        loadWeight: false,  
    };
    errors = undefined;

    constructor() {
        makeObservable(this, {
            all: observable,
            selectedPatient: observable,
            bodyWeightEvolution: observable,
            inProgress: observable,
            errors: observable,
            register: action,
            update: action,
            getPatient: action,
            getAll: action,
            setSelectedPatient: action,
            loadBodyWeightEvolution: action,  
            clearBodyWeightEvolution: action, 
        });
    }

    setSelectedPatient = (patient) => {
        this.selectedPatient = patient;
    };

    register = values => {
        this.inProgress.register = true;

        let person = JSON.parse(JSON.stringify(values));

        let patientData = {
            backend_id: person.backend_id || '',
            unique_id_number: person.unique_id_number || '',
            unique_id_country: person.unique_id_country?.code || '',
            association_date: new Date().toISOString(),
            name: person.name || '',
            email: person.email || null,
            phone_number: person.phone_number || '',
            birthdate: person.birthdate || '',
            gender: person.gender || '',
            ethnicity: '',
            address: {
                street: person.address?.street || '',
                postal_code: person.address?.postal_code || '',
                locality: person.address?.locality || '',
                city: person.address?.city || '',
                state_province_region: person.address?.state_province_region || '',
                country: person.address?.country || '',
            }
        };

        let body = {
            updated: [patientData],
            deleted: [],
            last_sync: new Date().toISOString()
        };

        return agent.Client.add(body)
            .then(() => {
                agent.Client.list().then(
                    action(clients => {
                        this.all = clients;
                    })
                );
            })
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.register = false;
                })
            );
    };

    update = async (values) => {
        this.inProgress.update = true;
        try {
            let body = {
                updated: [],
                deleted: [],
                last_sync: new Date().toISOString()
            };
    
            let patientData = {
                backend_id: values.backend_id || '',  
                updated_at: formatDateWithMicroseconds(),  
                association_date: values.association_date || null,  
                name: values.name || '',  
                email: values.email || '',  
                phone_number: values.phone_number || '',  
                birthdate: values.birthdate || '',  
                gender: values.gender || '',  
                ethnicity: values.ethnicity || '',  
                unique_id_country: values.unique_id_country || '',  
                unique_id_number: values.unique_id_number || '',  
                address: {
                    type: 1,
                    street: values.address?.street || '',
                    postal_code: values.address?.postal_code || '',
                    locality: values.address?.locality || '',
                    city: values.address?.city || '',
                    state_province_region: values.address?.state_province_region || '',
                    country: values.unique_id_country || '',
                }
            };

            body.updated.push(patientData);

            const response = await agent.Client.add(body);
            if (response.updated && response.updated.length > 0 && response.num_updated > 0) {
                const updatedPatient = response.updated[response.updated.length - 1];
                const patientIndex = this.all.findIndex(patient => patient.backend_id === updatedPatient.backend_id);
                this.all[patientIndex] = updatedPatient;

                return { success: true, message: "Patient updated successfully." };
            } else {
                return { success: false, message: "Error updating patient." }; 
            }
        } catch (error) {
            console.error("Error updating patient.", error);
            let errorMessage = "Error updating patient.";
            if (error.response) {
                if (error.response.data && error.response.data.message) {
                    errorMessage = `Backend error: ${error.response.data.message}`;
                } else if (error.response.status) {
                    errorMessage = `Request failed with status code ${error.response.status}`;
                }
            } else if (error.message) {
                errorMessage = error.message;
            }
        return { success: false, message: errorMessage };
        } finally {
            this.inProgress.update = false;
        }
    };

    getPatient = id => {
        return this.all.find(patient => patient.id === id);
    };

    getAll = () => {
        return agent.Client.list().then(
            action(clients => {
                this.all = clients;
            })
        );
    };

    loadBodyWeightEvolution = async () => {
        this.inProgress.loadWeight = true;
        this.errors = undefined;

        try {
            const response = await agent.Client.getBodyWeightEvo(this.selectedPatient?.backend_id);
            runInAction(() => {
                this.bodyWeightEvolution = response;  
            });
        } catch (error) {
            runInAction(() => {
                this.errors = error;
                console.error("Error loading body weight evolution:", error);
            });
        } finally {
            runInAction(() => {
                this.inProgress.loadWeight = false;
            });
        }
    };

    clearBodyWeightEvolution = () => {
        this.bodyWeightEvolution = [];
    };
}

export default PatientStore;
