import { styled } from '@mui/system';

const GlobalStyles = styled('div')({
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box'
  },
  html: {
    width: '100%',
    height: '100%',
    'MsTextSizeAdjust': '100%',
    'WebkitOverflowScrolling': 'touch'
  },
  body: {
    width: '100%',
    height: '100%'
  },
  '#root': {
    width: '100%',
    height: '100%'
  },
  input: {
    '&[type=number]': {
      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
      '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
    }
  },
  textarea: {
    '&::-webkit-input-placeholder': { color: 'disabled' }, // Replace theme.palette.text.disabled with a color value
    '&::-moz-placeholder': { opacity: 1, color: 'disabled' }, // Replace theme.palette.text.disabled with a color value
    '&:-ms-input-placeholder': { color: 'disabled' }, // Replace theme.palette.text.disabled with a color value
    '&::placeholder': { color: 'disabled' } // Replace theme.palette.text.disabled with a color value
  },
  a: { color: 'primary.main' }, // Replace theme.palette.primary.main with a color value
  img: { display: 'block', maxWidth: '100%' }
});

export default GlobalStyles;
