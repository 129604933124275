export default {
    'fold_triceps': 'Trizeps',
    'fold_subscapular': 'Subscapulare',
    'fold_biceps': 'Bizeps',
    'fold_iliacCrest': 'Iliac Crest',
    'fold_supraspinale': 'Supraspinale',
    'fold_abdominal': 'Abdominale',
    'fold_anteriorThigh': 'Oberschenkel',
    'fold_medialcalf': 'Wade',
    'fold_cheek': 'Wange',
    'fold_chin': 'Kinn',
    'fold_chest': 'Brust',
    'fold_umbilical': 'Nabelisch',
    'fold_knee': 'Knie',
    'fold_midAxillary': 'Axillar',
    'fold_lowerBack': 'Unterer Rücken',
    'fold_calf': 'Wade',
    'fold_axillar': 'Axillar',
    'fold_total': 'Gesamt',
    'eq1': 'Durnin&Womesley Trizeps',
    'eq2': 'Durnin&Womesley Subscapulare',
    'eq3': 'Durnin&Womesley T+S',
    'eq4': 'Durnin&Womesley 4 Hautfalten',
    'eq5': 'Jackson&Pollock 3 Hautfalten männlich',
    'eq6': 'Jackson&Pollock 3 Hautfalten(log) männlich',
    'eq7': 'Jackson&Pollock 3 Hautfalten weiblich',
    'eq8': 'Jackson&Pollock 3 Hautfalten(log) weiblich',
    'eq9': 'Jackson&Pollock 7 Hautfalten',
    'eq10': 'Jackson&Pollock 7 Hautfalten(log)',
    'eq11': 'Jackson&Pollock 4 Hautfalten weiblich',
    'eq12': 'Jackson&Pollock 4 Hautfalten(log) weiblich',
    'eq13': 'Jackson&Pollock 1985 3 Hautfalten männlich',
    'eq14': 'Jackson&Pollock 1985 3 Hautfalten weiblich',
    'eq15': 'Peterson',
    'eq16': 'Visser 4 Hautfalten.',
    'eq17': 'Visser T+B',
    'eq18': 'Slaughter T+C',
    'eq19': 'Slaughter T+S männlich',
    'eq20': 'Slaughter T+S weiblich',
    'eq21': 'Carter (ISAK)',
    'eq22': 'Yuhasz',
    'eq23': 'Withers männlich',
    'eq24': 'Withers weiblich',
    'eq25': 'Novel',
    'eq26': 'Guedes (1994) männlich',
    'eq27': 'Guedes (1994) weiblich',
    'eq28': 'Kagawa T+I weiblich',
    'eq29': 'Kagawa T+I+B weiblich',
    'eq30': 'Kagawa A+C männlich',
    'eq31': 'Kagawa 8 Hautfalten männlich',
    'eq32': 'Kagawa T+S männlich',
    'eq33': 'Faulkner',
    'eq34': 'Weltman',
    'eq35': 'Dezenberg',
    'eq36': 'Kannieappan (schwanger)',
    'eq37': 'Huston Presley (schwanger)',
    'eq38': 'Ball männlich',
    'eq39': 'Ball Frau',
    'eq40': 'Petroski männlich',
    'eq41': 'Petroski weiblich',
    'eq42': 'Sloan männlich',
    'eq43': 'Sloan weiblich',
    'eq44': 'Reilly männlich',
    'eq45': 'Evans',
    'eq46': 'Eston',
    'eq47': 'Haisman männlich',
    'eq48': 'Wilmore Behnke männlich',
    'eq49': 'Wilmore Behnke weiblich',
    // bone mass - ISAK consense
    'eq50': 'Rocha (Knochenmasse)',
    'eq51': 'Martin (Knochenmasse)',
    // muscle weight - ISAK consense
    'eq52': 'Matiegka (Muskelgewicht)',
    // skeletal muscle mass - ISAK consense
    'eq53': 'Lee (Muskelmasse)',
    'eq54': 'Poortmans (Muskelmasse)',
    // residual weight - ISAK consense
    'eq55': 'Wurch (Restgewicht)',
    // skinfold sums - ISAK consense
    'eq56': '6 Hautfalten Summe',
    'eq57': '8 Hautfalten Summe',
    'eq58': 'Hautfalten Summe',
    // Somatotype Heath-Carter (2002) - ISAK-Verkäufe
    'eq59': 'Endomorphie',
    'eq60': 'Mesomorphie',
    'eq61': 'Ektomorphie',
    'eq62': 'Umwandlung von 15 Fuß auf 4 m',
    'anthropometry_section_girths': 'Umfänge',
    'anthropometry_section_lengths_and_heights': 'Längen und Höhen',
    'anthropometry_section_breadths_and_depths': 'Breiten und Tiefen',
    'anthropometry_head': 'Kopf',
    'anthropometry_neck': 'Hals',
    'anthropometry_relaxedArm_wrist': 'Arm entspannt',
    'anthropometry_flexedArm': 'Gebeugter Arm',
    'anthropometry_flexedAndTensedArm': 'Arm kontrahiert',
    'anthropometry_forearm': 'Unterarm',
    'anthropometry_wrist': 'Handgelenk',
    'anthropometry_chest': 'Brust',
    'anthropometry_waist_minimal': 'Taille (minimal)',
    'anthropometry_waist_iliac': 'Taille (Iliac)',
    'anthropometry_gluteal': 'Hüfte',
    'anthropometry_thigh_1cm_below_gluteal': 'Oberschenkel (1 cm Glutealfalte)',
    'anthropometry_tight': 'Oberschenkelmitte',
    'anthropometry_calf': 'Wade',
    'anthropometry_ankle': 'Knöchel',
    'anthropometry_acromialeRadiale': 'Acromion-Radius (Länge)',
    'anthropometry_radialeStylion': 'Radius-Stylion (Länge)',
    'anthropometry_midstylion_dactylion': 'Radius-Stylion (Länge)',
    'anthropometry_iliospinaleHeight': 'Iliospinale (Höhe)',
    'anthropometry_trochanterionHeight': 'Trochanter (Höhe)',
    'anthropometry_trochanterionTibialeLaterale': 'Trochanter-Tibia (Länge)',
    'anthropometry_tibialeLateraleHeight': 'Tibia (Höhe)',
    'anthropometry_tibialeMedialeSphyrionTibiale': 'Tibia (Länge)',
    'anthropometry_biacromial': 'Bi-acromial (Breite, transversal)',
    'anthropometry_anterior_posterior_abdominal_depth': 'Abdomen (Tiefe)',
    'anthropometry_biiliocristal': 'Hüftbreite (Transversal)',
    'anthropometry_bimalleolar': 'Bimalleolar',
    'anthropometry_foot_length': 'Fuß (Länge)',
    'anthropometry_transverse_chest': 'Thorax (Breite, transversal)',
    'anthropometry_anterior_posterior_chest_depth': 'Thorax (Tiefe)',
    'anthropometry_bi_epicondylar_humerus': 'Humerus (Breite)',
    'anthropometry_bi_epicondylar_femur': 'Femur (Breite)',
    'anthropometry_bi_estiloideo': 'Bi-Styloid (Breite)',
    'alcohol_consumption_none': 'Keiner',
    'alcohol_consumption_1_portion': '1 Portion',
    'alcohol_consumption_2_portions': '2 Portionen',
    'alcohol_consumption_more_than_2_portions': '> 2 Portionen',
    'expectations_weightLoss': 'Gewichtsverlust',
    'expectations_weightGain': 'Gewichtszunahme',
    'expectations_muscleMassGain': 'Muskelmassengewinn',
    'expectations_other': 'Sonstiges',
    'level_low': 'Niedrig',
    'level_medium': 'Mittel',
    'level_elevate': 'Erhöht',
    'physical_activity_inactive': 'Inaktiv',
    'physical_activity_light_intensity': 'Leichte Intensität (1-3 Tage/Woche)',
    'physical_activity_moderate_intensity': 'Mäßige Intensität (3-5 Tage/Woche)',
    'physical_activity_vigorous_intensity': 'Starke Intensität (6-7 Tage/Woche)',
    'never_used': 'Nie benutzt',
    'have_used': 'Habe benutzt',
    'smoke_none': 'Keiner',
    'smoke_less_10': '<10',
    'smoke_10_to_20': '10-20',
    'smoke_20_to_40': '20-40',
    'smoke_more_than_40': '>40',
    'appetite_inappetent': 'Kein Appetit',
    'appetite_regular': 'Regelmäßig',
    'appetite_moderate': 'Mäßig',
    'appetite_strong': 'Stark',
    'nutrition_food_beans': 'Bohnen',
    'nutrition_food_dairy': 'Molkerei',
    'nutrition_food_drinks': 'Getränke',
    'nutrition_food_eggs': 'Eier',
    'nutrition_food_fish': 'Fisch',
    'nutrition_food_fruits': 'Früchte',
    'nutrition_food_grains/bread/pasta': 'Körner/Brot/Nudeln',
    'nutrition_food_meat': 'Fleisch',
    'nutrition_food_nuts': 'Nüsse',
    'nutrition_food_prepared meals': 'Zubereitete Mahlzeiten',
    'nutrition_food_poultry': 'Geflügel',
    'nutrition_food_seafood': 'Meeresfrüchte',
    'nutrition_food_sweets': 'Süßigkeiten',
    'nutrition_food_vegetables': 'Gemüse',
    'nutrition_food_other': 'Sonstiges',
    'nutrition_allergies_and_intolerances_bee pollen/propolis': 'Bienenpollen/Propolis',
    'nutrition_allergies_and_intolerances_beef chicken': 'Rindfleisch/Huhn',
    'nutrition_allergies_and_intolerances_celery': 'Sellerie',
    'nutrition_allergies_and_intolerances_crustaceans': 'Krustentiere',
    'nutrition_allergies_and_intolerances_eggs': 'Eier',
    'nutrition_allergies_and_intolerances_fish': 'Fisch',
    'nutrition_allergies_and_intolerances_gluten-containing cereals':
        'Glutenhaltige Getreide, nämlich: Weizen, Dinkel, Khorasan-Weizen, Roggen, Gerste, Hafer',
    'nutrition_allergies_and_intolerances_latex (natural rubber)': 'Latex (Naturkautschuk)',
    'nutrition_allergies_and_intolerances_lupine': 'Lupine',
    'nutrition_allergies_and_intolerances_mango': 'Mango',
    'nutrition_allergies_and_intolerances_milk lactose': 'Milch Laktose',
    'nutrition_allergies_and_intolerances_milk': 'Milch',
    'nutrition_allergies_and_intolerances_molluscs': 'Weichtiere',
    'nutrition_allergies_and_intolerances_mustard': 'Senf',
    'nutrition_allergies_and_intolerances_nuts':
        'Nüsse, nämlich Mandeln, Haselnüsse, Walnüsse, Cashews, Pekanüsse, Brasilien Nüsse, Pistazien, Macadamia oder Queensland Nüsse',
    'nutrition_allergies_and_intolerances_peach': 'Pfirsich',
    'nutrition_allergies_and_intolerances_peanuts': 'Erdnüsse',
    'nutrition_allergies_and_intolerances_pork': 'Schweinefleisch',
    'nutrition_allergies_and_intolerances_royal jelly': 'Gelee Royale',
    'nutrition_allergies_and_intolerances_sesame seeds': 'Sesamsamen',
    'nutrition_allergies_and_intolerances_soy': 'Soja',
    'nutrition_allergies_and_intolerances_sulphur dioxide and sulphites': 'Schwefeldioxid und Sulfiten',
    'nutrition_allergies_and_intolerances_tomato': 'Tomate',
    'diet_atkins': 'Atkins',
    'diet_blood_type': 'Blutgruppe',
    'diet_clear_liquid': 'Klare Flüssigkeit',
    'diet_dash': 'DASH',
    'diet_diabetic': 'Diabetiker',
    'diet_diary_free': 'Tagebuchfrei',
    'diet_energy_restricted': 'Energiebeschränkt',
    'diet_full_liquid': 'Vollflüssig',
    'diet_general': 'Allgemein',
    'diet_gluten_free': 'Glutenfrei',
    'diet_glycemic_index': 'Glykämischer-Index',
    'diet_high_calorie': 'Hochkalorisch',
    'diet_high_protein': 'Hoher Proteingehalt',
    'diet_high_fiber': 'Ballaststoffreich',
    'diet_ketogenic': 'Ketogenisch',
    'diet_lactose_free': 'Laktosefrei',
    'diet_low_carb': 'Wenig Kohlehydrate',
    'diet_low_cholesterol': 'Cholesterinarm',
    'diet_low_fat': 'Fettarm',
    'diet_low_potassium': 'Kaliumarm',
    'diet_low_residue': 'Rückstandsarm',
    'diet_low_fiber': 'Ballaststoffarm',
    'diet_low_sodium': 'Natriumarm',
    'diet_low_fodmap': 'Low-fodmap',
    'diet_macrobiotic': 'Makrobiotisch',
    'diet_mediterranean': 'Mediterran',
    'diet_ncs': 'Keine konzentrierten Süßigkeiten (NCS)',
    'diet_paleo': 'Paleo',
    'diet_pureed': 'Püriert',
    'diet_raw_food': 'Rohkost',
    'diet_renal': 'Nieren',
    'diet_soft': 'Weich',
    'diet_vegan': 'Vegan',
    'diet_vegetarian_flexitarian': 'Vegetarisch (Flexitarier)',
    'diet_vegetarian_lacto': 'Vegetarisch (Lacto)',
    'diet_vegetarian_lacto_ovo': 'Vegetarisch (Lacto-Ovo)',
    'diet_vegetarian_ovo': 'Vegetarisch (Ovo)',
    'diet_vegetarian_pescatarian': 'Vegetarier (Pescatarier)',
    'diet_weight_watchers': 'Weight Watchers',
    'diet_whole_30': 'Ganzheitlich 30',
    'bowel_normal': 'Normal',
    'bowel_constipation': 'Verstopfung',
    'bowel_diarrhea': 'Durchfall',
    'bowel_irregular': 'Unregelmäßig',
    'bristol_type1': 'Typ 1: Separate harte Klumpen, wie Nüsse (schwer zu passieren)',
    'bristol_type2': 'Typ 2: Wurstförmig, aber klumpig',
    'bristol_type3': 'Typ 3: Wie eine Wurst, aber mit Rissen auf der Oberfläche',
    'bristol_type4': 'Typ 4: Wie eine Wurst oder Schlange, glatt und weich',
    'bristol_type5': 'Typ 5: Weiche Kleckse mit klaren Schnittkanten (leicht zu passieren)',
    'bristol_type6': 'Typ 6: Flauschige Stücke mit ausgefransten Rändern, ein breiiger Stuhl',
    'bristol_type7': 'Typ 7: Wässrig, keine festen Stücke, völlig flüssig',
    'reasons_weight_loss': 'Gewichtsverlust',
    'reasons_weight_gain': 'Gewichtszunahme',
    'reasons_muscle_mass_gain': 'Zunahme der Muskelmasse',
    'reasons_food_allergies': 'Lebensmittelallergien',
    'reasons_overall': 'Allgemeine Verbesserung der Ernährung',
    'reasons_performance': 'Verbesserung der sportlichen Leistung',
    'reasons_food': 'Ernährungsroutine',
    'reasons_disease': 'Assoziierte Krankheit',
    'reasons_well_being': 'Wohlbefinden',
    'reasons_other': 'Sonstiges',
    'family_itself': 'Selber',
    'family_partner': 'Partner',
    'family_father': 'Vater',
    'family_mother': 'Mutter',
    'family_son': 'Sohn',
    'family_daughter': 'Tochter',
    'family_other': 'Andere',
    'gender_male': 'Männlich',
    'gender_female': 'Weiblich',
    'gender_other': 'Andere',
    '<1 day/week': '<1 Tag/Woche',
    '1-2 days/week': '1-2 Tage/Woche',
    '3-4 days/week': '3-4 Tage/Woche',
    '>4 days/week': '>4 Tage/Woche',
    'walking_pleasure': 'Gehen - zum Vergnügen',
    'walking_work': 'Zu Fuß gehen - zur und von der Arbeit',
    'walking_during_work': 'Zu Fuß gehen - während der Arbeitspause',
    'mowing_the_lawn_ridding': 'Rasen mähen - mit Aufsitzmäher',
    'mowing_the_lawn_walking': 'Den Rasen mähen - mit dem Motormäher',
    'mowing_the_lawn_pushing': 'Den Rasen mähen - schiebender Handmäher',
    'raking': 'Harken',
    'gardening': 'Gartenarbeit',
    'hiking': 'Wandern',
    'jogging': 'Joggen',
    'biking': 'Radfahren',
    'cycling': 'Radsport',
    'dancing': 'Tanzen',
    'aerobic': 'Aerobic',
    'bowling': 'Bowling',
    'golf_power_cart': 'Golf: Fahren mit einem Power Cart',
    'golf_walking_cart': 'Golf: Laufen, Schläger auf Cart ziehen',
    'gold_clubs': 'Golf: zu Fuß gehen und Schläger tragen',
    'single_tennis': 'Einzel-Tennis',
    'doubles_tennis': 'Doppeltes Tennis',
    'racket_ball': 'Schlägerball',
    'calisthenics': 'Gymnastik',
    'swimming': 'Schwimmen',
    'assistance_walking': 'Unterstützung beim Gehen',
    'rise_chair': 'Aufstehen von einem Stuhl',
    'climb_stairs': 'Treppe steigen',
    'falls': 'Stürzt',
    'sarc_f_none': 'Keiner',
    'sarc_f_some': 'Einige',
    'sarc_f_a_lot_or_unable': 'Viel oder unfähig',
    'sarc_f_a_lot_use_aids_or_unable': 'Viel, Hilfsmittel benutzen oder unfähig',
    'sarc_f_a_lot_or_unable_without_help': 'Viel oder unfähig ohne Hilfe',
    'sarc_f_1_3_falls': '1-3 Stürze',
    'sarc_f_4_or_more_falls': '4 oder mehr Stürze',
    'goals': 'Ziele',
    'reasons_appointment': 'Gründe für die Ernennung',
    'expectations': 'Erwartungen',
    'other_information': 'Sonstige Informationen',
    'motivation_title': 'Motivation',
    'personal_social': 'Persönlich und sozial',
    'stress_level': 'Stresslevel',
    'physical_activity': 'Körperliche Aktivität',
    'mobility_issues': 'Mobilitätsprobleme',
    'smoker': 'Raucher',
    'alcohol_consumption': 'Alkoholkonsum',
    'drugs': 'Drogen',
    'sleep_quantity': 'Schlafmenge',
    'sleep_quality': 'Schlafqualität',
    'nutrition': 'Ernährung',
    'daily_meals': 'Tägliche Mahlzeiten',
    'appetite': 'Appetit',
    'diet_type': 'Ernährungstyp',
    'water_intake': 'Wasseraufnahme',
    'bowel_movements': 'Darmbewegungen',
    'bristol_stool_scale': 'Bristol-Stuhl-Skala',
    'intestinal_gases': 'Darmgase',
    'indigestion': 'Verdauungsstörungen',
    'nausea': 'Übelkeit',
    'responsible_for_groceries': 'Verantwortlich für Einkäufe',
    'favorite_food': 'Lieblingsessen',
    'disliked_food': 'Unbeliebtes Essen',
    'allergies_and_intolerances': 'Allergien und Unverträglichkeiten',
    'body_fat': 'Körperfett',
    'muscle_mass': 'Muskelmasse',
    'bone_mass': 'Knochenmasse',
    'residual_mass': 'Restmasse',
    'lean_mass': 'Magermasse',
    'visceral_fat': 'Viszerales Fett',
    'no_intention': 'Keine Absicht',
    'medium_intention': 'Mittlere Absicht',
    'certain': 'sicher',
    'hours_night': 'Stunden/Nacht',
    'entity': 'Entität',
    'manager': 'Manager',
    'professional': 'Fachmann',
    'client': 'Kunde',
    'signin_as': 'Anmelden als',
    'username': 'Benutzername',
    'password': 'Kennwort',
    'login': 'Anmelden',
    'logout': 'Abmelden',
    'profile': 'Profil',
    'settings': 'Einstellungen',
    'no_account': 'Sie haben kein persönliches Konto?',
    'register': 'Registrieren',
    'new_entity': 'Möchten Sie eine Entität erstellen?',
    'select_entity_continue': 'Wählen Sie eine Entität aus, um fortzufahren',
    'no_entities_found': 'Es scheint, dass Sie keine Entitäten haben.',
    'ask_admin_for_entity': 'Bitten Sie Ihren Administrator, Sie zu einer Entität einzuladen oder',
    'softdiet_offline': 'Sie sind offline. Damit diese Seite richtig funktioniert, müssen Sie online sein.',
    'softdiet_error': 'Fehler beim Laden der Seite. Bitte versuchen Sie es erneut oder wenden Sie sich an Ihren Administrator.',
    'register_entity': 'Eine neue Entität erstellen',
    'info_register_entity': 'Muss gesetzlicher Vertreter sein',
    'forgot_password': 'Passwort vergessen?',
    'register_steps1': 'Informationen zur Entität',
    'register_steps2': 'Wählen Sie den gesetzlichen Vertreter des Rechtsträgers',
    'register_steps3': 'Wähle den ersten Fachmann aus',
    'register_steps4': 'Beenden',
    'next': 'Next',
    'back': 'Zurück',
    'yes': 'Ja',
    'no': 'Nein',
    'of': 'von',
    'super_manager': 'Super-Manager',
    'my_entity': 'Meine Entität',
    'external_entities': 'Externe Entitäten',
    'have_access': 'Habe Zugriff',
    'gave_access': 'Zugriff gewährt',
    'managers': 'Manager',
    'manager_profile': 'Manager-Profil',
    'professional_profile': 'Berufsprofil',
    'my_professionals': 'Meine Fachleute',
    'associations': 'Verbände',
    'external_professionals': 'Assoziationen zu externen Stellen',
    'my_clients': 'Meine Kunden',
    'client_profile': 'Kundenprofil',
    'external_clients': 'Externe Kunden',
    'add': 'add',
    'edit': 'Bearbeiten',
    'edit_role': 'Rolle bearbeiten',
    'save': 'Speichern',
    'cancel': 'Abbrechen',
    'delete': 'Löschen',
    'reset': 'Zurücksetzen',
    'disassociate': 'Disassoziieren',
    'search': 'Suchen',
    'label_name': 'Name',
    'label_username': 'Benutzername',
    'label_email': 'E-Mail-Addresse',
    'label_business_username': 'Geschäftlicher Benutzername',
    'label_business_email': 'Geschäfts-E-Mail-Adresse',
    'label_choose_country': 'Wählen Sie ein Land',
    'label_fiscal_id': 'Steuer-ID',
    'label_birthday': 'Geburtstag',
    'label_gender': 'Sex',
    'label_ethnicity': 'Ethnizität',
    'label_street': 'Straße',
    'label_postal_code': 'Postleitzahl',
    'label_city': 'Stadt',
    'label_locality': 'Ort',
    'label_state': 'Bundesland / Provinz / Region',
    'label_country': 'Land',
    'label_mobile': 'Handy, Mobiltelefon',
    'label_phone': 'Telefon',
    'label_language': 'Sprache',
    'label_status': 'Status',
    'label_permissions': 'Erlaubnis',
    'label_role': 'Rolle',
    'yup_short': 'Zu kurz!',
    'yup_long': 'Zu lang!',
    'yup_email_valid': 'Email muss eine gültige Email-Adresse sein',
    'yup_country_required': 'Land ist erforderlich',
    'yup_fiscal_id_required': 'Steuer-ID ist erforderlich',
    'yup_entity_required': 'Entität ist erforderlich',
    'yup_name_required': 'Name ist erforderlich',
    'yup_email_required': 'E-Mail ist erforderlich',
    'yup_username_required': 'Benutzername ist erforderlich',
    'yup_username_invalid': 'Benutzername darf nur Buchstaben, Zahlen, Unterstriche und Punkte enthalten',
    'yup_password_required': 'Passwort ist erforderlich',
    'yup_street_required': 'Straße ist erforderlich',
    'yup_postal_code_required': 'Postleitzahl ist erforderlich',
    'yup_locality_required': 'Ortschaft ist erforderlich',
    'yup_city_required': 'Stadt ist erforderlich',
    'yup_state_required': 'Staat / Provinz / Region ist erforderlich',
    'yup_gender_required': 'Sex ist erforderlich',
    'yup_birthdate_required': 'Geburtstag ist erforderlich',
    'start_new_entity': 'Beginnen Sie mit einer neuen Entität',
    'same_person': 'Dieselbe Person wie der gesetzliche Vertreter',
    'rows_per_page': 'Zeilen pro Seite:',
    'search_manager': 'Suchmanager',
    'search_professional': 'Professionelle Suche',
    'search_entity': 'Entität suchen',
    'search_client': 'Kunde suchen',
    'view_profile': 'Profil ansehen',
    'my_profile': 'Mein Profil',
    'select_entity': 'Entität auswählen',
    'password_reset': 'Passwort zurücksetzen',
    'need_username_entity_reset': 'Ihr Benutzername und die zugehörige Entität sind erforderlich.',
    'password_reset_email_sent_success':
        'Wenn es sich um ein gültiges Konto handelt, muss eine E-Mail an Ihr Konto gesendet wurden, das mit der Entity {{entityName}} mit Benutzername {{username}} verbunden ist.',
    'info_no_entity_for_fiscal_id': 'Keine Entität mit dieser fiskalischen ID gefunden.',
    'info_no_user_for_fiscal_id':
        'Kein {{user_type}} mit dieser steuer-ID gefunden. Bitte füllen Sie die Benutzerdaten {{user_type}} aus.',
    'register_entity_details_info':
        'Die Entität {{entity_name}} wird erstellt, das sich in {{entity_country}} mit der Steueridentifikationsnummer {{entity_fiscalID}} befindet.',
    'register_legal_representative_details_info':
        'Ihr gesetzlicher Vertreter ist {{legal_representative_name}} mit der Taxennummer {{legal_representative_fiscalID}} in {{legal_representative_fiscalCountry}}',
    'register_operations_manager_details_info':
        'Zu dem Sie einem Manager mit dem Namen {{operations_manager_name}} und Steueridentifikationsnummer {{operations_manager_fiscalID}} in {{operations_manager_fiscalCountry}}.',
    'register_same_operations_manager_details_info': 'zu dem er auch als Manager assoziiert war.',
    'error_regist': 'Registrierungsfehler',
    'filter': 'Filter',
    'filter_list': 'Filterliste',
    'search_not_found': 'Nicht gefunden',
    'search_no_results': 'Keine Ergebnisse gefunden für',
    'search_try_again': 'Versuchen Sie, nach Tippfehler zu suchen oder vollständige Wörter zu verwenden.',
    'add_entity': 'Externe Entität hinzufügen',
    'add_user': 'Füge {{user_type}} zu {{entity}}',
    'add_professional_success':
        '{{user_type}} wurde erfolgreich hinzugefügt.\nE-Mail zur Überprüfung wurde an {{user_email}} gesendet.',
    'disassociate_manager': 'Manager dissoziieren?',
    'disassociate_manager_info': 'Möchten Sie diesen Manager sicher, dass Sie diesen Manager trennen möchten?',
    'disassociate_professional': 'Professional dissoziieren?',
    'disassociate_professional_info': 'Sind Sie sicher, dass Sie diesen Fachmann nicht trennen möchten?',
    'user_is_not_confirmed_yet': 'Benutzer ist noch nicht bestätigt',
    'unique_id_already_exists': 'Benutzer existiert bereits mit dem Paar {{unique_id_country}} {{unique_id_number}}',
    'username_already_exists': 'Benutzername existiert bereits.',
    'person_already_a_professional_in_entity': 'Diese Person ist bereits professionell in der angegebenen Entität.',
    'person_already_a_manager_in_entity': 'Diese Person ist bereits ein Manager in der angegebenen Entität.',
    'entity_unique_id_already_exists': 'Eine Entität existiert bereits mit dieser Steuer-ID.',
    'motivation_description':
        '0 bis 2: Vorabsichtsprüfung - weder Absicht noch Übung\nKommen Sie nicht ins Fitnessstudio, wenn die Ärzte sie nicht verpflichten (bald aufgeben)\n\n2 bis 4: Kontemplation - Absicht, aber keine Übung\nEs ist unwahrscheinlich, dass es ein Fitnessstudio kommt, aber wenn sie kommen, geben sie auf\n\n4 bis 6: Vorbereitung - gelegentliche Absicht und Übung\nKommen Sie ins Fitnessstudio, habe aber eine starke Möglichkeit, aufzugeben (los und kommen)\n\n6 bis 8: regelmäßige Übung für kurze Zeit\nMache körperliche Übung, aber nicht regelmäßig\n\n8 bis 10: Wartung - regelmäßige Übung\nNormale Übung',
    'download_report': 'Bericht herunterladen',
    'template_type': 'Wählen Sie die Vorlage aus',
    'complete': 'Vollständig',
    'frailty': 'Gebrechlichkeit',
    'isak': 'Isak',
    'appointments_list': 'Terminliste',
    'report': 'Prüfbericht',
    'body_composition': 'Körperzusammensetzung',
    'last_appointment': 'Letzter Termin',
    'age': 'Zeitalter',
    'handgrip': 'Handgriff',
    'bmi': 'BMI',
    'lipowise': 'Lipowise',
    'gripwise': 'Griffweise',
    'type': 'Typ',
    'value': 'Wert',
    'ref_value': 'Referenzwert',
    'equation': 'Gleichung',
    'other_skinfold_calliper': 'Andere Hautfaltenlehre',
    'bioimpedance': 'Bioimpedanz',
    'anthropometry': 'Anthropometrie',
    'waist_hip_ratio': 'Taille-Hüfte-Verhältnis',
    'weight': 'Körpermasse',
    'height': 'Körperhöhe',
    'sitting_height': 'Sitzhöhe',
    'arm_span': 'Armspannweite',
    'girths': 'Umfänge',
    'lenghts_heights': 'Längen und Höhen',
    'breadths_depths': 'Breiten und Tiefen',
    'somatotype': 'Somatotyp',
    'endomorphy': 'Endomorphie',
    'mesomorphy': 'Mesomorphie',
    'ectomorphy': 'Ektomorphie',
    'skinfold_measurements': 'Hautfalte-Messungen',
    'measure': 'Messen',
    'skinfold_compressibility': 'Hautfalte-Kompressibilität',
    'skinfold': 'Hautförmig',
    'thickness': 'Dicke',
    'time': 'Zeit',
    'geriatric_assessment': 'Geriatrische Beurteilung (physisch)',
    'weigh_loss': 'Gewichtsverlust',
    'energy_expenditure': 'Energieverbrauch',
    'exhaustion': 'Erschöpfung',
    'grip_strenght': 'Griffstärke',
    'walk_time': 'Gehzeit',
    'frailty_score': 'Punktzahl für Gebrechlichkeit',
    'sarcopenia': 'Sarkopenie',
    'muscle_strength': 'Muskelstärke',
    'sit_to_stand': 'Sit to Stand (5x)',
    'muscle_quantity_quality': 'Muskel-Quantität/-Qualität',
    'muscle_quantity_estimation': 'Schätzung der Muskelmasse',
    'calf_girth': 'Wadenumfang',
    'mid_arm_muscle_girth': 'Entspannter Armumfang',
    'physical_performance': 'Physische Leistung',
    'gait_speed_4m': 'Ganggeschwindigkeit  (4 m)',
    'strength': 'Stärke',
    'left_hand': 'Linke Hand',
    'right_hand': 'Rechte Hand',
    'dominant': 'Dominant',
    'non_dominant': 'nicht-dominant',
    'size': 'Größe',
    'measures': 'Maße',
    'max_strength': 'Max. Stärke',
    'avg_strength': 'Durchschnittliche Stärke',
    'strength_profile': 'Kraftprofil',
    'other_dynamometer': 'Andere Dynamometer',
    'surveys': 'Erhebungen',
    'total': 'Gesamt',
    'sarc_f': 'SARC-F',
    'answer': 'Antworten',
    'score': 'Punktzahl',
    'other_tests': 'Andere Tests',
    'last_year': 'letztes Jahr',
    'no_appointments': 'Keine Termine',
    'error_login': 'Sie haben sich nicht korrekt angemeldet oder Ihr Konto ist deaktiviert.',
    'enter_username': 'Unbekannter Benutzername, bitte überprüfen.',
    'identified_risks': 'Identifizierte Risiken',
    'alcohol': 'Alkohol',
    'sedentarism': 'Sesshaftigkeit',
    'overweight': 'Übergewicht',
    'sleep': 'Schlaf',
    'evolution': 'Evolution',
    'maximum': 'Maximal',
    'average': 'Durchschnitt',
    'skinfold_values': 'Hautfaltenwerte',
    'email': 'Email',
    'identification': 'Identifikation',
    'appointment_date': 'Termindatum',
    'right': 'Rechts',
    'left': 'Links',
    'body_comp': 'Analyse der Körperzusammensetzung',
    'frailty_sarcopenia': 'Körperliche Gebrechlichkeit und Sarkopenie',
    'reduced': 'Reduziert',
    'assoziiert': 'Assoziiert',
    'associate_manager': 'Zugeordneter Manager?',
    'associate_manager_info': 'Sind Sie sicher, dass Sie diesen Manager zuordnen wollen?',
    'associate_professional': 'Fachkraft zuordnen?',
    'associate_professional_info': 'Sind Sie sicher, dass Sie diesen Fachmann zuordnen wollen?',
    'note': 'Beobachtungen',
    'regist_ok': 'Registrierung erfolgreich. Prüfen Sie Ihre E-Mail!',
    'invalid_token': 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich ein!',
    'manager_login': 'Entitätsmanagement',
    'professional_login': 'Patientenverwaltung',
    'info_same_user': 'Dies ist gleiche Person wie der gesetzliche Vertreter!',
    'median': 'Median',
    'mst_abbott_title': 'Malnutrition Screening Tool (MST)',
    'full_mna_title': 'Full MNA®',
    'survey_not_complete': 'Nicht alle Fragen werden beantwortet',
    'mst_abbott_S2Score1A': 'MST = 0 oder 1 nicht gefährdet',
    'mst_abbott_S2Score1B':
        'Gut essen mit wenig oder keinen Gewichtsverlust\nWenn der Aufenthaltsdauer 7 Tage überschreitet, dann rescreen, wöchentlich nach Bedarf wiederholt.',
    'mst_abbott_S2Score2A': 'MST = 2 oder mehr gefährdet',
    'mst_abbott_S2Score2B':
        'Essen, der schlecht und / oder letztes Gewichtsverlust ist.\nImplementieren Sie schnell Ernährungseingriffe. Nährstoffberatung innerhalb von 24-72 Stunden lang durchführen, je nach Risiko.',
    'full_mna_score1': 'Screening-Score (Zwischensumme max. 14 Punkte)',
    'full_mna_score1A': '12-14 Punkte: Normaler Ernährungsstatus',
    'full_mna_score1B': '8-11 Punkte: Nach dem Risiko von Unterernährung',
    'full_mna_score1C': '0-7 Punkte: Unterernährt',
    'full_mna_score2': 'Bewertung (max. 16 Punkte)',
    'full_mna_score3': 'Gesamtbewertung (max. 30 Punkte)',
    'full_mna_score4': 'Unterernährungsindikatoranzeige',
    'full_mna_score4A': '24 bis 30 Punkte Normaler Ernährungsstatus',
    'full_mna_score4B': '17 bis 23,5 Punkte gefährdet von Unterernährung',
    'full_mna_score4C': 'Weniger als 17 Punkte unterernährt',
    'same_username': 'Gleicher Benutzername wie der gesetzliche Vertreter',
    'entity_name_already_exists': 'Eine Entität existiert bereits mit diesem Namen.',

    'body_mass': 'Fette Masse',
    'matiegka': 'Matiegka',
    'rocha': 'Rocha',
    'wurch': 'Wurch',
    'more_5%': 'mehr als 5%',
    'less_383kcals': 'weniger als 383 kcal',
    'less_270kcals': 'weniger als 270 kcal',
    'more_2day_week': 'mehr als 2 Tage/Woche',
    'less_29kgf': 'weniger als 29 kgf',
    'less_30kgf': 'weniger als 30 kgf',
    'less_32kgf': 'weniger als 32 kgf',
    'less_17kgf': 'weniger als 17 kgf',
    'less_17.3kgf': 'weniger als 17.3 kgf',
    'less_18kgf': 'weniger als 18 kgf',
    'less_21kgf': 'weniger als 21 kgf',
    'more_6s': 'mehr als 6 s',
    'more_7s': 'mehr als 7 s',
    'frailty_robust': 'Robust',
    'frailty_pre_frail': 'Vorgebrechlich',
    'frailty_frail': 'Gebrechlich',
    'result_must_confirmed_professional': 'Dieses Ergebnis muss von einem Fachmann bestätigt werden',
    'sarc_f_score': 'SARC-F-Ergebnis',
    'find_cases': 'Fälle finden',
    'no_sarcopenia': 'Keine Sarkopenie',
    'predictive_sarcopenia': 'Voraussagende Sarkopenie',
    'more_equal_4': 'mehr oder gleich 4',
    'assess': 'Bewerten',
    'less_27kg': 'weniger als 27 kgf',
    'less_16kg': 'weniger als 16 kgf',
    'more_15s': 'mehr als 15 sekunden',
    'confirm': 'Bestätigen',
    'muscle_estimation': 'Muskelschätzung',
    'less_20kg': 'weniger als 20 kg',
    'less_15kg': 'weniger als 15 kg',
    'less_equal_31cm': 'weniger oder gleich 31 cm',
    'less_equal_26_3cm': 'kleiner oder gleich 26,3 cm',
    'less_equal_20_2cm': 'kleiner oder gleich 20,2 cm',
    'severity': 'Schweregrad',
    'more_5s': 'mehr als 5 sekunden',
    'sarcopenia_score': 'Sarcopenia-Score',
    'presence_sarcopenia': 'Anwesenheits-Sarkopenie',
    'severe_sarcopenia': 'Schwere Sarkopenie',
    'criteria': 'Kriterien',
    'results': 'Ergebnisse',
    'presence': 'Präsenz',
    'parameters': 'Parameter',
    'dashboard': 'Armaturenbrett',
    'anamnese': 'Anamnese',
    'appointments': 'Konsultation',
    'already_have_an_account': 'Schon ein Konto? ',
    'all_steps_completed': 'Alle Schritte abgeschlossen.',
    "measurement_profiles": "Messprofile",
    'my_patients': 'Meine Patienten',
    'search_patient': 'Patient suchen',
    'patient': 'Patient',
    'create_measurement_profile': 'Messprofil erstellen',
    'profile_name': 'Profilname',
    'step_list': 'Schrittliste',
    'add_step': 'Schritt hinzufügen',
    "S-AEROBIC-ENDURANCE": "Aerobe Ausdauer",
    "S-ANTHRO": "Anthropometrie",
    "S-ANTHRO-BREADTHS-DEPTHS": "Breiten und Tiefen",
    "S-ANTHRO-GIRTHS": "Umfänge",
    "S-ANTHRO-LENGTHS-HEIGHTS": "Längen und Höhen",
    "S-BASIC-MEASUREMENTS": "Grundlegende Messungen",
    "S-BIO-IMPEDANCE": "Bioimpedanz",
    "S-BLOOD-PRESSURE": "Blutdruck",
    "S-BLOOD-TESTS": "Bluttests",
    "S-CATEGORY-ANALYTICAL-DATA": "Analytische Daten",
    "S-CATEGORY-ANTHROPOMETRY": "Anthropometrie",
    "S-CATEGORY-ERGONOMIC": "Ergonomisch",
    "S-CATEGORY-FOLLOW-UP-SURVEYS": "Nachfolgebefragungen",
    "S-CATEGORY-FUNCTIONAL": "Funktionell",
    "S-CATEGORY-MORPHOLOGICAL": "Morphologisch",
    "S-CATEGORY-OTHERS": "Andere",
    "S-CATEGORY-PRESETS": "Voreinstellungen",
    "S-GOALS": "Ziele",
    "S-HAND-CONFIGURATION": "Handkonfiguration",
    "S-INITIAL-PRESET-CHECKPOINT": "Anforderungskontrolle",
    "S-LIMBS-BALANCE": "Gliedmaßenbalance",
    "S-MANUAL-HAND-STRENGTH": "Handkraft (Manuell)",
    "S-MANUAL-OTITEST": "Otitest (Manuell)",
    "S-MANUAL-SKIN": "Hautfaltenmessung (Manuell)",
    "S-MANUAL-STRENGTH-ABDUCTION": "Abduktion und Adduktion der Beine (Manuell)",
    "S-MANUAL-TEMP": "Temperatur (Manuell)",
    "S-MEDICAL-AND-CLINICAL": "Medizinisch und Klinisch",
    "S-MOBILITY-AND-STABILITY": "Mobilität und Stabilität",
    "S-NOTES": "Notizen machen",
    "S-NUTRITION": "Ernährung",
    "S-OTITEST": "Otitest (Automatisch)",
    "S-OXIMETER": "Pulsoximeter",
    "S-PERSONAL-AND-SOCIAL": "Persönlich und sozial",
    "S-PHOTOGRAMETRY": "Photogrammetrie",
    "S-PHYSICAL-ACTIVITY-AND-SPORTS": "Körperliche Aktivität und Sport",
    "S-POSTURE": "Körperhaltung",
    "S-QUALITY-PRESET-CHECKPOINT": "Muskelqualitätskontrolle",
    "S-SARCOPENIA-ASSESSMENT": "Sarkopenie",
    "S-SEVERITY-PRESET-CHECKPOINT": "Sarkopenie-Schweregradkontrolle",
    "S-SIT-TO-STAND": "Sitzen-aufstehen-Test",
    "S-SIT-TO-STAND-INTERVAL": "Sitzen-aufstehen-Intervall",
    "S-SKIN": "Hautfaltenmessung (Lipowise)",
    "S-SKINFOLD-ORDER": "Reihenfolge der Hautfaltenmessung",
    "S-STRENGTH": "Kraft (Gripwise)",
    "S-STRENGTH-ABDUCTION": "Abduktion und Adduktion der Beine (Gripwise)",
    "S-STRENGTH-PRESET-CHECKPOINT": "Muskelkraftkontrolle",
    "S-SURVEY": "Befragungen",
    "S-SURVEY-PRESET-CHECKPOINT": "SarcF-Befragung",
    "S-TEMP-OTITEST": "Temperaturmessung (Otitest)",
    "S-UP-AND-GO": "TUG (3+3 m)",
    "S-WALK": "Gehzeit (15 Fuß)",
    "S-WEIGHT": "Gewicht und Größe",
    "S-WEIGHT-PAST": "Früheres Gewicht",
    "choose_at_least_1_step": "Wählen Sie mindestens 1 Schritt",
    "save_profile": "Profil speichern",
    "delete_profile": "Profil löschen",
    "confirm_delete_profile": "Möchten Sie dieses Profil wirklich löschen:",
    'patient_update_error_title': 'Fehler',
    'patient_update_error_description': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
    'patient_update_success_title': 'Erfolg',
    'patient_update_success_description': 'Patient erfolgreich aktualisiert.',
    'axial_measurements': 'Axiale Messungen',
    'bilateral_measurements': 'Bilaterale Messungen',
    "physical_activity": "Körperliche Aktivität",
    "physical_activity_description": "Körperliche Aktivität ist ein wichtiger Bestandteil eines gesunden Lebensstils. Es ist wichtig, dass Sie Ihre körperliche Aktivität aufzeichnen, um eine bessere Analyse Ihrer Gesundheit zu ermöglichen.",
    "physical_activity_field_label": "Körperliche Aktivität",
    "physical_activity_header": "Körperliche Aktivität",
    "physical_activity_inactive": "Inaktiv",
    "physical_activity_label": "Körperliche Aktivität",
    "physical_activity_light": "Leichte Intensität (1-3 Tage/Woche)",
    "physical_activity_moderate": "Moderate Intensität (3-5 Tage/Woche)",
    "physical_activity_vigorous": "Kräftige Intensität (>6 Tage/Woche)",
    "have_not_have_used": "Jemals benutzt",
    "have_not_never_used": "Nie benutzt",
    "hours_per_night": "Stunden pro Nacht",
    "level": "Niveau",
    "level_elevate": "Erhöhen",
    "level_low": "Niedrig",
    "level_medium": "Mittel",
    "gender_female": "Weiblich",
    "gender_label": "Geschlecht",
    "gender_male": "Männlich",
    "gender_other": "Andere",
    "ethnicity_african": "Afrikanisch",
    "ethnicity_asiatic": "Asiatisch",
    "ethnicity_caucasian": "Kaukasisch",
    "ethnicity_hispanic": "Hispanisch",
    "ethnicity_other": "Andere",

};
