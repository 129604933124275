import React from 'react';
import {
    Avatar,
    IconButton,
    Box,
    Typography,
    Divider,
    Button,
    MenuItem,
} from '@mui/material';
import MenuPopover from './MenuPopover.jsx';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { stores } from '../stores';
import DefaultAvatar from './DefaultAvatar.jsx';
import { translate } from '../translations/translate.js';
import personFill from '@iconify/icons-eva/person-fill';
import { observer } from 'mobx-react-lite';
import { Icon } from '@iconify/react';

export const UserPopover = observer(() => {
    let MENU_OPTIONS = [
        {
            label: 'profile',
            icon: personFill,
            linkTo: `../user/${stores.userStore.currentUser?.id}`
        },
    ];

    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    if (!stores.userStore.loggedIn) return null;
    
    const handleOpen = () => {
        setOpen(!open);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleLogout = () => {
        stores.userStore.logout();
    }

    const user = stores.userStore.currentUser;

    return <div>
        <IconButton
            ref={anchorRef}
            onClick={handleOpen}
            sx={{
                padding: 0,
                width: 44,
                height: 44,
                ...(open && {
                    '&:before': {
                        zIndex: 1,
                        content: "''",
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        position: 'absolute',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.3)
                    }
                })
            }}
            size="large">
            {user?.photoURL ?
                <Avatar src={user?.photoURL || ""} alt={user.name} />
                :
                <DefaultAvatar name={user?.name || ''} />
            }
        </IconButton>


        <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{ width: 220 }}
        >
            <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle1" noWrap>
                    {user.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    {user.email}
                </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />

            {MENU_OPTIONS.map((option) => (
                <MenuItem
                    key={option.label}
                    to={option.linkTo}
                    component={RouterLink}
                    onClick={handleClose}
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                >
                    <Box
                        component={Icon}
                        icon={option.icon}
                        sx={{
                            mr: 2,
                            width: 24,
                            height: 24
                        }}
                    />

                    {translate(option.label)}
                </MenuItem>
            ))}

            <Box sx={{ p: 2, pt: 1.5 }}>
                <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
                    {translate('logout')}
                </Button>
            </Box>
        </MenuPopover>
    </div >
});