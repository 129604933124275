import { makeObservable, computed } from 'mobx';
import { translate } from '../../translations/translate.js';


/**
 * This is a base definition of profile's step. A step is uniquely identified by its name. Whenever you need to create a
 * new evaluation step, you should create a new step in the steps folder. That step must inherit from this class.
 */
class Step {
    name = '';
    preset = null;

    constructor(name, preset = null) {
        this.name = name;
        this.preset = preset;

        // Inicializamos as propriedades observáveis e computadas
        makeObservable(this, {
            inReordableListFormat: computed
        });
    }

    initFromData(data) {
        // Implementação específica para inicializar a partir de dados fornecidos
    }

    get inReordableListFormat() {
        return {
            text: translate(this.name),
            isRemovable: true,
            isEditable: true,
            preset: this.preset,
            name: this.name,
            id: this.name
        };
    }

    toJSON() {
        return {
            name: this.name,
            preset: this.preset
        };
    }
}

export default Step;
