import Layout from './components/Layout.jsx';
import loadable from '@loadable/component';
import { Icon } from '@iconify/react';
import React from 'react';
import { translate } from './translations/translate.js';

import briefcaseFill from '@iconify/icons-eva/briefcase-fill';
import appleFill from '@iconify/icons-fluent/food-apple-20-filled.js';
import pencilRulerFill from '@iconify/icons-fa-solid/pencil-ruler.js';
import peopleFill from '@iconify/icons-eva/people-fill.js';
import { mapValuesToLabels } from './utils/formatString.js';
import { stores } from './stores/index.js';


const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const sidebarConfig = [
    {
        title: 'my_professionals',
        path: '/professionals',
        icon: getIcon(briefcaseFill),
        requiresManagerPermission: true,
    },
    {
        title: 'my_patients',
        path: '/patients',
        icon: getIcon(peopleFill),
        requiresManagerPermission: false,
    },
    {
        title: 'measurement_profiles',
        path: '/measurementprofiles',
        icon: getIcon(pencilRulerFill),
        requiresManagerPermission: false,
    },

]

export const routes = [
    {
        path: '/create-entity',
        exact: true,
        component: loadable(() => import('./pages/CreateEntity.jsx')),
        requiresManagerPermission: false,
    },
    {
        path: '/login',
        exact: true,
        component: loadable(() => import('./pages/Login.jsx')),
        requiresManagerPermission: false,
    },
    {
        path: '*',
        component: Layout,
        routes: [
            {
                path: '/main_window',
                exact: true,
                component: loadable(() => {
                    if (stores.userStore.getRedirectPath() === '/professionals') return import('./pages/Professionals.jsx');
                    else return import('./pages/Patients.jsx');
                }),
            },
            {
                path: '/professionals',
                exact: true,
                component: loadable(() => import('./pages/Professionals.jsx')),
                requiresManagerPermission: true,
            },
            {
                path: '/patients',
                exact: true,
                component: loadable(() => import('./pages/Patients.jsx')),
                requiresManagerPermission: false,
            },
            {
                path: '/patients/:id',
                exact: true,
                component: loadable(() => import('./pages/Patient/PatientPage.jsx')),
                requiresManagerPermission: false,
            },
            {
                path: '/entity/:id',
                exact: true,
                component: loadable(() => import('./pages/EntityInfo.jsx')),
                requiresManagerPermission: false // TODO: CLARIFY
            },
            {
                path: '/user/:id',
                exact: true,
                component: loadable(() => import('./pages/UserInfo.jsx')),
                requiresManagerPermission: false,
            },
            {
                path: '/nutrition',
                exact: true,
                component: loadable(() => import('./pages/Nutrition/index.jsx')),
                requiresManagerPermission: false,
            },
            {
                path: '/measurementprofiles',
                exact: true,
                component: loadable(() => import('./pages/MeasurementProfiles/MeasurementProfiles.jsx')),
                requiresManagerPermission: false,
            },
        ]
    }
];

export const LANGUAGES = [
    {
        value: 'en',
        label: 'English',
        icon: '/img/en.png',
    },
    {
        value: 'pt',
        label: 'Português',
        icon: '/img/pt.png',
    },
    {
        value: 'es',
        label: 'Español',
        icon: '/img/es.png',
    },
    {
        value: 'fr',
        label: 'Français',
        icon: '/img/fr.png',
    },
    {
        value: 'de',
        label: 'Deutsch',
        icon: '/img/de.png',
    },
];

export const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
        suggested: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
        suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan, Province of China',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

export const USERNAME_REGEX = /^[\w.]+$/;

export const GENDER = [
    {
        value: 'male',
        label: translate('gender_male'),
    },
    {
        value: 'female',
        label: translate('gender_female'),
    },
    {
        value: 'other',
        label: translate('gender_other'),
    },
];

export const GENDER_LABELS_MAP = mapValuesToLabels(GENDER);

export const ETHNICITY = [
    {
        value: 'caucasian',
        label: translate('ethnicity_caucasian'),
    },
    {
        value: 'african',
        label: translate('ethnicity_african'),
    },
    {
        value: 'asian',
        label: translate('ethnicity_asiatic'),
    },
    {
        value: 'hispanic',
        label: translate('ethnicity_hispanic'),
    },
    {
        value: 'other',
        label: translate('ethnicity_other'),
    },
];

export const ETHNICITY_LABELS_MAP = mapValuesToLabels(ETHNICITY);

export const ETHNICITY_TO_INT = ethnicityStr => (ethnicityStr === 'caucasian' ? 0 : 1);
export const PT_LANGUAGE = ['pt-PT', 'pt-BR'];


export const FOLD_FRONT = 1;
export const FOLD_BACK = 2;

export const FOLDS6 = [0, 1, 4, 5, 6, 7];
export const FOLDS8 = [0, 1, 2, 3, 4, 5, 6, 7];

export const FOLDS = {
    0: {
        id: '0',
        originalName: 'fold_triceps',
        name: 'fold_triceps',
        description: 'fold_triceps_desc',
        bodyX: 830,
        bodyY: 560,
        type: FOLD_BACK,
    },
    1: {
        id: '1',
        originalName: 'fold_subscapular',
        name: 'fold_subscapular',
        description: 'fold_subscapular_desc',
        bodyX: 620,
        bodyY: 450,
        type: FOLD_BACK,
    },
    2: {
        id: '2',
        originalName: 'fold_biceps',
        name: 'fold_biceps',
        description: 'fold_biceps_desc',
        bodyX: 265,
        bodyY: 560,
        type: FOLD_FRONT,
    },
    3: {
        id: '3',
        originalName: 'fold_iliacCrest',
        name: 'fold_iliacCrest',
        description: 'fold_iliacCrest_desc',
        bodyX: 740,
        bodyY: 750,
        type: FOLD_BACK,
    },
    4: {
        id: '4',
        originalName: 'fold_supraspinale',
        name: 'fold_supraspinale' /* Previously known as the Suprailiac site */,
        description: 'fold_supraspinale_desc',
        bodyX: 730,
        bodyY: 900,
        type: FOLD_FRONT,
    },
    5: {
        id: '5',
        originalName: 'fold_abdominal',
        name: 'fold_abdominal',
        description: 'fold_abdominal_desc',
        bodyX: 470,
        bodyY: 700,
        type: FOLD_FRONT,
    },
    6: {
        id: '6',
        originalName: 'fold_anteriorThigh',
        name: 'fold_anteriorThigh',
        description: 'fold_anteriorThigh_desc',
        bodyX: 350,
        bodyY: 1350,
        type: FOLD_FRONT,
    },
    7: {
        id: '7',
        originalName: 'fold_medialcalf',
        name: 'fold_medialcalf',
        description: 'fold_medialcalf_desc',
        bodyX: 740,
        bodyY: 1775,
        type: FOLD_BACK,
    },
    8: {
        id: '8',
        originalName: 'fold_cheek',
        name: 'fold_cheek',
        description: 'fold_cheek_desc',
        bodyX: 390,
        bodyY: 50,
        type: FOLD_FRONT,
    },
    9: {
        id: '9',
        originalName: 'fold_chin',
        name: 'fold_chin',
        description: 'fold_chin_desc',
        bodyX: 530,
        bodyY: 140,
        type: FOLD_FRONT,
    },
    10: {
        id: '10',
        originalName: 'fold_chest',
        name: 'fold_chest',
        description: 'fold_chest_desc',
        bodyX: 420,
        bodyY: 440,
        type: FOLD_FRONT,
    },
    11: {
        id: '11',
        originalName: 'fold_umbilical',
        name: 'fold_umbilical',
        description: 'fold_umbilical_desc',
        bodyX: 550,
        bodyY: 850,
        type: FOLD_FRONT,
    },
    12: {
        id: '12',
        originalName: 'fold_knee',
        name: 'fold_knee',
        description: 'fold_knee_desc',
        bodyX: 720,
        bodyY: 1600,
        type: FOLD_FRONT,
    },
    13: {
        id: '13',
        originalName: 'fold_midAxillary',
        name: 'fold_midAxillary',
        description: 'fold_midAxillary_desc',
        bodyX: 370,
        bodyY: 560,
        type: FOLD_BACK,
    },
    14: {
        id: '14',
        originalName: 'fold_lowerBack',
        name: 'fold_lowerBack',
        description: 'fold_lowerBack_desc',
        bodyX: 450,
        bodyY: 850,
        type: FOLD_BACK,
    },
    15: {
        id: '15',
        originalName: 'fold_calf',
        name: 'fold_calf',
        description: 'fold_calf_desc',
        bodyX: 350,
        bodyY: 2000,
        type: FOLD_BACK,
    },
    16: {
        id: '16',
        originalName: 'fold_axillar',
        name: 'fold_axillar',
        description: 'fold_axillar_desc',
        bodyX: 770,
        bodyY: 440,
        type: FOLD_FRONT,
    },
};

export const RIGHT_HAND = 0;
export const LEFT_HAND = 1;

export const PHYSICAL_ACTIVITY_OPTIONS = [
    {
        id: 1,
        label: 'walking_pleasure',
        display_name: 'walking_pleasure',
        energetic_spent: 3,
    },
    {
        id: 2,
        label: 'walking_work',
        display_name: 'walking_work',
        energetic_spent: 3,
    },
    {
        id: 3,
        label: 'walking_during_work',
        display_name: 'walking_during_work',
        energetic_spent: 3,
    },
    {
        id: 4,
        label: 'mowing_the_lawn_ridding',
        display_name: 'mowing_the_lawn_ridding',
        energetic_spent: 3,
    },
    {
        id: 5,
        label: 'mowing_the_lawn_walking',
        display_name: 'mowing_the_lawn_walking',
        energetic_spent: 5,
    },
    {
        id: 6,
        label: 'mowing_the_lawn_pushing',
        display_name: 'mowing_the_lawn_pushing',
        energetic_spent: 6,
    },
    {
        id: 7,
        label: 'raking',
        display_name: 'raking',
        energetic_spent: 3,
    },
    {
        id: 8,
        label: 'gardening',
        display_name: 'gardening',
        energetic_spent: 5,
    },
    {
        id: 9,
        label: 'hiking',
        display_name: 'hiking',
        energetic_spent: 6,
    },
    {
        id: 10,
        label: 'jogging',
        display_name: 'jogging',
        energetic_spent: 6,
    },
    {
        id: 11,
        label: 'biking',
        display_name: 'biking',
        energetic_spent: 3,
    },
    {
        id: 12,
        label: 'cycling',
        display_name: 'cycling',
        energetic_spent: 3,
    },
    {
        id: 13,
        label: 'dancing',
        display_name: 'dancing',
        energetic_spent: 5,
    },
    {
        id: 14,
        label: 'aerobic',
        display_name: 'aerobic',
        energetic_spent: 6,
    },
    {
        id: 15,
        label: 'bowling',
        display_name: 'bowling',
        energetic_spent: 3,
    },
    {
        id: 16,
        label: 'golf_power_cart',
        display_name: 'golf_power_cart',
        energetic_spent: 3,
    },
    {
        id: 17,
        label: 'golf_walking_cart',
        display_name: 'golf_walking_cart',
        energetic_spent: 3,
    },
    {
        id: 18,
        label: 'gold_clubs',
        display_name: 'gold_clubs',
        energetic_spent: 5,
    },
    {
        id: 19,
        label: 'single_tennis',
        display_name: 'single_tennis',
        energetic_spent: 6,
    },
    {
        id: 20,
        label: 'doubles_tennis',
        display_name: 'doubles_tennis',
        energetic_spent: 6,
    },
    {
        id: 21,
        label: 'racket_ball',
        display_name: 'racket_ball',
        energetic_spent: 6,
    },
    {
        id: 22,
        label: 'calisthenics',
        display_name: 'calisthenics',
        energetic_spent: 3,
    },
    {
        id: 23,
        label: 'swimming',
        display_name: 'swimming',
        energetic_spent: 6,
    },
];

export const SARCOPENIA_OPTIONS_BY_QUESTION_INDEX = [
    // Question 1
    [
        {
            value: 1,
            label: 'sarc_f_none',
            score: 0,
        },
        {
            value: 2,
            label: 'sarc_f_some',
            score: 1,
        },
        {
            value: 3,
            label: 'sarc_f_a_lot_or_unable',
            score: 2,
        },
    ],

    // Question 2
    [
        {
            value: 1,
            label: 'sarc_f_none',
            score: 0,
        },
        {
            value: 2,
            label: 'sarc_f_some',
            score: 1,
        },
        {
            value: 3,
            label: 'sarc_f_a_lot_use_aids_or_unable',
            score: 2,
        },
    ],

    // Question 3
    [
        {
            value: 1,
            label: 'sarc_f_none',
            score: 0,
        },
        {
            value: 2,
            label: 'sarc_f_some',
            score: 1,
        },
        {
            value: 3,
            label: 'sarc_f_a_lot_or_unable_without_help',
            score: 2,
        },
    ],

    // Question 4
    [
        {
            value: 1,
            label: 'sarc_f_none',
            score: 0,
        },
        {
            value: 2,
            label: 'sarc_f_some',
            score: 1,
        },
        {
            value: 3,
            label: 'sarc_f_a_lot_or_unable',
            score: 2,
        },
    ],

    // Question 5
    [
        {
            value: 1,
            label: 'sarc_f_none',
            score: 0,
        },
        {
            value: 2,
            label: 'sarc_f_1_3_falls',
            score: 1,
        },
        {
            value: 3,
            label: 'sarc_f_4_or_more_falls',
            score: 2,
        },
    ],
];

export const EXHAUSTION = [
    {
        value: 0,
        label: '<1 day/week',
    },
    {
        value: 1,
        label: '1-2 days/week',
    },
    {
        value: 2,
        label: '3-4 days/week',
    },
    {
        value: 3,
        label: '>4 days/week',
    },
];

export const REASONS_FOR_APPOINTMENT = [
    {
        'id': 1,
        'label': 'reasons_weight_loss',
        'display_name': 'reasons_weight_loss',
    },
    {
        'id': 2,
        'label': 'reasons_weight_gain',
        'display_name': 'reasons_weight_gain',
    },
    {
        'id': 3,
        'label': 'reasons_muscle_mass_gain',
        'display_name': 'reasons_muscle_mass_gain',
    },
    {
        'id': 4,
        'label': 'reasons_food_allergies',
        'display_name': 'reasons_food_allergies',
    },
    {
        'id': 5,
        'label': 'reasons_overall',
        'display_name': 'reasons_overall',
    },
    {
        'id': 6,
        'label': 'reasons_performance',
        'display_name': 'reasons_performance',
    },
    {
        'id': 7,
        'label': 'reasons_food',
        'display_name': 'reasons_food',
    },
    {
        'id': 8,
        'label': 'reasons_disease',
        'display_name': 'reasons_disease',
    },
    {
        'id': 9,
        'label': 'reasons_well_being',
        'display_name': 'reasons_well_being',
    },
    {
        'id': 10,
        'label': 'reasons_other',
        'display_name': 'reasons_other',
    },
];

export const WATER_INTAKE = [
    {
        label: '<0.5 l',
        value: '0_5l',
    },
    {
        label: '0.5 - 1.5 l',
        value: '0_5_1_5l',
    },
    {
        label: '1.5 - 2.0 l',
        value: '1_5_2_0l',
    },
    {
        label: '2.0 - 2.5 l',
        value: '2_0_2_5l',
    },
    {
        label: '2.5 - 3.0 l',
        value: '2_5_3_0l',
    },
    {
        label: '>3.0 l',
        value: '3_0l',
    },
];

export const WATER_INTAKE_LABELS_MAP = mapValuesToLabels(WATER_INTAKE);


export const BRISTOL = [
    {
        value: 'Type_1_Separate_hard_lumps__like_nuts_(hard_to_pass)',
        label: 'bristol_type1',
    },
    {
        value: 'Type_2_Sausage_shaped__but_lumpy',
        label: 'bristol_type2',
    },
    {
        value: 'Type_3_Like_a_sausage_but_with_cracks_on_its_surface',
        label: 'bristol_type3',
    },
    {
        value: 'Type_4_Like_a_sausage_or_snake__smooth_and_soft',
        label: 'bristol_type4',
    },
    {
        value: 'Type_5_Soft_blobs_with_clear_cut_edges_(easy_to_pass)',
        label: 'bristol_type5',
    },
    {
        value: 'Type_6_Fluffy_pieces_with_ragged_edges__a_mushy_stool',
        label: 'bristol_type6',
    },
    {
        value: 'Type_7_Watery__no_solid_pieces__entirely_liquid',
        label: 'bristol_type7',
    },
];

export const BRISTOL_LABELS_MAP = mapValuesToLabels(BRISTOL);

export const YES_NO = [
    {
        label: 'yes',
        value: true,
    },
    {
        label: 'no',
        value: false,
    },
];

export const FAMILY_MEMBERS = [
    {
        'id': 1,
        'name': 'family_itself',
        'display_name': 'family_itself',
    },
    {
        'id': 2,
        'name': 'family_partner',
        'display_name': 'family_partner',
    },
    {
        'id': 3,
        'name': 'family_father',
        'display_name': 'family_father',
    },
    {
        'id': 4,
        'name': 'family_mother',
        'display_name': 'family_mother',
    },
    {
        'id': 5,
        'name': 'family_son',
        'display_name': 'family_son',
    },
    {
        'id': 6,
        'name': 'family_daughter',
        'display_name': 'family_daughter',
    },
    {
        'id': 7,
        'name': 'family_other',
        'display_name': 'family_other',
    },
];

export const FAMILY_MEMBERS_LABELS_MAP = mapValuesToLabels(FAMILY_MEMBERS, 'name', 'id');

export const NUTRITION_FOODS = [
    'nutrition_food_beans',
    'nutrition_food_dairy',
    'nutrition_food_drinks',
    'nutrition_food_eggs',
    'nutrition_food_fish',
    'nutrition_food_fruits',
    'nutrition_food_grains/bread/pasta',
    'nutrition_food_meat',
    'nutrition_food_nuts',
    'nutrition_food_prepared meals',
    'nutrition_food_poultry',
    'nutrition_food_seafood',
    'nutrition_food_sweets',
    'nutrition_food_vegetables',
    'nutrition_food_other',
];

export const NUTRITION_ALLERGIES_AND_INTOLERANCES = [
    'nutrition_allergies_and_intolerances_bee pollen/propolis',
    'nutrition_allergies_and_intolerances_beef chicken',
    'nutrition_allergies_and_intolerances_celery',
    'nutrition_allergies_and_intolerances_crustaceans',
    'nutrition_allergies_and_intolerances_eggs',
    'nutrition_allergies_and_intolerances_fish',
    'nutrition_allergies_and_intolerances_gluten-containing cereals',
    'nutrition_allergies_and_intolerances_latex (natural rubber)',
    'nutrition_allergies_and_intolerances_lupine',
    'nutrition_allergies_and_intolerances_mango',
    'nutrition_allergies_and_intolerances_milk lactose',
    'nutrition_allergies_and_intolerances_milk',
    'nutrition_allergies_and_intolerances_molluscs',
    'nutrition_allergies_and_intolerances_mustard',
    'nutrition_allergies_and_intolerances_nuts',
    'nutrition_allergies_and_intolerances_peach',
    'nutrition_allergies_and_intolerances_peanuts',
    'nutrition_allergies_and_intolerances_pork',
    'nutrition_allergies_and_intolerances_royal jelly',
    'nutrition_allergies_and_intolerances_sesame seeds',
    'nutrition_allergies_and_intolerances_soy',
    'nutrition_allergies_and_intolerances_sulphur dioxide and sulphites',
    'nutrition_allergies_and_intolerances_tomato',
];
export const EXPECTATIONS = [
    {
        label: 'expectations_weightLoss',
        value: 'weight_loss',
    },
    {
        label: 'expectations_weightGain',
        value: 'weight_gain',
    },
    {
        label: 'expectations_muscleMassGain',
        value: 'muscle_mass_gain',
    },
    {
        label: 'expectations_other',
        value: 'other',
    },
];
export const EXPECTATIONS_LABELS_MAP = mapValuesToLabels(EXPECTATIONS);


export const LEVEL = [
    {
        label: 'level_low',
        value: 'low',
    },
    {
        label: 'level_medium',
        value: 'medium',
    },
    {
        label: 'level_elevate',
        value: 'elevate',
    },
];

export const LEVEL_LABELS_MAP = mapValuesToLabels(LEVEL);

export const PHYSICAL_ACTIVITY = [
    {
        label: 'physical_activity_inactive',
        value: 'inactive',
    },
    {
        label: 'physical_activity_light',
        value: 'light_intensity',
    },
    {
        label: 'physical_activity_moderate',
        value: 'moderate_intensity',
    },
    {
        label: 'physical_activity_vigorous',
        value: 'vigorous_intensity',
    },
];

export const PHYSICAL_ACTIVITY_LABELS_MAP = mapValuesToLabels(PHYSICAL_ACTIVITY);

export const SMOKE_DAILY = [
    {
        label: 'smoke_none',
        value: 'none',
    },
    {
        label: 'smoke_less_10',
        value: 'less_10',
    },
    {
        label: 'smoke_10_to_20',
        value: '10_to_20',
    },
    {
        label: 'smoke_20_to_40',
        value: '20_to_40',
    },
    {
        label: 'smoke_more_than_40',
        value: 'more_than_40',
    },
];

export const SMOKE_DAILY_LABELS_MAP = mapValuesToLabels(SMOKE_DAILY);

export const ALCOHOL_CONSUMPTION = [
    {
        label: 'alcohol_consumption_none',
        value: 'none',
    },
    {
        label: 'alcohol_consumption_1_portion',
        value: '1_portion',
    },
    {
        label: 'alcohol_consumption_2_portions',
        value: '2_portions',
    },
    {
        label: 'alcohol_consumption_more_than_2_portions',
        value: 'more_than_2_portions',
    },
];

export const ALCOHOL_CONSUMPTION_LABELS_MAP = mapValuesToLabels(ALCOHOL_CONSUMPTION);

export const HAVE_NOT = [
    {
        label: 'have_not_never_used',
        value: 'never_used',
    },
    {
        label: 'have_not_have_used',
        value: 'have_used',
    },
];

export const HAVE_NOT_LABELS_MAP = mapValuesToLabels(HAVE_NOT);

export const SLEEP_QTY = [
    {
        label: '<4',
        value: '<4',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '>10',
        value: '>10',
    },
];

export const SLEEP_QTY_LABELS_MAP = mapValuesToLabels(SLEEP_QTY);

export const APPETITE = [
    {
        label: 'appetite_inappetent',
        value: 'inappetent',
    },
    {
        label: 'appetite_regular',
        value: 'regular',
    },
    {
        label: 'appetite_moderate',
        value: 'moderate',
    },
    {
        label: 'appetite_strong',
        value: 'strong',
    },
];

export const APPETITE_LABELS_MAP = mapValuesToLabels(APPETITE);

export const DIET = [
    { value: 'atkins' },
    { value: 'blood_type' },
    { value: 'clear_liquid' },
    { value: 'dash' },
    { value: 'diabetic' },
    { value: 'diary_free' },
    { value: 'energy_restricted' },
    { value: 'full_liquid' },
    { value: 'general' },
    { value: 'gluten_free' },
    { value: 'glycemic_index' },
    { value: 'high_calorie' },
    { value: 'high_protein' },
    { value: 'high_fiber' },
    { value: 'ketogenic' },
    { value: 'lactose_free' },
    { value: 'low_carb' },
    { value: 'low_cholesterol' },
    { value: 'low_fat' },
    { value: 'low_potassium' },
    { value: 'low_residue' },
    { value: 'low_fiber' },
    { value: 'low_sodium' },
    { value: 'low_fodmap' },
    { value: 'macrobiotic' },
    { value: 'mediterranean' },
    { value: 'ncs' },
    { value: 'paleo' },
    { value: 'pureed' },
    { value: 'raw_food' },
    { value: 'renal' },
    { value: 'soft' },
    { value: 'vegan' },
    { value: 'vegetarian_flexitarian' },
    { value: 'vegetarian_lacto' },
    { value: 'vegetarian_lacto_ovo' },
    { value: 'vegetarian_ovo' },
    { value: 'vegetarian_pescatarian' },
    { value: 'weight_watchers' },
    { value: 'whole_30' },
];

// Add the name in the format diet_<value> (e.g. diet_raw_food)
for (const f in DIET) DIET[f].label = 'diet_' + DIET[f].value;

export const DIET_LABELS_MAP = mapValuesToLabels(DIET);

export const BOWEL = [
    {
        label: 'bowel_normal',
        value: 'normal',
    },
    {
        label: 'bowel_constipation',
        value: 'constipation',
    },
    {
        label: 'bowel_diarrhea',
        value: 'diarrhea',
    },
    {
        label: 'bowel_irregular',
        value: 'irregular',
    },
];

export const BOWEL_LABELS_MAP = mapValuesToLabels(BOWEL);


export const INTEGER_RANGE_AS_INT = (min, max) => {
    let array = [];

    for (let i = min; i <= max; i++) {
        array.push({
            label: String(i),
            value: i,
        });
    }

    return array;
};

export const MOTIVATION = INTEGER_RANGE_AS_INT(1, 10);

export const MOTIVATION_LABELS_MAP = mapValuesToLabels(MOTIVATION);



export const NUTRITION_FOODS_MULTISELECT_OPTIONS = NUTRITION_FOODS.map((i, index) => ({
    id: index + 1,
    name: i,
}));



export const NUTRITION_ALLERGIES_AND_INTOLERANCES_MULTISELECT_OPTIONS = NUTRITION_ALLERGIES_AND_INTOLERANCES.map(
    (i, index) => ({
        id: index + 1,
        name: i,
    })
);


export const YES_NO_LABELS_MAP = mapValuesToLabels(YES_NO);