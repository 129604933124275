export default {
    'fold_triceps': 'Tríceps',
    'fold_subscapular': 'Subscapular',
    'fold_biceps': 'Bíceps',
    'fold_iliacCrest': 'Crista Ilíaca',
    'fold_supraspinale': 'Supraespinal',
    'fold_abdominal': 'Abdominal',
    'fold_anteriorThigh': 'Crural/Coxa',
    'fold_medialcalf': 'Geminal',
    'fold_cheek': 'Bochecha',
    'fold_chin': 'Queixo',
    'fold_chest': 'Peitoral',
    'fold_umbilical': 'Umbilical',
    'fold_knee': 'Joelho',
    'fold_midAxillary': 'Axilar',
    'fold_lowerBack': 'Lombar',
    'fold_calf': 'Geminal',
    'fold_axillar': 'Axilar',
    'fold_total': 'Total',
    'eq1': 'Durnin&Womesley Tríceps',
    'eq2': 'Durnin&Womesley Subscapular',
    'eq3': 'Durnin&Womesley T+S',
    'eq4': 'Durnin&Womesley 4 Pregas',
    'eq5': 'Jackson&Pollock 3 Pregas Masculino',
    'eq6': 'Jackson&Pollock 3 Pregas(log)Masculino',
    'eq7': 'Jackson&Pollock 3 Pregas Feminino',
    'eq8': 'Jackson&Pollock 3 Pregas(log)Feminino',
    'eq9': 'Jackson&Pollock 7 Pregas',
    'eq10': 'Jackson&Pollock 7 Pregas(log)',
    'eq11': 'Jackson&Pollock 4 Pregas Feminino',
    'eq12': 'Jackson&Pollock 4 Pregas(log)Feminino',
    'eq13': 'Jackson&Pollock 1985 3 Pregas Masculino',
    'eq14': 'Jackson&Pollock 1985 3 Pregas Feminino',
    'eq15': 'Peterson',
    'eq16': 'Visser 4 Pregas',
    'eq17': 'Visser T+B',
    'eq18': 'Slaughter T+G',
    'eq19': 'Slaughter T+S Masculino',
    'eq20': 'Slaughter T+S Feminino',
    'eq21': 'Carter (ISAK)',
    'eq22': 'Yuhasz',
    'eq23': 'Withers Masculino',
    'eq24': 'Withers Feminino',
    'eq25': 'Novel',
    'eq26': 'Guedes (1994) Masculino',
    'eq27': 'Guedes (1994) Feminino',
    'eq28': 'Kagawa T+I Feminino',
    'eq29': 'Kagawa T+I+B Feminino',
    'eq30': 'Kagawa A+G Masculino',
    'eq31': 'Kagawa 8 Skinfolds Masculino',
    'eq32': 'Kagawa T+S Masculino',
    'eq33': 'Faulkner',
    'eq34': 'Weltman',
    'eq35': 'Dezenberg',
    'eq36': 'Kannieappan (Grávidas)',
    'eq37': 'Huston Presley (Grávidas)',
    'eq38': 'Ball Masculino',
    'eq39': 'Ball Feminino',
    'eq40': 'Petroski Masculino',
    'eq41': 'Petroski Feminino',
    'eq42': 'Sloan Masculino',
    'eq43': 'Sloan Feminino',
    'eq44': 'Reilly Masculino',
    'eq45': 'Evans',
    'eq46': 'Eston',
    'eq47': 'Haisman Masculino',
    'eq48': 'Wilmore Behnke Masculino',
    'eq49': 'Wilmore Behnke Feminino',
    // bone mass - ISAK consense
    'eq50': 'Rocha (Massa Óssea)',
    'eq51': 'Martin (Massa Óssea)',
    // muscle weight - ISAK consense
    'eq52': 'Matiegka (Peso Muscular)',
    // skeletal muscle mass - ISAK consense
    'eq53': 'Lee (Massa Muscular)',
    'eq54': 'Poortmans (Massa Muscular)',
    // residual weight - ISAK consense
    'eq55': 'Wurch (Peso Residual)',
    // skinfold sums - ISAK consense
    'eq56': 'Soma de 6 Pregas',
    'eq57': 'Soma de 8 Pregas',
    'eq58': 'Soma das Pregas',
    // somatotype HEATH-CARTER (2002) - ISAK consense
    'eq59': 'Endomorfo',
    'eq60': 'Mesomorfo',
    'eq61': 'Ectomorfo',
    'eq62': 'Conversão de 15 feet para 4 m',
    'anthropometry_section_girths': 'Perímetros',
    'anthropometry_section_lengths_and_heights': 'Comprimentos & Alturas',
    'anthropometry_section_breadths_and_depths': 'Diâmetros & Profundidades',
    'anthropometry_head': 'Cabeça',
    'anthropometry_neck': 'Pescoço',
    'anthropometry_relaxedArm_wrist': 'Braço relaxado',
    'anthropometry_flexedArm': 'Braço flectido',
    'anthropometry_flexedAndTensedArm': 'Braço flectido e contraído',
    'anthropometry_forearm': 'Antebraço',
    'anthropometry_wrist': 'Pulso',
    'anthropometry_chest': 'Tórax',
    'anthropometry_waist_minimal': 'Cintura (mínima)',
    'anthropometry_waist_iliac': 'Cintura (ilíaca)',
    'anthropometry_gluteal': 'Anca/Glúteo',
    'anthropometry_thigh_1cm_below_gluteal': 'Coxa 1 cm abaixo da anca',
    'anthropometry_tight': 'Coxa/Crural',
    'anthropometry_calf': 'Gémeo',
    'anthropometry_ankle': 'Tornozelo',
    'anthropometry_acromialeRadiale': 'Braço (acromial-radial)',
    'anthropometry_radialeStylion': 'Antebraço (radial-stylion)',
    'anthropometry_midstylion_dactylion': 'Midstylion-dactylion',
    'anthropometry_iliospinaleHeight': 'Altura Ilioespinal',
    'anthropometry_trochanterionHeight': 'Altura trocantérica',
    'anthropometry_trochanterionTibialeLaterale': 'Coxa (trocantérico-tibial)',
    'anthropometry_tibialeLateraleHeight': 'Altura tibial lateral',
    'anthropometry_tibialeMedialeSphyrionTibiale': 'Tibial mediano-Sphyrion tibial',
    'anthropometry_biacromial': 'Biacromial',
    'anthropometry_anterior_posterior_abdominal_depth': 'Profundidade antero-posterior do abdómen',
    'anthropometry_biiliocristal': 'Bi-ilíaco',
    'anthropometry_bimalleolar': 'Bimaleolar',
    'anthropometry_foot_length': 'Comprimento dos pés',
    'anthropometry_transverse_chest': 'Tórax transverso',
    'anthropometry_anterior_posterior_chest_depth': 'Profundidade anterio-posterior do tórax',
    'anthropometry_bi_epicondylar_humerus': 'Bicôndilo-umeral',
    'anthropometry_bi_estiloideo': 'Biestilóideo',
    'anthropometry_bi_epicondylar_femur': 'Bicôndilo-femoral',
    'alcohol_consumption_none': 'Nenhum',
    'alcohol_consumption_1_portion': '1 porção',
    'alcohol_consumption_2_portions': '2 porções',
    'alcohol_consumption_more_than_2_portions': '> 2 porções',
    'expectations_weightLoss': 'Perda de Peso',
    'expectations_weightGain': 'Ganho de Peso',
    'expectations_muscleMassGain': 'Ganho de Massa Muscular',
    'expectations_other': 'Outro',
    'level_low': 'Baixo',
    'level_medium': 'Médio',
    'level_elevate': 'Elevado',
    'physical_activity_inactive': 'Sedentário',
    'physical_activity_light_intensity': 'Intensidade Baixa (1-3 dias/semana)',
    'physical_activity_moderate_intensity': 'Intensidade Moderada (3-5 dias/semana)',
    'physical_activity_vigorous_intensity': 'Intensidade Vigorosa (6-7 dias/semana)',
    'never_used': 'Nunca utilizou',
    'have_used': 'Já utilizou',
    'smoke_none': 'Nunca',
    'smoke_less_10': '<10',
    'smoke_10_to_20': '10-20',
    'smoke_20_to_40': '20-40',
    'smoke_more_than_40': '>40',
    'appetite_inappetent': 'Sem Apetite',
    'appetite_regular': 'Regular',
    'appetite_moderate': 'Moderado',
    'appetite_strong': 'Elevado',
    'nutrition_food_beans': 'Feijão',
    'nutrition_food_dairy': 'Laticínios',
    'nutrition_food_drinks': 'Bebidas',
    'nutrition_food_eggs': 'Ovos',
    'nutrition_food_fish': 'Peixe',
    'nutrition_food_fruits': 'Frutas',
    'nutrition_food_grains/bread/pasta': 'Grãos/Pão/Massa',
    'nutrition_food_meat': 'Carne',
    'nutrition_food_nuts': 'Frutos Oleaginosos',
    'nutrition_food_prepared meals': 'Refeições pré-preparadas',
    'nutrition_food_poultry': 'Aves',
    'nutrition_food_seafood': 'Mariscos',
    'nutrition_food_sweets': 'Doces',
    'nutrition_food_vegetables': 'Hortícolas',
    'nutrition_food_other': 'Outro',
    'nutrition_allergies_and_intolerances_bee pollen/propolis': 'pólen de abelha / própolis',
    'nutrition_allergies_and_intolerances_beef chicken': 'Carne de Frango',
    'nutrition_allergies_and_intolerances_celery': 'Aipo',
    'nutrition_allergies_and_intolerances_crustaceans': 'Crustáceos',
    'nutrition_allergies_and_intolerances_eggs': 'Ovos',
    'nutrition_allergies_and_intolerances_fish': 'Peixe',
    'nutrition_allergies_and_intolerances_gluten-containing cereals':
        'Cereais que contêm glúten, Nomeadamente: Trigo, Espelta, Trigo Khorasan (kamut), Centeio, Cevada, Aveia',
    'nutrition_allergies_and_intolerances_latex (natural rubber)': 'Látex (borracha natural)',
    'nutrition_allergies_and_intolerances_lupine': 'Tremoço',
    'nutrition_allergies_and_intolerances_mango': 'Manga',
    'nutrition_allergies_and_intolerances_milk lactose': 'Lactose',
    'nutrition_allergies_and_intolerances_milk': 'Leite',
    'nutrition_allergies_and_intolerances_molluscs': 'Moluscos',
    'nutrition_allergies_and_intolerances_mustard': 'Mostarda',
    'nutrition_allergies_and_intolerances_nuts':
        'Frutos de casca rija, nomeadamente: amêndoas, avelãs, nozes, castanhas de caju, nozes pecan, castanhas do Brasil, pistáchios, nozes de macadâmia ou do Queensland.',
    'nutrition_allergies_and_intolerances_peach': 'Pêssego',
    'nutrition_allergies_and_intolerances_peanuts': 'Amendoim',
    'nutrition_allergies_and_intolerances_pork': 'Carne de porco',
    'nutrition_allergies_and_intolerances_royal jelly': 'Geleia real',
    'nutrition_allergies_and_intolerances_sesame seeds': 'Sementes de Sésamo',
    'nutrition_allergies_and_intolerances_soy': 'Soja',
    'nutrition_allergies_and_intolerances_sulphur dioxide and sulphites': 'Dióxido de enxofre e sulfitos',
    'nutrition_allergies_and_intolerances_tomato': 'Tomate',
    'diet_atkins': 'Atkins',
    'diet_blood_type': 'Tipo sanguíneo',
    'diet_clear_liquid': 'Líquida clara',
    'diet_dash': 'DASH',
    'diet_diabetic': 'Para diabético',
    'diet_diary_free': 'Isenta de leite e derivados',
    'diet_energy_restricted': 'Hipoenergética',
    'diet_full_liquid': 'Dieta líquida',
    'diet_general': 'Geral',
    'diet_gluten_free': 'Isenta de glúten',
    'diet_glycemic_index': 'Índice glicémico',
    'diet_high_calorie': 'Hiperenergética',
    'diet_high_protein': 'Hiperproteica',
    'diet_high_fiber': 'Alto teor de fibras',
    'diet_ketogenic': 'Cetogénica',
    'diet_lactose_free': 'Restrita em lactose',
    'diet_low_carb': 'Restrita em hidratos de carbono',
    'diet_low_cholesterol': 'Restrita em colesterol',
    'diet_low_fat': 'Restrita em gorduras',
    'diet_low_potassium': 'Restrita em potássio',
    'diet_low_residue': 'Restrita em resíduos',
    'diet_low_fiber': 'Restrita em fibras',
    'diet_low_sodium': 'Restrita em sódio',
    'diet_low_fodmap': 'Restrita em FODMAP',
    'diet_macrobiotic': 'Macrobiótica',
    'diet_mediterranean': 'Mediterrânea',
    'diet_ncs': 'Sem açúcares adicionados',
    'diet_paleo': 'Paleolítica',
    'diet_pureed': 'Cremosa',
    'diet_raw_food': 'Crudívora',
    'diet_renal': 'Renal',
    'diet_soft': 'Mole',
    'diet_vegan': 'Vegana',
    'diet_vegetarian_flexitarian': 'Vegetariana (flexitariana)',
    'diet_vegetarian_lacto': 'Vegetariana (lacto)',
    'diet_vegetarian_lacto_ovo': 'Vegetariana (ovo-lacto)',
    'diet_vegetarian_ovo': 'Vegetariana (ovo)',
    'diet_vegetarian_pescatarian': 'Vegetariana (pescetariana)',
    'diet_weight_watchers': 'Weight Watchers',
    'diet_whole_30': 'Whole 30',
    'bowel_normal': 'Normal',
    'bowel_constipation': 'Obstipação',
    'bowel_diarrhea': 'Diarreia',
    'bowel_irregular': 'Irregular',
    'bristol_type1': 'Tipo 1: Fezes em formato de cíbalos. Difíceis de eliminar. Obstipação intestinal',
    'bristol_type2': 'Tipo 2: Fezes volumosas com rachadura. Obstipação intestinal',
    'bristol_type3': 'Tipo 3: Fezes formadas, volumosas, características intermediárias',
    'bristol_type4': 'Tipo 4: Fezes lisas e macias. Fáceis de eliminar',
    'bristol_type5': 'Tipo 5: Fezes em pedaços, mas macias e fáceis de eliminar',
    'bristol_type6': 'Tipo 6: Fezes bem amolecidas e semi-pastosas',
    'bristol_type7': 'Tipo 7: Fezes aquosas, não há partes sólidas. Resultante de trânsito intestinal rápido',
    'reasons_weight_loss': 'Perder peso',
    'reasons_weight_gain': 'Ganhar peso',
    'reasons_muscle_mass_gain': 'Ganhar massa muscular',
    'reasons_food_allergies': 'Alergias alimentares',
    'reasons_overall': 'Melhorar a alimentação',
    'reasons_performance': 'Melhorar o desempenho desportivo',
    'reasons_food': 'Rotina de alimentação',
    'reasons_disease': 'Doença associada',
    'reasons_well_being': 'Bem-estar',
    'reasons_other': 'Outra',
    'family_itself': 'Próprio',
    'family_partner': 'Parceiro',
    'family_father': 'Pai',
    'family_mother': 'Mãe',
    'family_son': 'Filho',
    'family_daughter': 'Filha',
    'family_other': 'Outro',
    'gender_male': 'Masculino',
    'gender_female': 'Feminino',
    'gender_other': 'Outro',
    '<1 day/week': '<1 dia/semana',
    '1-2 days/week': '1-2 dias/semana',
    '3-4 days/week': '3-4 dias/semana',
    '>4 days/week': '>4 dias/semana',
    'walking_pleasure': 'Andar - por prazer',
    'walking_work': 'Andar – de e do trabalho',
    'walking_during_work': 'Andar – durante a pausa do trabalho',
    'mowing_the_lawn_ridding': 'Cortar a relva - com cortador de relva',
    'mowing_the_lawn_walking': 'Cortar a relva – andando atrás do cortador de relva',
    'mowing_the_lawn_pushing': 'Cortar a relva – empurrar o cortador de relva manual',
    'raking': 'Juntar com ancinho',
    'gardening': 'Jardinagem',
    'hiking': 'Caminhar',
    'jogging': 'Correr',
    'biking': 'Andar de Bicicleta',
    'cycling': 'Ciclismo',
    'dancing': 'Dançar',
    'aerobic': 'Aeróbica',
    'bowling': 'Bólingue',
    'golf_power_cart': 'Golfe: usando carro elétrico',
    'golf_walking_cart': 'Golfe: andando a puxar carrinho de tacos',
    'gold_clubs': 'Golfe: andando a carregar os tacos',
    'single_tennis': 'Ténis individual',
    'doubles_tennis': 'Ténis de pares',
    'racket_ball': 'Raquetebol',
    'calisthenics': 'Calistenia',
    'swimming': 'Nadar',
    'assistance_walking': 'Assistência para Caminhar',
    'rise_chair': 'Levantar da Cadeira',
    'climb_stairs': 'Subir Escadas',
    'falls': 'Quedas',
    'sarc_f_none': 'Nenhuma',
    'sarc_f_some': 'Alguma',
    'sarc_f_a_lot_or_unable': 'Muita ou incapaz',
    'sarc_f_a_lot_use_aids_or_unable': 'Muita, com auxílio, ou incapaz',
    'sarc_f_a_lot_or_unable_without_help': 'Muita, ou incapaz sem ajuda',
    'sarc_f_1_3_falls': '1-3 quedas',
    'sarc_f_4_or_more_falls': '4 ou mais quedas',
    'goals': 'Objetivos',
    'reasons_appointment': 'Razões da Consulta',
    'expectations': 'Expectativas',
    'other_information': 'Outras informações',
    'motivation_title': 'Motivação',
    'personal_social': 'Pessoal e Social',
    'stress_level': 'Nível de Stresse',
    'physical_activity': 'Atividade física',
    'mobility_issues': 'Problemas de mobilidade',
    'smoker': 'Fumador',
    'alcohol_consumption': 'Consumo de Álcool',
    'drugs': 'Drogas',
    'sleep_quantity': 'Quantidade de Sono',
    'sleep_quality': 'Qualidade do sono',
    'nutrition': 'Nutrição',
    'daily_meals': 'Refeições diárias',
    'appetite': 'Apetite',
    'diet_type': 'Tipo de Dieta',
    'water_intake': 'Ingestão de Água',
    'bowel_movements': 'Movimentos Intestinais',
    'bristol_stool_scale': 'Escala de Bristol',
    'intestinal_gases': 'Gases Intestinais',
    'indigestion': 'Indigestão/Refluxo',
    'nausea': 'Náusea/Azia',
    'responsible_for_groceries': 'Responsável pela Mercearia',
    'favorite_food': 'Comida Favorita',
    'disliked_food': 'Comida Preterida',
    'allergies_and_intolerances': 'Alergias e Intolerâncias',
    'body_fat': 'Gordura Corporal',
    'muscle_mass': 'Massa Muscular',
    'bone_mass': 'Massa Óssea',
    'residual_mass': 'Massa Residual',
    'lean_mass': 'Massa Magra',
    'visceral_fat': 'Gordura Visceral',
    'no_intention': 'Sem Intenção',
    'medium_intention': 'Intenção Média',
    'certain': 'Certeza',
    'hours_night': 'horas/noite',
    'entity': 'Entidade',
    'manager': 'Gestor',
    'professional': 'Profissional',
    'client': 'Cliente',
    'signin_as': 'Entrar como',
    'username': 'Nome do Utilizador',
    'password': 'Palavra-chave',
    'login': 'Entrar',
    'logout': 'Sair',
    'profile': 'Perfil',
    'settings': 'Definições',
    'no_account': 'Não tem uma conta pessoal?',
    'register': 'Registe-se',
    'new_entity': 'Quer criar uma entidade?',
    'select_entity_continue': 'Selecione a sua entidade para continuar',
    'no_entities_found': 'Parece que não tem nenhuma entidade.',
    'ask_admin_for_entity': 'Por favor, peça ao seu administrador para o convidar para uma entidade ou',
    'softdiet_offline': 'Está off-line. Para que esta página funcione corretamente, é necessário estar online.',
    'softdiet_error': 'Erro ao carregar a página. Por favor, tente novamente, ou contacte o administrador.',
    'register_entity': 'Criar uma nova entidade',
    'info_register_entity': 'Deve ser representante legal',
    'forgot_password': 'Esqueceu-se da palavra-chave?',
    'register_steps1': 'Informação da Entidade',
    'register_steps2': 'Selecione o representante legal da entidade',
    'register_steps3': 'Selecione o primeiro profissional',
    'register_steps4': 'Terminar',
    'next': 'Próximo',
    'back': 'Anterior',
    'yes': 'Sim',
    'no': 'Não',
    'of': 'de',
    'super_manager': 'Super Gestor',
    'my_entity': 'Minha Entidade',
    'external_entities': 'Entidades Externas',
    'have_access': 'Tenho acesso',
    'gave_access': 'Deu acesso',
    'managers': 'Gestores',
    'manager_profile': 'Perfil do Gestor',
    'professional_profile': 'Perfil do Profissional',
    'my_professionals': 'Meus profissionais',
    'associations': 'Associações',
    'external_professionals': 'Associações a entidades externas',
    'my_clients': 'Meus Clientes',
    'client_profile': 'Perfil do Cliente',
    'external_clients': 'Clientes Externos',
    'add': 'adicionar',
    'edit': 'Editar',
    'edit_role': 'Editar função',
    'save': 'Guardar',
    'cancel': 'Cancelar',
    'delete': 'Apagar',
    'reset': 'Reset',
    'disassociate': 'Desassociar',
    'search': 'Procurar',
    'label_name': 'Nome',
    'label_username': 'Nome do utilizador',
    'label_email': 'Endereço de e-mail',
    'label_business_username': 'Nome do utilizador comercial',
    'label_business_email': 'Endereço de e-mail comercial',
    'label_choose_country': 'Escolha um país',
    'label_fiscal_id': 'Nº Identificação Fiscal',
    'label_birthday': 'Aniversário',
    'label_gender': 'Sexo',
    'label_ethnicity': 'Etnia',
    'label_street': 'Rua',
    'label_postal_code': 'Código postal',
    'label_city': 'Cidade',
    'label_locality': 'Localidade',
    'label_state': 'Estado / Província / Região',
    'label_country': 'País',
    'label_mobile': 'Telemóvel',
    'label_phone': 'Telefone',
    'label_language': 'Língua',
    'label_status': 'Estado',
    'label_permissions': 'Permissão',
    'label_role': 'Função',
    'yup_short': 'Muito curto!',
    'yup_long ': 'Demasiado longo!',
    'yup_email_valid': 'O Endereço de e-mail deve ser um válido',
    'yup_country_required': 'O país é obrigatório',
    'yup_fiscal_id_required': 'Nº Identificação Fiscal é obrigatório',
    'yup_entity_required': 'A entidade é obrigatória',
    'yup_name_required': 'O nome é obrigatório',
    'yup_email_required': 'O endereço de e-mail é obrigatório',
    'yup_username_required': 'O nome do Utilizador é obrigatório',
    'yup_username_invalid': 'O nome do Utilizador deve conter apenas letras, números, _ e .',
    'yup_password_required': 'A palavra-chave é obrigatória',
    'yup_street_required': 'a Rua é obrigatório',
    'yup_postal_code_required': 'o Código postal é obrigatório',
    'yup_locality_required': 'A localidade é obrigatório',
    'yup_city_required': 'A cidade é obrigatório',
    'yup_state_required': 'O Estado / Província / Região é obrigatório',
    'yup_gender_required': 'O sexo é obrigatório',
    'yup_birthdate_required': 'A data de nascimento é obrigatória',
    'start_new_entity': 'Comece com uma nova entidade.',
    'same_person': 'A mesma pessoa que o representante legal',
    'rows_per_page': 'Linhas por página:',
    'search_manager': 'Pesquisa do Gestor',
    'search_professional': 'Pesquisa de Profissional',
    'search_entity': 'Pesquisar Entidade',
    'search_client': 'Pesquisar Cliente',
    'view_profile': 'Ver perfil',
    'my_profile': 'Meu Perfil',
    'select_entity': 'Selectione a Entidade',
    'password_reset': 'Redefinição de palavra-chave',
    'need_username_entity_reset': 'O nome de utilizador e entidade associada são obrigatórios.',
    'password_reset_email_sent_success':
        'Se for uma conta válida, um e-mail deverá ter sido enviado para a sua conta associada à entidade {{entityName}} com o nome de utilizador {{username}}.',
    'info_no_entity_for_fiscal_id': 'Nenhuma entidade encontrada com este id fiscal.',
    'info_no_user_for_fiscal_id':
        'Nenhum {{user_type}} encontrado com este id fiscal. Preencha os dados do utilizador do {{user_type}}.',
    'register_entity_details_info':
        'Está a ser criada a entidade {{entity_name}}, localizada em {{entity_country}} com o número de identificação fiscal {{entity_fiscalID}}.',
    'register_legal_representative_details_info':
        'O seu responsável legal é {{legal_representative_name}} com número fiscal {{legal_representative_fiscalID}} em {{legal_representative_fiscalCountry}} ',
    'register_operations_manager_details_info':
        'ao qual associou um gestor com o nome {{operations_manager_name}} e número de identificação fiscal {{operations_manager_fiscalID}} em {{operations_manager_fiscalCountry}}.',
    'register_same_operations_manager_details_info': 'ao qual também se associou como gestor.',
    'error_regist': 'Erro de Registo',
    'filter': 'Filtro',
    'filter_list': 'Lista de filtros',
    'search_not_found': 'Não encontrado',
    'search_no_results': 'Nenhum resultado encontrado para',
    'search_try_again': '. Tente verificar se há erros de digitação ou usar palavras completas.',
    'add_entity': 'Adicionar entidade externa',
    'add_user': 'Adicionar {{user_type}} ao {{entity}}',
    'add_professional_success':
        '{{user_type}} adicionado com sucesso.\nO e-mail para verificação foi enviado para {{user_email}}.',
    'disassociate_manager': 'Desassociar gestor?',
    'disassociate_manager_info': 'Tem certeza de que deseja desassociar este gestor?',
    'disassociate_professional': 'Desassociar profissional?',
    'disassociate_professional_info': 'Tem certeza de que deseja desassociar este profissional?',
    'user_is_not_confirmed_yet': 'O utilizador ainda não foi confirmado',
    'unique_id_already_exists': 'O usuário já existe com o par {{unique_id_country}} {{unique_id_number}}',
    'username_already_exists': 'O nome de utilizador já existe.',
    'person_already_a_professional_in_entity': 'Esta pessoa já é profissional na entidade indicada.',
    'person_already_a_manager_in_entity': 'Esta pessoa já é gestor na entidade especificada.',
    'entity_unique_id_already_exists': 'Já existe uma entidade com este ID Fiscal.',
    'motivation_description':
        '0 a 2: Pré-contemplação - nem intenção nem exercício\nNÃO VÊM AO CLUBE A MENOS QUE OS MÉDICOS OS OBRIGUEM (DESISTEM LOGO)\n\n2 a 4: Contemplação - intenção mas nenhum exercício\nPOUCO PROVÁVEL VIREM AO CLUBE MAS QUANDO VÊM DESISTEM\n\n4 a 6: Preparação - intenção e exercício ocasional\nVêm AO CLUBE MAS TÊM FORTE POSSIBILIDADE DE DESISTIR (VÃO E VÊM)\n\n6 a 8: Acção - exercício regular por pouco tempo\nFAZEM EXERCÍCIO FÍSICO MAS NÃO SÃO REGULARES\n\n8 a 10: Manutenção - exercício regular habitual\nFAZEM EXERCÍCIO REGULAR',
    'download_report': 'Descarregar Relatório',
    'template_type': 'Escolha o modelo',
    'complete': 'Completo',
    'frailty': 'Fragilidade',
    'isak': 'ISAK',
    'appointments_list': 'Lista de Consultas',
    'report': 'Relatório',
    'body_composition': 'Composição Corporal',
    'last_appointment': 'Última consulta',
    'age': 'Idade',
    'handgrip': 'Handgrip',
    'bmi': 'IMC',
    'lipowise': 'Lipowise',
    'gripwise': 'Gripwise',
    'type': 'Tipo',
    'value': 'Valor',
    'ref_value': 'Valor de Referência',
    'equation': 'Equação',
    'other_skinfold_calliper': 'Outro Adipómetros',
    'bioimpedance': 'Bioimpedância',
    'anthropometry': 'Antropometria',
    'waist_hip_ratio': 'Relação cintura-anca',
    'weight': 'Peso',
    'height': 'Altura',
    'sitting_height': 'Altura sentada',
    'arm_span': 'Envergadura do Braço',
    'girths': 'Perímetros',
    'lenghts_heights': 'Comprimentos e Alturas',
    'breadths_depths': 'Larguras de Profundidade',
    'somatotype': 'Somatótipo',
    'endomorphy': 'Endomorfo',
    'mesomorphy': 'Mesomorfo',
    'ectomorphy': 'Ectomorfo',
    'skinfold_measurements': 'Medidas de pregas cutâneas',
    'measure': 'Medida',
    'skinfold_compressibility': 'Compressibilidade de Pregas Cutâneas',
    'skinfold': 'Prega Cutânea',
    'thickness': 'Espessura',
    'time': 'Tempo',
    'geriatric_assessment': 'Avaliação Geriátrica (fisica)',
    'weigh_loss': 'Perda de peso',
    'energy_expenditure': 'Gasto Energético',
    'exhaustion': 'Exaustão',
    'grip_strenght': 'Força de Preensão',
    'walk_time': 'Tempo de Caminhada',
    'frailty_score': 'Pontuação de fragilidade',
    'sarcopenia': 'Sarcopenia',
    'muscle_strength': 'Força Muscular',
    'sit_to_stand': 'Sit to Stand (5x)',
    'muscle_quantity_quality': 'Quantidade/Qualidade muscular',
    'muscle_quantity_estimation': 'Estimativa da quantidade de músculo',
    'calf_girth': 'Perímetro geminal',
    'mid_arm_muscle_girth': 'Circunferência Muscular do Braço',
    'physical_performance': 'Desempenho Físico',
    'gait_speed_4m': 'Velocidade de Marcha (4 m)',
    'strength': 'Força',
    'left_hand': 'Mão esquerda',
    'right_hand': 'Mão direita',
    'dominant': 'Dominante',
    'non_dominant': 'Não Dominante',
    'size': 'Tamanho',
    'measures': 'Medidas',
    'max_strength': 'Max. Força',
    'avg_strength': 'Força Média',
    'strength_profile': 'Perfil de Força',
    'other_dynamometer': 'Outro Dinamómetro',
    'surveys': 'Inquéritos',
    'total': 'Total',
    'sarc_f': 'SARC-F',
    'answer': 'Resposta',
    'score': 'Pontuação',
    'other_tests': 'Outros Testes',
    'last_year': 'ano passado',
    'no_appointments': 'Sem consultas',
    'error_login': 'Não fez login corretamente ou a sua conta foi desativada.',
    'enter_username': 'Nome de utilizador desconhecido, por favor, verifique.',
    'identified_risks': 'Riscos identificados',
    'alcohol': 'Álcool',
    'sedentarism': 'Sedentarismo',
    'overweight': 'Excesso de peso',
    'sleep': 'Sono',
    'evolution': 'Evolução',
    'maximum': 'Máximo',
    'average': 'Média',
    'skinfold_values': 'Valores de pregas cutâneas',
    'email': 'Email',
    'identification': 'Identificação',
    'appointment_date': 'Data da Consulta',
    'right': 'Direita',
    'left': 'Esquerda',
    'body_comp': 'Análise da composição corporal',
    'frailty_sarcopenia': 'Fragilidade Física e Sarcopenia',
    'reduced': 'Reduzido',
    'associate': 'Associadar',
    'associate_manager': 'Associar gestor?',
    'associate_manager_info': 'Tem a certeza que deseja associar este gestor?',
    'associate_professional': 'Associar profissional?',
    'associate_professional_info': 'Tem a certeza que deseja associar este profissional?',
    'note': 'Observações',
    'regist_ok': 'Registo efectuado com sucesso. Verifique seu e-mail!',
    'invalid_token': 'Sua sessão expirou. Por favor entre!',
    'manager_login': 'Gestão de Entidades',
    'professional_login': 'Gestão de Pacientes',
    'info_same_user': 'Esta é a mesma pessoa que o representante legal!',
    'median': 'Mediana',
    'mst_abbott_title': 'Malnutrition Screening Tool (MST)',
    'full_mna_title': 'Full MNA®',
    'survey_not_complete': 'Nem todas as perguntas foram respondidas',
    'mst_abbott_S2Score1A': 'MST = 0 ou 1 não está em risco',
    'mst_abbott_S2Score1B':
        'Come bem com pouca ou nenhuma perda de peso\nSe o tempo de internamento exceder 7 dias, faça uma nova avaliação, repetindo semanalmente conforme necessário.',
    'mst_abbott_S2Score2A': 'MST = 2 ou mais está em risco',
    'mst_abbott_S2Score2B':
        'Come mal e/ou perda de peso recente.\nImplementar intervenções nutricionais rapidamente. Faça uma consulta nutricional dentro de 24-72 horas, dependendo do risco.',
    'full_mna_score1': 'Pontuação da Triagem (subtotal, máximo de 14 pontos)',
    'full_mna_score1A': '12-14 pontos: estado nutricional normal',
    'full_mna_score1B': '8-11 pontos: sob risco de desnutrição',
    'full_mna_score1C': '0-7 pontos: desnutrido',
    'full_mna_score2': 'Avaliação global (máximo 16 pontos)',
    'full_mna_score3': 'Pontuação total (máximo 30 pontos)',
    'full_mna_score4': 'Avaliação do Estado Nutricional',
    'full_mna_score4A': 'de 24 a 30 pontos estado nutricional normal',
    'full_mna_score4B': 'de 17 a 23,5 pontos sob risco de desnutrição',
    'full_mna_score4C': 'menos de 17 pontos desnutrido',
    'same_username': 'O nome de utilizador é o mesmo que o representante legal',
    'entity_name_already_exists': 'Já existe uma entidade com este Nome.',
    'body_mass': 'Massa Gorda',
    'matiegka': 'Matiegka',
    'rocha': 'Rocha',
    'wurch': 'Wurch',
    'more_5%': 'mais de 5%',
    'less_383kcals': 'menos de 383 kcals',
    'less_270kcals': 'menos de 270 kcals',
    'more_2day_week': 'mais de 2 dias/semana',
    'less_29kgf': 'menos de 29 kgf',
    'less_30kgf': 'menos de 30 kgf',
    'less_32kgf': 'menos de 32 kgf',
    'less_17kgf': 'menos de 17 kgf',
    'less_17.3kgf': 'menos de 17.3 kgf',
    'less_18kgf': 'menos de 18 kgf',
    'less_21kgf': 'menos de 21 kgf',
    'more_6s': 'mais de 6 s',
    'more_7s': 'mais de 7 s',
    'frailty_robust': 'Robusto',
    'frailty_pre_frail': 'Pré-frágil',
    'frailty_frail': 'Frágil',
    'result_must_confirmed_professional': 'Este resultado deve ser confirmado por um profissional',
    'sarc_f_score': 'Pontuação do SARC-F',
    'find_cases': 'Encontrar casos',
    'no_sarcopenia': 'Sem sarcopenia',
    'predictive_sarcopenia': 'Sarcopenia preditiva',
    'more_equal_4': 'mais ou igual a 4',
    'assess': 'Avaliar',
    'less_27kg': 'menos de 27 kgf',
    'less_16kg': 'menos de 16 kgf',
    'more_15s': 'mais de 15 segundos',
    'confirm': 'Confirmar',
    'muscle_estimation': 'Estimativa muscular',
    'less_20kg': 'menos de 20kg',
    'less_15kg': 'menos de 15kg',
    'less_equal_31cm': 'menor ou igual a 31 cm',
    'less_equal_26_3cm': 'menor ou igual a 26,3 cm',
    'less_equal_20_2cm': 'menor ou igual a 20,2 cm',
    'severity': 'Gravidade',
    'more_5s': 'mais de 5 segundos',
    'sarcopenia_score': 'Pontuação de Sarcopenia',
    'presence_sarcopenia': 'Presença de Sarcopenia',
    'severe_sarcopenia': 'Sarcopenia Grave',
    'criteria': 'Critérios',
    'results': 'Resultados',
    'presence': 'Presença',
    'parameters': 'Parâmetros',
    'dashboard': 'Painel Resumo',
    'anamnese': 'Anamnese',
    'appointments': 'Consultas',
    'already_have_an_account': 'Já tem uma conta? ',
    'all_steps_completed': 'Todos os passos completados.',
    "measurement_profiles": "Perfis de Medição",
    'my_patients': 'Meus Pacientes',
    'search_patient': 'Pesquisar Paciente',
    'patient': 'Paciente',
    'create_measurement_profile': 'Criar Perfil de Medição',
    'profile_name': 'Nome do Perfil',
    'step_list': 'Lista de Passos',
    'add_step': 'Adicionar Passo',
    "S-AEROBIC-ENDURANCE": "Resistência aeróbica",
    "S-ANTHRO": "Antropometria",
    "S-ANTHRO-BREADTHS-DEPTHS": "Larguras e Profundidades",
    "S-ANTHRO-GIRTHS": "Circunferências",
    "S-ANTHRO-LENGTHS-HEIGHTS": "Comprimentos e Alturas",
    "S-BASIC-MEASUREMENTS": "Medições Básicas",
    "S-BIO-IMPEDANCE": "Bioimpedância",
    "S-BLOOD-PRESSURE": "Pressão arterial",
    "S-BLOOD-TESTS": "Testes de sangue",
    "S-CATEGORY-ANALYTICAL-DATA": "Dados analíticos",
    "S-CATEGORY-ANTHROPOMETRY": "Antropometria",
    "S-CATEGORY-ERGONOMIC": "Ergonómico",
    "S-CATEGORY-FOLLOW-UP-SURVEYS": "Inquéritos de acompanhamento",
    "S-CATEGORY-FUNCTIONAL": "Funcional",
    "S-CATEGORY-MORPHOLOGICAL": "Morfológico",
    "S-CATEGORY-OTHERS": "Outros",
    "S-CATEGORY-PRESETS": "Predefinições",
    "S-GOALS": "Metas",
    "S-HAND-CONFIGURATION": "Configure as Mãos",
    "S-INITIAL-PRESET-CHECKPOINT": "Verificação de requisitos",
    "S-LIMBS-BALANCE": "Equilíbrio dos membros",
    "S-MANUAL-HAND-STRENGTH": "Força (Outro)",
    "S-MANUAL-OTITEST": "Otite (Manual)",
    "S-MANUAL-SKIN": "Pregas cutâneas (Outro)",
    "S-MANUAL-STRENGTH-ABDUCTION": "Abdução e Adução de Pernas (Outro)",
    "S-MANUAL-TEMP": "Temperatura (Manual)",
    "S-MEDICAL-AND-CLINICAL": "Médico e clínico",
    "S-MOBILITY-AND-STABILITY": "Mobilidade e estabilidade",
    "S-NOTES": "Faça Anotações",
    "S-NUTRITION": "Nutrição",
    "S-OTITEST": "Otite (Otitest)",
    "S-OXIMETER": "Oxímetro",
    "S-PERSONAL-AND-SOCIAL": "Pessoal e social",
    "S-PHOTOGRAMETRY": "Fotogrametria",
    "S-PHYSICAL-ACTIVITY-AND-SPORTS": "Atividade física e desportos",
    "S-POSTURE": "Postura",
    "S-QUALITY-PRESET-CHECKPOINT": "Ponto de verificação de qualidade muscular",
    "S-SARCOPENIA-ASSESSMENT": "Sarcopenia",
    "S-SEVERITY-PRESET-CHECKPOINT": "Ponto de verificação de gravidade da sarcopenia",
    "S-SIT-TO-STAND": "Sentar para Levantar",
    "S-SIT-TO-STAND-INTERVAL": "Intervalo Sentar para Levantar",
    "S-SKIN": "Pregas cutâneas (Lipowise)",
    "S-SKINFOLD-ORDER": "Ordem de Medição das Pregas Cutâneas",
    "S-STRENGTH": "Força (Gripwise)",
    "S-STRENGTH-ABDUCTION": "Abdução e Adução de Pernas (Gripwise)",
    "S-STRENGTH-PRESET-CHECKPOINT": "Ponto de verificação de força muscular",
    "S-SURVEY": "Inquéritos",
    "S-SURVEY-PRESET-CHECKPOINT": "Inquérito SarcF",
    "S-TEMP-OTITEST": "Temperatura (Otitest)",
    "S-UP-AND-GO": "TUG (3+3m)",
    "S-WALK": "Tempo de marcha (15 pés)",
    "S-WEIGHT": "Peso e Altura",
    "S-WEIGHT-PAST": "Peso (anterior)",
    "choose_at_least_1_step": 'Escolha pelo menos 1 passo',
    "save_profile": "Guardar Perfil",
    "delete_profile": "Apagar Perfil",
    "confirm_delete_profile": "Tem a certeza que deseja apagar este perfil:",
    'patient_update_error_title': 'Erro ao atualizar o paciente',
    'patient_update_error_description': 'Ocorreu um erro ao atualizar o paciente. Por favor, tente novamente.',
    'patient_update_success_title': 'Sucesso',
    'patient_update_success_description': 'O paciente foi atualizado com sucesso.',
    'axial_measurements': 'Medidas Axiais',
    'bilateral_measurements': 'Medidas Bilaterais',
    "physical_activity": "Atividade Física",
    "physical_activity_description": "Exemplo de dados: Tempo gasto a fazer alguns tipos de exercícios.",
    "physical_activity_field_label": "Atividade Física (horas/semana)",
    "physical_activity_header": "ATIVIDADE FÍSICA",
    "physical_activity_inactive": "Sedentário",
    "physical_activity_label": " (horas por semana)",
    "physical_activity_light": "Intensidade Baixa (1-3 dias/semana)",
    "physical_activity_moderate": "Intensidade Moderada (3-5 dias/semana)",
    "physical_activity_vigorous": "Intensidade Vigorosa (6-7 dias/semana)",
    "have_not_have_used": "Já utilizou",
    "have_not_never_used": "Nunca utilizou",
    "hours_per_night": "Horas por noite",
    "level": "Nível",
    "level_elevate": "Elevado",
    "level_low": "Baixo",
    "level_medium": "Médio",
    "gender_female": "Feminino",
    "gender_label": "Sexo",
    "gender_male": "Masculino",
    "gender_other": "Outro",
    "ethnicity_african": "Africana",
    "ethnicity_asiatic": "Asiática",
    "ethnicity_caucasian": "Caucasiana",
    "ethnicity_hispanic": "Hispânica",
    "ethnicity_other": "Outra",
};
