import Step from '../Step';

class BasicMeasurementsStep extends Step {
    constructor(name) {
        super(name);
    }

    basicMeasurements = [];

    // we're saving the settings as a list of strings, where the elements are the selected settings
    // anything that is not in the list is considered not selected, thus false
    initFromData(data) {
        this.basicMeasurements = data.basic_measurements || data.basicMeasurements;
    }
    
    // we're saving the settings as a list of strings, where the elements are the selected settings, which are the ones set to true
    saveBasicMeasurements(basicMeasurements) {
        this.basicMeasurements = Object.keys(basicMeasurements).filter(key => basicMeasurements[key]);
    }
}

export default BasicMeasurementsStep;
