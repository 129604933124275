export default {
    'fold_triceps': 'Tricipital',
    'fold_subscapular': 'Sous-scapulaire',
    'fold_biceps': 'Bicipital',
    'fold_iliacCrest': 'Iliocristal',
    'fold_supraspinale': 'Supraspinal',
    'fold_abdominal': 'Ombilical',
    'fold_anteriorThigh': 'Quadricipital',
    'fold_medialcalf': 'Sural',
    'fold_cheek': 'Joue',
    'fold_chin': 'Menton',
    'fold_chest': 'Coffre',
    'fold_umbilical': 'Ombilicale',
    'fold_knee': 'Le genou',
    'fold_midAxillary': 'Axillaire',
    'fold_lowerBack': 'Lombes',
    'fold_calf': 'Sural',
    'fold_axillar': 'Axillaire',
    'fold_total': 'Totale',
    'eq1': 'Durnin&Womesley Triceps',
    'eq2': 'Durnin&Womesley Sous-scapulaire',
    'eq3': 'Durnin&Womesley T+S',
    'eq4': 'Durnin&Womesley 4 plis cutanés',
    'eq5': 'Jackson&Pollock 3 plis cutanés Homme',
    'eq6': 'Jackson&Pollock 3 plis cutanés(log) Homme',
    'eq7': 'Jackson&Pollock 3 plis cutanés Femme',
    'eq8': 'Jackson&Pollock 3 plis cutanés(log) Femme',
    'eq9': 'Jackson&Pollock 7 plis cutanés',
    'eq10': 'Jackson&Pollock 7 plis cutanés (log)',
    'eq11': 'Jackson&Pollock 4 plis cutanés femelles',
    'eq12': 'Jackson&Pollock 4 plis cutanés (log) Femme',
    'eq13': 'Jackson&Pollock 1985 3 plis cutanés Homme',
    'eq14': 'Jackson&Pollock 1985 3 plis cutanés Femme',
    'eq15': 'Peterson',
    'eq16': 'Visser 4 plis cutanés',
    'eq17': 'Visser T+B',
    'eq18': 'Slaughter T+C',
    'eq19': 'Slaughter T+S Homme',
    'eq20': 'Slaughter T+S femme',
    'eq21': 'Carter (Isak)',
    'eq22': 'Yuhasz',
    'eq23': 'Withers Homme',
    'eq24': 'Withers Femme',
    'eq25': 'Novel',
    'eq26': 'Guedes (1994) Homme',
    'eq27': 'Guedes (1994) Femme',
    'eq28': 'Kagawa T+I Femme',
    'eq29': 'Kagawa T+I+B Femme',
    'eq30': 'Kagawa A+C Homme',
    'eq31': 'Kagawa 8 plis cutanés Homme',
    'eq32': 'Kagawa T+S Homme',
    'eq33': 'Faulkner',
    'eq34': 'Weltman',
    'eq35': 'Dezenberg',
    'eq36': 'Kannierappan (enceinte)',
    'eq37': 'Huston Presley (enceinte)',
    'eq38': 'Ball Homme',
    'eq39': 'Ball Femme',
    'eq40': 'Pétroski Homme',
    'eq41': 'Pétroski Femme',
    'eq42': 'Sloan Homme',
    'eq43': 'Sloan Femme',
    'eq44': 'Reilly Homme',
    'eq45': 'Evans',
    'eq46': 'Eston',
    'eq47': 'Haisman Homme',
    'eq48': 'Wilmore Behnke Homme',
    'eq49': 'Wilmore Behnke Femme',
    // bone mass - ISAK consense
    'eq50': 'Rocha (masse osseuse)',
    'eq51': 'Martin (masse osseuse)',
    // muscle weight - ISAK consense
    'eq52': 'Matiegka (poids musculaire)',
    // skeletal muscle mass - ISAK consense
    'eq53': 'Lee (masse musculaire)',
    'eq54': 'Poorts (masse musculaire)',
    // residual weight - ISAK consense
    'eq55': 'Wurch (poids résiduel)',
    // skinfold sums - ISAK consense
    'eq56': 'Somme de 6 plis cutanés',
    'eq57': 'Somme de 8 plis cutanés',
    'eq58': 'Somme de plis cutanés',
    // Somatotype Heath-Carter (2002) - ISAK consense
    'eq59': 'Endomorphe',
    'eq60': 'Mésomorphe',
    'eq61': 'Ectomorphe',
    'eq62': 'Conversion de 15 pieds à 4 m',
    'anthropometry_section_girths': 'Girlures',
    'anthropometry_section_lengths_and_heights': 'Longueurs et hauteurs',
    'anthropometry_section_breadths_and_depths': 'Plateaux et profondeurs',
    'anthropometry_head': 'Diriger',
    'anthropometry_neck': 'Cou',
    'anthropometry_relaxedArm_wrist': 'Bras détendu',
    'anthropometry_flexedArm': 'Bras fléchi',
    'anthropometry_flexedAndTensedArm': 'Bras fléchi et tendu',
    'anthropometry_forearm': 'Avant bras',
    'anthropometry_wrist': 'Poignet',
    'anthropometry_chest': 'Coffre',
    'anthropometry_waist_minimal': 'Taille (minimale)',
    'anthropometry_waist_iliac': 'Taille (iliaque)',
    'anthropometry_gluteal': 'Fessiers',
    'anthropometry_thigh_1cm_below_gluteal': 'Cuisse 1 cm sous la fesse',
    'anthropometry_tight': 'La cuisse',
    'anthropometry_calf': 'Sural',
    'anthropometry_ankle': 'La cheville',
    'anthropometry_acromialeRadiale': 'Bras (Acromiale-Radiale)',
    'anthropometry_radialeStylion': 'Avant-bras (radiale-stylon)',
    'anthropometry_midstylion_dactylion': 'Midstylion-dactylion',
    'anthropometry_iliospinaleHeight': 'Hauteur iliospinale',
    'anthropometry_trochanterionHeight': 'Hauteur de trochanterion',
    'anthropometry_trochanterionTibialeLaterale': 'Trochanterion-tibiau latéral',
    'anthropometry_tibialeLateraleHeight': 'Tibiale latéral hauteur',
    'anthropometry_tibialeMedialeSphyrionTibiale': 'Tibiale Mediale-Sphyrion Tibiale',
    'anthropometry_biacromial': 'Biagionnaire',
    'anthropometry_anterior_posterior_abdominal_depth': 'Profondeur abdominale postérieure antérieure',
    'anthropometry_biiliocristal': 'Biiliocristal',
    'anthropometry_bimalleolar': 'Bimalléolaire',
    'anthropometry_foot_length': 'Longueur du pied',
    'anthropometry_transverse_chest': 'Coffre transversale',
    'anthropometry_anterior_posterior_chest_depth': 'Profondeur de coffre postérieure antérieure',
    'anthropometry_bi_epicondylar_humerus': 'Humérus bi-épicondylaire',
    'anthropometry_bi_epicondylar_femur': 'Fémur bi-épicondylaire',
    'anthropometry_bi_estiloideo': 'Bi-styloïde',
    'alcohol_consumption_none': 'Aucune',
    'alcohol_consumption_1_portion': '1 partie',
    'alcohol_consumption_2_portions': '2 portions',
    'alcohol_consumption_more_than_2_portions': '> 2 portions',
    'expectations_weightLoss': 'Perte de poids',
    'expectations_weightGain': 'Gain de poids',
    'expectations_muscleMassGain': 'Gain de masse musculaire',
    'expectations_other': 'Autre',
    'level_low': 'Meugler',
    'level_medium': 'Moyen',
    'level_elevate': 'Élever',
    'physical_activity_inactive': 'Inactive',
    'physical_activity_light_intensity': 'Intensité légère (1-3 jours/semaine)',
    'physical_activity_moderate_intensity': 'Intensité modérée (3-5 jours/semaine)',
    'physical_activity_vigorous_intensity': 'Intensité vigoureuse (6-7 jours/semaine)',
    'never_used': 'Jamais utilisé',
    'have_used': 'A utilisé',
    'smoke_none': 'Aucune',
    'smoke_less_10': '<10',
    'smoke_10_to_20': '10-20',
    'smoke_20_to_40': '20-40',
    'smoke_more_than_40': '>40',
    'appetite_inappetent': "Pas d'appétit",
    'appetite_regular': 'Régulière',
    'appetite_moderate': 'Modérer',
    'appetite_strong': 'Forte',
    'nutrition_food_beans': 'Des haricots',
    'nutrition_food_dairy': 'Laitière',
    'nutrition_food_drinks': 'Boisson',
    'nutrition_food_eggs': 'Œufs',
    'nutrition_food_fish': 'Poisson',
    'nutrition_food_fruits': 'Des fruits',
    'nutrition_food_grains/bread/pasta': 'Grains/pain/pâtes',
    'nutrition_food_meat': 'Moi à',
    'nutrition_food_nuts': 'Des noisettes',
    'nutrition_food_prepared meals': 'Repas préparés',
    'nutrition_food_poultry': 'la volaille',
    'nutrition_food_seafood': 'Fruit de mer',
    'nutrition_food_sweets': 'Bonbons',
    'nutrition_food_vegetables': 'Des légumes',
    'nutrition_food_other': 'Autre',
    'nutrition_allergies_and_intolerances_bee pollen/propolis': "Pollen/propolis d'abeille",
    'nutrition_allergies_and_intolerances_beef chicken': 'Poulet au bœuf',
    'nutrition_allergies_and_intolerances_celery': 'Céleri',
    'nutrition_allergies_and_intolerances_crustaceans': 'Crustacés',
    'nutrition_allergies_and_intolerances_eggs': 'Œufs',
    'nutrition_allergies_and_intolerances_fish': 'Poisson',
    'nutrition_allergies_and_intolerances_gluten-containing cereals':
        'Céréales contenant du gluten, nommément: blé, épeautre, blé khorasan, seigle, orge, avoine',
    'nutrition_allergies_and_intolerances_latex (natural rubber)': 'Latex (caoutchouc naturel)',
    'nutrition_allergies_and_intolerances_lupine': 'Lupin',
    'nutrition_allergies_and_intolerances_mango': 'Mangue',
    'nutrition_allergies_and_intolerances_milk lactose': 'Lactose au lait',
    'nutrition_allergies_and_intolerances_milk': 'Lait',
    'nutrition_allergies_and_intolerances_molluscs': 'Mollusques',
    'nutrition_allergies_and_intolerances_mustard': 'Moutarde',
    'nutrition_allergies_and_intolerances_nuts':
        'Noix, nommément amandes, noisettes, noix, noix de cajou, pacanes, noix du Brésil, pistaches, macadamia ou noix du Queensland',
    'nutrition_allergies_and_intolerances_peach': 'La pêche',
    'nutrition_allergies_and_intolerances_peanuts': 'Cacahuètes',
    'nutrition_allergies_and_intolerances_pork': 'Porc',
    'nutrition_allergies_and_intolerances_royal jelly': 'Gelée royale',
    'nutrition_allergies_and_intolerances_sesame seeds': 'graines de sésame',
    'nutrition_allergies_and_intolerances_soy': 'Soja',
    'nutrition_allergies_and_intolerances_sulphur dioxide and sulphites': 'Dioxyde de soufre et sulfites',
    'nutrition_allergies_and_intolerances_tomato': 'Tomate',
    'diet_atkins': 'Atkins',
    'diet_blood_type': 'Groupe sanguin',
    'diet_clear_liquid': 'Liquide transparent',
    'diet_dash': 'TIRET',
    'diet_diabetic': 'Diabétique',
    'diet_diary_free': 'Agenda',
    'diet_energy_restricted': 'Énergie restreinte',
    'diet_full_liquid': 'Liquide complet',
    'diet_general': 'Générale',
    'diet_gluten_free': 'Sans gluten',
    'diet_glycemic_index': 'Index glycémique',
    'diet_high_calorie': 'Hautement calorifique',
    'diet_high_protein': 'Riche en protéines',
    'diet_high_fiber': 'Riche en fibres',
    'diet_ketogenic': 'Céché',
    'diet_lactose_free': 'Sans lactose',
    'diet_low_carb': 'Faible glucidité',
    'diet_low_cholesterol': 'Faible cholestérol',
    'diet_low_fat': 'Faible en gras',
    'diet_low_potassium': 'Faible potassium',
    'diet_low_residue': 'Résidu',
    'diet_low_fiber': 'Fibre basse',
    'diet_low_sodium': 'Faible sodium',
    'diet_low_fodmap': 'Faible fodmap',
    'diet_macrobiotic': 'Macrobiotique',
    'diet_mediterranean': 'Méditerranéenne',
    'diet_ncs': 'Pas de bonbons concentrés (NCS)',
    'diet_paleo': 'Paléo',
    'diet_pureed': 'En purée',
    'diet_raw_food': 'Nourriture crue',
    'diet_renal': 'Rénale',
    'diet_soft': 'Douce',
    'diet_vegan': 'Végétalienne',
    'diet_vegetarian_flexitarian': 'Végétarien (flexitaire)',
    'diet_vegetarian_lacto': 'Végétarien (Lacto)',
    'diet_vegetarian_lacto_ovo': 'Végétarien (Lacto-Ovo)',
    'diet_vegetarian_ovo': 'Végétarien (Ovo)',
    'diet_vegetarian_pescatarian': 'Végétarien (pespararian)',
    'diet_weight_watchers': 'Weight Watchers',
    'diet_whole_30': 'Ensemble 30',
    'bowel_normal': 'Normale',
    'bowel_constipation': 'Constipation',
    'bowel_diarrhea': 'La diarrhée',
    'bowel_irregular': 'Irrégulière',
    'bristol_type1': 'Type 1: morceaux distincts, comme les écrous (difficiles à passer)',
    'bristol_type2': 'Type 2: en forme de saucisse, mais grumeleux',
    'bristol_type3': 'Type 3: comme une saucisse mais avec des fissures sur sa surface',
    'bristol_type4': 'Type 4: Comme une saucisse ou un serpent, lisse et doux',
    'bristol_type5': 'Type 5: Blobs doux avec bords coupés transparents (facile à passer)',
    'bristol_type6': 'Type 6: Morceaux moelleux avec bords en lambeaux, un tabouret mushy',
    'bristol_type7': 'Type 7: aqueux, pas de morceaux solides, entièrement liquide',
    'reasons_weight_loss': 'Perte de poids',
    'reasons_weight_gain': 'Gain de poids',
    'reasons_muscle_mass_gain': 'Gain de masse musculaire',
    'reasons_food_allergies': 'Allergies alimentaires',
    'reasons_overall': 'Amélioration de la nutrition globale',
    'reasons_performance': 'Amélioration des performances sportives',
    'reasons_food': 'Routine alimentaire',
    'reasons_disease': 'Maladie associée',
    'reasons_well_being': 'Bien-être',
    'reasons_other': 'Autre',
    'family_itself': 'Lui-même',
    'family_partner': 'Partenaire',
    'family_father': 'Père',
    'family_mother': 'Mère',
    'family_son': 'Fils',
    'family_daughter': 'La fille',
    'family_other': 'Autre',
    'gender_male': 'Femelle',
    'gender_female': 'Femme',
    'gender_other': 'Autre',
    '<1 day/week': '<1 jour / semaine',
    '1-2 days/week': '1-2 jours/semaine',
    '3-4 days/week': '3-4 jours/semaine',
    '>4 days/week': '> 4 jours/semaine',
    'walking_pleasure': 'Marcher - pour le plaisir',
    'walking_work': 'Marcher - aller au travail',
    'walking_during_work': 'Marcher - pendant la pause de travail',
    'mowing_the_lawn_ridding': 'Tondre la pelouse - avec tondeuse à cheval',
    'mowing_the_lawn_walking': 'Tondre la pelouse - marche derrière la tondeuse électrique',
    'mowing_the_lawn_pushing': 'Tondre la pelouse - poussant la tondeuse à main',
    'raking': 'Ratissage',
    'gardening': 'Jardinage',
    'hiking': 'Randonnée',
    'jogging': 'Le jogging',
    'biking': 'Cyclisme',
    'cycling': 'Exercice cyclisme',
    'dancing': 'Dansante',
    'aerobic': 'Aérobie',
    'bowling': 'Bowling',
    'golf_power_cart': "Golf: équitation d'un panier d'alimentation",
    'golf_walking_cart': 'Golf: Marcher, Tiring Clubs au panier',
    'gold_clubs': 'Golf: Clubs de marche et de transport',
    'single_tennis': 'Tennis unique',
    'doubles_tennis': 'Double tennis',
    'racket_ball': 'Balle de raquette',
    'calisthenics': 'Gymnastique suédoise',
    'swimming': 'Nager',
    'assistance_walking': 'Assistance à la marche',
    'rise_chair': "Sortir d'une chaise",
    'climb_stairs': 'Monter les escaliers',
    'falls': 'Chutes',
    'sarc_f_none': 'Aucune',
    'sarc_f_some': 'Quelque',
    'sarc_f_a_lot_or_unable': 'Beaucoup ou incapable',
    'sarc_f_a_lot_use_aids_or_unable': 'Beaucoup, utiliser le sida ou incapable',
    'sarc_f_a_lot_or_unable_without_help': 'Beaucoup ou incapable sans aide',
    'sarc_f_1_3_falls': '1-3 chutes',
    'sarc_f_4_or_more_falls': '4 ou plus tombe',
    'goals': 'Buts',
    'reasons_appointment': 'Raisons de rendez-vous',
    'expectations': 'Attentes',
    'other_information': 'les autres informations',
    'motivation_title': 'Motivation',
    'personal_social': 'Personnel et social',
    'stress_level': 'Niveau de stress',
    'physical_activity': 'Activité physique',
    'mobility_issues': 'Problèmes de mobilité',
    'smoker': 'Fumeuse',
    'alcohol_consumption': "Consommation d'alcool",
    'drugs': 'Médicaments',
    'sleep_quantity': 'Quantité de sommeil',
    'sleep_quality': 'Qualité du sommeil',
    'nutrition': 'Nutrition',
    'daily_meals': 'Repas journaliers',
    'appetite': 'Appétit',
    'diet_type': 'Type de régime',
    'water_intake': "Consommation d'eau",
    'bowel_movements': 'Mouvements intestinaux',
    'bristol_stool_scale': 'Échelle des selles Bristol',
    'intestinal_gases': 'Gaz intestinal',
    'indigestion': 'Indigestion',
    'nausea': 'La nausée',
    'responsible_for_groceries': "Responsable de l'épicerie",
    'favorite_food': 'Nourriture favorite',
    'disliked_food': 'Nourriture désignée',
    'allergies_and_intolerances': 'Allergies et intolérances',
    'body_fat': 'Graisse corporelle',
    'muscle_mass': 'Masse musculaire',
    'bone_mass': 'Masse osseuse',
    'residual_mass': 'Masse résiduelle',
    'lean_mass': 'Maigre masse',
    'visceral_fat': 'Graisse viscérale',
    'no_intention': 'Sans intention',
    'medium_intention': 'Intention moyenne',
    'certain': 'certaine',
    'hours_night': 'heures/nuit',
    'entity': 'Entité',
    'manager': 'Directrice',
    'professional': 'Professionnelle',
    'client': 'Cliente',
    'signin_as': 'Connectez-vous comme',
    'username': "Nom d'utilisateur",
    'password': 'Mot de passe',
    'login': 'Connexion',
    'logout': 'Se déconnecter',
    'profile': 'Profil',
    'settings': 'Réglages',
    'no_account': "Vous n'avez pas de compte personnel?",
    'register': "S'inscrire",
    'new_entity': 'Cherche à créer une entité?',
    'select_entity_continue': 'Sélectionnez une entité pour continuer',
    'no_entities_found': 'Il semble que vous n\'ayez aucune entité.',
    'ask_admin_for_entity': 'Veuillez demander à votre administrateur de vous inviter dans une entité ou',
    'softdiet_offline': 'Vous êtes hors ligne. Pour que cette page fonctionne correctement, vous devez être en ligne.',
    'softdiet_error': 'Erreur lors du chargement de la page. Veuillez réessayer ou contacter votre administrateur.',
    'register_entity': 'Créer une nouvelle entité',
    'info_register_entity': 'Doit être représentant légal',
    'forgot_password': 'Mot de passe oublié?',
    'register_steps1': "Informations sur l'entité",
    'register_steps2': "Sélectionnez le représentant légal de l'entité",
    'register_steps3': 'Sélectionnez le premier professionnel',
    'register_steps4': 'Finir',
    'next': 'Suivante',
    'back': 'Arrière',
    'yes': 'Oui',
    'no': 'Non',
    'of': 'de',
    'super_manager': 'Super gestionnaire',
    'my_entity': 'Mon entité',
    'external_entities': 'Entités externes',
    'have_access': 'Avoir accès',
    'gave_access': 'A donné accès',
    'managers': 'Gestionnaires',
    'manager_profile': 'Profil du gestionnaire',
    'professional_profile': 'Profil professionnel',
    'my_professionals': 'Mes professionnels',
    'associations': 'Les associations',
    'external_professionals': 'Associations à des entités externes',
    'my_clients': 'Mes clients',
    'client_profile': 'Profil client',
    'external_clients': 'Clients externes',
    'add': 'ajouter',
    'edit': 'Éditer',
    'edit_role': 'Rôle de modification',
    'save': 'sauvegarder',
    'cancel': 'Annuler',
    'delete': 'Supprimer',
    'reset': 'Réinitialiser',
    'disassociate': 'Dissocier',
    'search': 'Rechercher',
    'label_name': 'Nom',
    'label_username': "Nom d'utilisateur",
    'label_email': 'Adresse e-mail',
    'label_business_username': "Nom d'utilisateur des entreprises",
    'label_business_email': "Adresse e-mail d'entreprise",
    'label_choose_country': 'Choisissez un pays',
    'label_fiscal_id': 'ID fiscal',
    'label_birthday': "Date d'anniversaire",
    'label_gender': 'Sexe',
    'label_ethnicity': 'Ethnicité',
    'label_street': 'Rue',
    'label_postal_code': 'Code postal',
    'label_city': 'Ville',
    'label_locality': 'Localité',
    'label_state': 'État / province / région',
    'label_country': 'De campagne',
    'label_mobile': 'Mobile',
    'label_phone': 'Téléphoner',
    'label_language': 'Langue',
    'label_status': 'Statut',
    'label_permissions': 'Autorisation',
    'label_role': 'Rôle',
    'yup_short': 'Trop court!',
    'yup_long': 'Trop long!',
    'yup_email_valid': 'Le courrier électronique doit être une adresse email valide',
    'yup_country_required': 'Pays est requis',
    'yup_fiscal_id_required': "L'ID fiscal est requis",
    'yup_entity_required': 'Une entité est requise',
    'yup_name_required': 'Le nom est requis',
    'yup_email_required': 'E-mail est requis',
    'yup_username_required': "Nom d'utilisateur est nécessaire",
    'yup_username_invalid':
        "Le nom d'utilisateur ne doit contenir que des lettres, des chiffres, des traits de soulignement et des points",
    'yup_password_required': 'Mot de passe requis',
    'yup_street_required': 'La rue est requise',
    'yup_postal_code_required': 'Le code postal est requis',
    'yup_locality_required': 'La localité est requise',
    'yup_city_required': 'La ville est requise',
    'yup_state_required': 'État / province / région requis',
    'yup_gender_required': 'Le sexe est requis',
    'yup_birthdate_required': 'La date de naissance est requise',
    'start_new_entity': 'Commencez avec une nouvelle entité.',
    'same_person': 'Même personne que le représentant légal',
    'rows_per_page': 'Rangées par page:',
    'search_manager': 'Responsable de recherche',
    'search_professional': 'Recherche professionnelle',
    'search_entity': 'Entité de recherche',
    'search_client': 'Client de recherche',
    'view_profile': 'Voir le profil',
    'my_profile': 'Mon profil',
    'select_entity': 'Sélectionnez entité',
    'password_reset': 'Réinitialisation du mot de passe',
    'need_username_entity_reset': "Votre nom d'utilisateur et votre entité associée sont nécessaires.",
    'password_reset_email_sent_success':
        "S'il s'agit d'un compte valide, un email doit avoir été envoyé à votre compte associé à l'entité {{entityName}} avec nom d'utilisateur {{username}}.",
    'info_no_entity_for_fiscal_id': 'Aucune entité trouvée avec cet ID fiscal.',
    'info_no_user_for_fiscal_id':
        'No {{user_type}} trouvé avec cet ID fiscal. Veuillez remplir les données utilisateur {{user_type}}.',
    'register_entity_details_info':
        "L'entité {{entity_name}} est créée, située dans {{entity_country}} avec le numéro d'identification de la taxe {{entity_fiscalID}}.",
    'register_legal_representative_details_info':
        'Votre représentant légal est {{legal_representative_name}} avec le numéro de taxe {{legal_representative_fiscalID}} dans {{legal_representative_fiscalCountry}}',
    'register_operations_manager_details_info':
        "auquel vous avez associé un manager avec le nom {{operations_manager_name}} et numéro d'identification fiscal {{operations_manager_fiscalID}} in {{operations_manager_fiscalCountry}}.",
    'register_same_operations_manager_details_info': 'auquel il était également associé en tant que gestionnaire.',
    'error_regist': "Erreur d'enregistrement",
    'filter': 'Filtre',
    'filter_list': 'Liste de filtres',
    'search_not_found': 'Pas trouvé',
    'search_no_results': 'Aucun résultat trouvé pour',
    'search_try_again': ". Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.",
    'add_entity': 'Ajouter une entité externe',
    'add_user': 'Ajouter {{user_type}} à {{entity}}',
    'add_professional_success':
        '{{user_type}} ajouté avec succès.\nLe courrier électronique de vérification a été envoyé à {{user_email}}.',
    'disassociate_manager': 'Gestionnaire de disassociate?',
    'disassociate_manager_info': 'Êtes-vous sûr de vouloir dissocier ce manager?',
    'disassociate_professional': 'Désassociate professionnel?',
    'disassociate_professional_info': 'Êtes-vous sûr de vouloir dissocier ce professionnel?',
    'user_is_not_confirmed_yet': "L'utilisateur n'est pas encore confirmé",
    'unique_id_already_exists': "L'utilisateur existe déjà avec la paire {{unique_id_country}} {{unique_id_number}}",
    'username_already_exists': "Ce nom d'utilisateur existe déjà.",
    'person_already_a_professional_in_entity': "Cette personne est déjà un professionnel dans l'entité spécifiée.",
    'person_already_a_manager_in_entity': "Cette personne est déjà un gestionnaire dans l'entité spécifiée.",
    'entity_unique_id_already_exists': 'Une entité existe déjà avec cet ID fiscal.',
    'motivation_description':
        "0 à 2 - Pré-contemplation - Ni l'intention ni l'exercice\nNe venez pas à la gym si les médecins les obligent (abandonner bientôt)\n\n2 à 4 - contemplation - intention, mais aucun exercice\nPeu susceptible de venir une salle de gym mais quand ils viennent, ils abandonnent\n\n4 à 6 - Préparation - Intention occasionnelle et exercice\nVenez à la gym mais avoir une forte possibilité d'abandonner (aller et venir)\n\n6 à 8 - Exercice régulier pour une courte période de temps\nFaire de l'exercice physique mais pas régulièrement\n\n8 à 10 - Maintenance - Exercice régulier / N Faites de l'exercice régulier",
    'download_report': 'Télécharger le rapport',
    'template_type': 'Choisissez le modèle',
    'complete': 'Compléter',
    'frailty': 'Fragilité',
    'isak': 'Isak',
    'appointments_list': 'Liste de rendez-vous',
    'report': 'Reportage',
    'body_composition': 'La composition corporelle',
    'last_appointment': 'Dernier rendez-vous',
    'age': 'Âge',
    'handgrip': 'Poignée de main',
    'bmi': 'IMC',
    'lipowise': 'Lipowise',
    'gripwise': 'Gripwise',
    'type': 'Taper',
    'value': 'Évaluer',
    'ref_value': 'Valeur de référence',
    'equation': 'Équation',
    'other_skinfold_calliper': 'Autre calibreur',
    'bioimpedance': 'Bioimpedance',
    'anthropometry': 'Anthropométrie',
    'waist_hip_ratio': 'Ratio de la hanche',
    'weight': 'Poids',
    'height': 'Hauteur',
    'sitting_height': 'Hauteur assise',
    'arm_span': 'Étendue de bras',
    'girths': 'Girlures',
    'lenghts_heights': 'Longueurs et hauteurs',
    'breadths_depths': 'Plateaux et profondeurs',
    'somatotype': 'Somatotype',
    'endomorphy': 'Endomorphe',
    'mesomorphy': 'Mésomorphe',
    'ectomorphy': 'Ectomorphe',
    'skinfold_measurements': 'Mesures du pli cutané ',
    'measure': 'Mesure',
    'skinfold_compressibility': 'Compressibilité du pli cutané',
    'skinfold': 'Pli cutané',
    'thickness': 'Épaisseur',
    'time': 'Temps',
    'geriatric_assessment': 'Évaluation gériatrique (physique)',
    'weigh_loss': 'Perte de poids',
    'energy_expenditure': "Dépenses d'énergie",
    'exhaustion': 'Épuisement',
    'grip_strenght': 'Force de grip',
    'walk_time': 'Temps de marche',
    'frailty_score': 'Score de fragilité',
    'sarcopenia': 'Sarcopénie',
    'muscle_strength': 'Force musculaire',
    'sit_to_stand': 'Sit to Stand (5x)',
    'muscle_quantity_quality': 'Quantité musculaire/qualité',
    'muscle_quantity_estimation': 'Estimation de la quantité musculaire',
    'calf_girth': 'Girthe de mollet',
    'mid_arm_muscle_girth': 'Circonférence Musculaire Brachiale',
    'physical_performance': 'Performance physique',
    'gait_speed_4m': 'Vitesse de la démarche (4 m)',
    'strength': 'Force',
    'left_hand': 'Main gauche',
    'right_hand': 'Main droite',
    'dominant': 'dominante',
    'non_dominant': 'non dominant',
    'size': 'Taille',
    'measures': 'Les mesures',
    'max_strength': 'Max. Force',
    'avg_strength': 'Force moyenne',
    'strength_profile': 'Profil de force',
    'other_dynamometer': 'Autre dynamomètre',
    'surveys': 'Enquêtes',
    'total': 'Totale',
    'sarc_f': 'Sarc-f',
    'answer': 'Répondre',
    'score': 'But',
    'other_tests': 'Autres tests',
    'last_year': "l'année dernière",
    'no_appointments': 'Pas de rendez-vous',
    'error_login': "Vous n'avez pas connecté correctement ou votre compte est désactivé.",
    'enter_username': "Nom d'utilisateur inconnu, veuillez vérifier.",
    'identified_risks': 'Risques identifiés',
    'alcohol': "De l'alcool",
    'sedentarism': 'Sédentarisme',
    'overweight': 'en surpoids',
    'sleep': 'Sommeil',
    'evolution': 'Évolution',
    'maximum': 'Maximum',
    'average': 'Moyenne',
    'skinfold_values': 'Valeurs de cuisson de plage',
    'email': 'E-mail',
    'identification': 'Identification',
    'appointment_date': 'Date de rendez-vous',
    'right': 'Droite',
    'left': 'La gauche',
    'body_comp': 'Analyse de la composition corporelle',
    'frailty_sarcopenia': 'Fragilité physique et sarcopénie',
    'reduced': 'Réduite',
    'associate': 'Associer',
    'associate_manager': 'Manager associé?',
    'associate_manager_info': 'Êtes-vous sûr de vouloir associer ce manager?',
    'associate_professional': 'Professional associé?',
    'associate_professional_info': 'Êtes-vous sûr de vouloir associer ce professionnel?',
    'note': 'Observations',
    'regist_ok': 'Enregistrer le succès. Vérifiez votre email!',
    'invalid_token': 'Votre session a expiré. Veuillez vous connecter!',
    'manager_login': 'Gestion des entités',
    'professional_login': 'Gestion des patients',
    'info_same_user': "C'est la même personne que le représentant légal!",
    'median': 'Médiane',
    'mst_abbott_title': 'Outil de dépistage de la malnutrition (MST)',
    'full_mna_title': 'Full Mna®',
    'survey_not_complete': 'Toutes les questions ne sont pas répondues',
    'mst_abbott_S2Score1A': 'MST = 0 ou 1 non à risque',
    'mst_abbott_S2Score1B':
        'Bien manger avec peu ou pas de perte de poids\nSi la longueur du séjour dépasse 7 jours, puis SRANCHEEN, répétant la semaine si nécessaire.',
    'mst_abbott_S2Score2A': 'MST = 2 ou plus à risque',
    'mst_abbott_S2Score2B':
        'Manger peu et/ou perte de poids récente.\nMettre en œuvre rapidement des interventions nutritionnelles. Effectuer la nutrition consulte dans les 24 à 72 heures, en fonction du risque.',
    'full_mna_score1': 'Score de dépistage (sous-total max. 14 points)',
    'full_mna_score1A': '12-14 points: état nutritionnel normal',
    'full_mna_score1B': '8-11 points: risque de malnutrition',
    'full_mna_score1C': '0-7 points: mal nourris',
    'full_mna_score2': 'Évaluation (max. 16 points)',
    'full_mna_score3': 'Évaluation totale (max. 30 points)',
    'full_mna_score4': "Note d'indicateur de malnutrition",
    'full_mna_score4A': '24 à 30 points Statut nutritionnel normal',
    'full_mna_score4B': '17 à 23,5 points à risque de malnutrition',
    'full_mna_score4C': 'Moins de 17 points mal nourris',
    'same_username': "Même nom d'utilisateur que le représentant légal",
    'entity_name_already_exists': 'Une entité existe déjà avec ce nom.',

    'body_mass': 'Masse Grasse',
    'matiegka': 'Matiegka',
    'rocha': 'Rocha',
    'wurch': 'Wurch',
    'more_5%': 'plus de 5%',
    'less_383kcals': 'moins de 383 kcals',
    'less_270kcals': 'moins de 270 kcals',
    'more_2day_week': 'plus de 2 jours/semaine',
    'less_29kgf': 'moins de 29kgf',
    'less_30kgf': 'moins de 30kgf',
    'less_32kgf': 'moins de 32kgf',
    'less_17kgf': 'moins de 17kgf',
    'less_17.3kgf': 'moins de 17.3kgf',
    'less_18kgf': 'moins de 18kgf',
    'less_21kgf': 'moins de 21kgf',
    'more_6s': 'plus de 6 s',
    'more_7s': 'plus de 7 s',
    'frailty_robust': 'Robuste',
    'frailty_pre_frail': 'Pré-fragile',
    'frailty_frail': 'frêle',
    'result_must_confirmed_professional': 'Ce résultat doit être confirmé par un professionnel',
    'sarc_f_score': 'Score SARC-F',
    'find_cases': 'Rechercher des cas',
    'no_sarcopenia': 'Pas de sarcopénie',
    'predictive_sarcopenia': 'Sarcopénie prédictive',
    'more_equal_4': 'plus ou égal à 4',
    'assess': 'Évaluer',
    'less_27kg': 'moins de 27kgf',
    'less_16kg': 'moins de 16kgf',
    'more_15s': 'plus de 15 secondes',
    'confirm': 'Confirmer',
    'muscle_estimation': 'Estimation musculaire',
    'less_20kg': 'moins de 20 kg',
    'less_15kg': 'moins de 15 kg',
    'less_equal_31cm': 'inférieur ou égal à 31 cm',
    'less_equal_26_3cm': 'inférieur ou égal à 26,3 cm',
    'less_equal_20_2cm': 'inférieur ou égal à 20,2 cm',
    'severity': 'Gravité',
    'more_5s': 'plus de 5 secondes',
    'sarcopenia_score': 'Score de Sarcopénie',
    'presence_sarcopenia': 'Présence Sarcopénie',
    'severe_sarcopenia': 'Sarcopénie Sévère',
    'criteria': 'Critères',
    'results': 'Résultats',
    'presence': 'Présence',
    'parameters': 'Paramètres',
    'dashboard': 'Tableau de bord',
    'anamnese': 'Anamnèse',
    'appointments': 'Consultation',
    'already_have_an_account': 'Vous avez déjà un compte? ',
    'all_steps_completed': 'Toutes les étapes sont terminées.',
    "measurement_profiles": "Profils de mesure",
    'my_patients': 'Mes patients',
    'search_patient': 'Rechercher un patient',
    'patient': 'Patient',
    'create_measurement_profile': 'Créer un profil de mesure',
    'profile_name': 'Nom du profil',
    'step_list': 'Liste des étapes',
    'add_step': 'Ajouter une étape',
    "S-AEROBIC-ENDURANCE": "Endurance Aérobie",
    "S-ANTHRO": "Anthropométrie",
    "S-ANTHRO-BREADTHS-DEPTHS": "Largeurs et Profondeurs",
    "S-ANTHRO-GIRTHS": "Circonférences",
    "S-ANTHRO-LENGTHS-HEIGHTS": "Longueurs et Hauteurs",
    "S-BASIC-MEASUREMENTS": "Mesures de Base",
    "S-BIO-IMPEDANCE": "Bio-impédance",
    "S-BLOOD-PRESSURE": "Pression Artérielle",
    "S-BLOOD-TESTS": "Analyses de Sang",
    "S-CATEGORY-ANALYTICAL-DATA": "Données Analytiques",
    "S-CATEGORY-ANTHROPOMETRY": "Anthropométrie",
    "S-CATEGORY-ERGONOMIC": "Ergonomique",
    "S-CATEGORY-FOLLOW-UP-SURVEYS": "Enquêtes de Suivi",
    "S-CATEGORY-FUNCTIONAL": "Fonctionnel",
    "S-CATEGORY-MORPHOLOGICAL": "Morphologique",
    "S-CATEGORY-OTHERS": "Autres",
    "S-CATEGORY-PRESETS": "Préréglages",
    "S-GOALS": "Objectifs",
    "S-HAND-CONFIGURATION": "Configuration des Mains",
    "S-INITIAL-PRESET-CHECKPOINT": "Point de Contrôle Initial",
    "S-LIMBS-BALANCE": "Équilibre des Membres",
    "S-MANUAL-HAND-STRENGTH": "Force (Manuel)",
    "S-MANUAL-OTITEST": "Otitest (Manuel)",
    "S-MANUAL-SKIN": "Plis Cutanés (Manuel)",
    "S-MANUAL-STRENGTH-ABDUCTION": "Abduction et Adduction des Jambes (Manuel)",
    "S-MANUAL-TEMP": "Température (Manuel)",
    "S-MEDICAL-AND-CLINICAL": "Médical et Clinique",
    "S-MOBILITY-AND-STABILITY": "Mobilité et Stabilité",
    "S-NOTES": "Prendre des Notes",
    "S-NUTRITION": "Nutrition",
    "S-OTITEST": "Otitest (Automatique)",
    "S-OXIMETER": "Oxymètre",
    "S-PERSONAL-AND-SOCIAL": "Personnel et Social",
    "S-PHOTOGRAMETRY": "Photogrammétrie",
    "S-PHYSICAL-ACTIVITY-AND-SPORTS": "Activité Physique et Sports",
    "S-POSTURE": "Posture",
    "S-QUALITY-PRESET-CHECKPOINT": "Point de Contrôle de la Qualité Musculaire",
    "S-SARCOPENIA-ASSESSMENT": "Évaluation de la Sarcopénie",
    "S-SEVERITY-PRESET-CHECKPOINT": "Point de Contrôle de la Gravité de la Sarcopénie",
    "S-SIT-TO-STAND": "S'asseoir et se Lever",
    "S-SIT-TO-STAND-INTERVAL": "Intervalle S'asseoir et se Lever",
    "S-SKIN": "Plis Cutanés (Lipowise)",
    "S-SKINFOLD-ORDER": "Ordre de Mesure des Plis Cutanés",
    "S-STRENGTH": "Force (Gripwise)",
    "S-STRENGTH-ABDUCTION": "Abduction et Adduction des Jambes (Gripwise)",
    "S-STRENGTH-PRESET-CHECKPOINT": "Point de Contrôle de la Force Musculaire",
    "S-SURVEY": "Enquêtes",
    "S-SURVEY-PRESET-CHECKPOINT": "Enquête SarcF",
    "S-TEMP-OTITEST": "Température (Otitest)",
    "S-UP-AND-GO": "TUG (3+3m)",
    "S-WALK": "Temps de Marche (15 pieds)",
    "S-WEIGHT": "Poids et Taille",
    "S-WEIGHT-PAST": "Poids (Antérieur)",
    "choose_at_least_1_step": 'Choisissez au moins une étape',
    "save_profile": "Enregistrer le profil",
    "delete_profile": "Supprimer le profil",
    "confirm_delete_profile": "Êtes-vous sûr de vouloir supprimer ce profil: ",
    'patient_update_error_title': 'Erreur',
    'patient_update_error_description': 'Une erreur s\'est produite lors de la mise à jour du patient.',
    'patient_update_success_title': 'Succès',
    'patient_update_success_description': 'Patient mis à jour avec succès.',
    'axial_measurements': 'Mesures axiales',
    'bilateral_measurements': 'Mesures bilatérales',
    "physical_activity": "Activité physique",
    "physical_activity_description": "Description de l'activité physique",
    "physical_activity_field_label": "Activité physique",
    "physical_activity_header": "Activité physique",
    "physical_activity_inactive": "Inactif",
    "physical_activity_label": "Activité physique",
    "physical_activity_light": "Intensité légère (1-3 jours/semaine)",
    "physical_activity_moderate": "Intensité modérée (3-5 jours/semaine)",
    "physical_activity_vigorous": "Intensité vigoureuse (> 5 jours/semaine)",
    "have_not_have_used": "Utilisé",
    "have_not_never_used": "Jamais utilisé",
    "hours_per_night": "Heures par nuit",
    "level": "Niveau",
    "level_elevate": "Élever",
    "level_low": "Faible",
    "level_medium": "Moyen",
    "gender_female": "Femelle",
    "gender_label": "Sexe",
    "gender_male": "mâle",
    "gender_other": "Autre",
    "ethnicity_african": "Africaine",
    "ethnicity_asiatic": "Asiatique",
    "ethnicity_caucasian": "Caucasienne",
    "ethnicity_hispanic": "Hispanique",
    "ethnicity_other": "Autre",
};
