import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../agent";
import { stores } from ".";

class AppointmentStore {
  appointments = [];
  selectedAppointment = null;
  totalCount = 0;
  inProgress = false;
  errors = undefined;

  constructor() {
    makeObservable(this, {
      appointments: observable,
      totalCount: observable,
      inProgress: observable,
      errors: observable,
      selectedAppointment: observable,
      loadAppointments: action,
      clear: action,
    });
  }

  selectAppointment(appointment) {
    runInAction(() => {
      this.selectedAppointment = appointment;
    });
  }

  loadAppointments = async ( page = 1, pageSize = 10) => {
    this.inProgress = true;
    this.errors = undefined;
    const patientBackendId = stores.patientStore.selectedPatient?.backend_id;

    try {
      const response = await agent.Appointment.list(patientBackendId, page, pageSize);
      runInAction(() => {
        this.appointments = response.results;
        const appointment = this.appointments[0];
        const patient = stores.patientStore.selectedPatient;
        if (patient && appointment) {
          appointment.results.client = patient;
        }
        this.selectedAppointment = appointment;
        this.totalCount = response.count;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = error;
        console.error("Error loading appointments:", error);
      });
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  };

  clear() {
    this.appointments = [];
    this.selectedAppointment = null;
    this.totalCount = 0;
  }
}

export default AppointmentStore;
